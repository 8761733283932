import { AuctionDetailsResponse,
    AuctionResponse,
    AuctionSearchingRequestSortTypeEnum, NoteResponse } from 'utils/types/AuctionModels';
import { FiltersStateType } from 'utils/types/FiltersStateType';
import { ModulesType } from 'utils/types/ModulesType';

export interface AuctionOfferStateType {
  isLoading: boolean,
  auctionOfferList: AuctionOffersResponse,
  sortType: AuctionSearchingRequestSortTypeEnum;
  filtersState: FiltersStateType;
  pageType: ModulesType;
  filteredCity: string;
  notes?: NoteResponse[],
}

export type AuctionOfferDetailsResponse = AuctionDetailsResponse;

export interface AuctionOfferResponseContent extends AuctionResponse {
  detailedContent?: AuctionOfferDetailsResponse;
}

export interface AuctionOffersResponse {
  empty: boolean,
  content: AuctionOfferResponseContent[],
  pageable: {
      pageNumber: number,
      pageSize: number,
  },
  numberOfElements: number,
  totalPages: number,
  totalElements: number,
  last: boolean,
  size: number,
  afterSearch: boolean,
}

export const auctionInitialState: AuctionOfferStateType = {
    isLoading: false,
    auctionOfferList: {
        empty: false,
        content: [],
        pageable: {
            pageNumber: 1,
            pageSize: 0
        },
        numberOfElements: 0,
        size: 25,
        totalPages: 0,
        totalElements: 0,
        last: false,
        afterSearch: false
    },
    sortType: AuctionSearchingRequestSortTypeEnum.DATE_DESC,
    filtersState: {} as FiltersStateType,
    pageType: 'auction',
    filteredCity: ''
};
