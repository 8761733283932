import React, { ChangeEvent, ForwardedRef, forwardRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { userApiRequests } from 'utils/api-requests/user';
import { extraSmsToBuyOptions } from 'utils/options';
import { AdvertValidatorType, isFieldValid } from 'utils/validators/advertAddingValidator';

import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import AutocompleteInput from 'components/common/Inputs/AutocompleteInput/AutocompleteInput';
import TextInput from 'components/common/Inputs/TextInput';
import TextBox, { TextBoxStructureType } from 'components/common/TextBox';
import { Title } from 'components/HeaderSection';
import { FormRadio } from 'components/InputFilters';

import { SaveButton } from './SaveButton';

const NotificationsContainer = styled.div`
    padding: 24px 10px 32px 20px;
    border-radius: var(--box-border-radius);
    background: var(--color-white);
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 40px;
    margin-bottom: 60px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ToggleInpuContainer = styled.div`
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const NotificationsInputContainer = styled.div`
    padding-bottom: 10px;
`;

const AutocompleteWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    padding: 15px 15px 0 0;

    @media (width < 991px) {
        width: 100%;
    }
`;

const InputAndButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-alt-second);
    color: var(--color-primary);
    border-radius: var(--border-radius);
`;

const Button = styled.div`
    background: var(--color-alt-second);
    border-radius: 40px;
    height: 50px;
    border: none;
    padding: 10px;
    display: flex;
    align-items: center;
    width: 35%;
    margin-left: auto;
    justify-content: center;
    text-decoration: none !important;
    cursor: pointer;

    span {
        font-size: var(--font-size-body-2);
        text-align: center;
        width: 100%;
        padding: 5px;
        border-radius: 40px;
        color: white;
        background-color: var(--color-alt);
    }
`;

interface NotificationsTabProps {
    title: string;
}

const NotificationsTab = forwardRef<HTMLDivElement, NotificationsTabProps>(({ title }, ref: ForwardedRef<HTMLDivElement>) => {
    const { user, dispatch } = useUser();
    const [isEditableInput, setIsEditableInput] = useState(false);
    const [triggerValidation, setTriggerValidation] = useState(false);

    const [userNotificationData, setUserNotificationData] = useState(user.notificationSettings);

    useEffect(() => {
        UserActions.getNotificationSettings(dispatch);
    }, [dispatch]);

    useEffect(() => {
        setUserNotificationData(user.notificationSettings);
    }, [user.notificationSettings]);

    const handleUserDataChange = (field: keyof typeof userNotificationData, value: string) => {
        setUserNotificationData((prevData) => ({
            ...prevData,
            [field]: value
        }));
    };

    const [smsToBuy, setSmsToBuy] = useState<string>('100');
    const [editedDailyLimit, setEditedDailyLimit] = useState<string | number | undefined>(userNotificationData.smsDayLimit?.toString());

    const handleSmsToBuyChange = (value: string) => {
        setSmsToBuy(value);
    };

    const handleAlertNotificationsChange = () => {
        userApiRequests.changeSmsAndMailNotificationStatus(!userNotificationData.activeNotification);
        setUserNotificationData((prev) => ({
            ...prev,
            activeNotification: !userNotificationData.activeNotification
        }));
    };

    const handlePushNotificationsChange = () => {
        userApiRequests.changePushNotificationStatus(!userNotificationData.pushNotification);
        setUserNotificationData((prev) => ({
            ...prev,
            pushNotification: !userNotificationData.pushNotification
        }));
    };

    const handleSubmitBuySms = () => {
        const accountUrl = `https://investoro.pl/?add-to-cart=1010&quantity=${smsToBuy}`;
        window.location.href = accountUrl;
    };

    const handleDailyLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
        setEditedDailyLimit(value);
    };

    const handleLimitChangeButton = async () => {
        if (isEditableInput) {
            if (editedDailyLimit !== '') {
                await userApiRequests.changeDayLimit(Number(editedDailyLimit));
                await UserActions.getNotificationSettings(dispatch);
                setIsEditableInput(false);
            }
        } else {
            setIsEditableInput(!isEditableInput);
            setEditedDailyLimit(userNotificationData.smsDayLimit);
        }
        setIsEditableInput(!isEditableInput);
    };

    const onOutsideClick = () => {
        setIsEditableInput(false);
    };

    const handleSave = () => {
        setTriggerValidation(true);

        if (isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, userNotificationData.notificationEmail) &&
            isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, userNotificationData.notificationPhone)) {
            setTriggerValidation(false);
            userApiRequests.changeNotificationMailAndPhoneNumber(userNotificationData.notificationEmail, userNotificationData.notificationPhone);
        }
    };

    const notificationSettingsStruture = [
        {
            title: 'Powiadomienia E-mail i SMS',
            icon: IconEnum.AUDIO,
            helperText:
            'Jeśli wyłączysz powiadomienia nie będziesz otrzymywać powiadomień z żadnego stworzonego przez siebie filtra, na email i telefon.',
            formRadio:
                <FormRadio
                    name="Wyłączyć powiadomienia?"
                    value={userNotificationData.activeNotification}
                    onChange={handleAlertNotificationsChange}
                    optionsText={{ trueOptionText: 'Włączone', falseOptionText: 'Wyłączone' }}
                />

        },
        {
            title: 'Powiadomienia PUSH',
            icon: IconEnum.INFO,
            formRadio:
                <FormRadio
                    name="Wyłączyć powiadomienia?"
                    value={userNotificationData.pushNotification}
                    onChange={handlePushNotificationsChange}
                    optionsText={{ trueOptionText: 'Włączone', falseOptionText: 'Wyłączone' }}
                />

        }
    ];

    const textBoxesStructure: TextBoxStructureType[] = [
        {
            title: 'Limity Powiadomień SMS',
            titleIcon: IconEnum.CHART,
            label: 'Wykorzystano/Pakiet',
            value: userNotificationData.monthSmsToUse
        },
        {
            title: '',
            label: 'Dokupione SMSY',
            value: userNotificationData.boughtSms
        },
        {
            title: '',
            label: 'Dzienny limit wiadomości SMS:',
            value: userNotificationData.smsDayLimit,
            editedValue: editedDailyLimit,
            actions: {
                actionText: isEditableInput ? 'Zapisz zmiany' : 'Zmień limit',
                actionClick: handleLimitChangeButton,
                isEditable: isEditableInput,
                onChange: handleDailyLimitChange,
                onOutsideClick

            }
        },
        {
            title: '',
            label: 'Data zresetowania pakietu:',
            value: dayjs(userNotificationData.smsDayReset).format('DD.MM.YYYY') || ''
        }
    ];

    return (
        <div ref={ref}>
            <Title>{title}</Title>
            <NotificationsContainer>
                <Wrapper>
                    <NotificationsInputContainer>
                        <InputContainer title="Dane do powiadomień" icon={IconEnum.ALERT}>
                            <TextInput
                                value={userNotificationData.notificationEmail}
                                onChange={(e) => handleUserDataChange('notificationEmail', e.target.value)}
                                placeholder={userNotificationData.notificationEmail}
                                type="email"
                                icon={IconEnum.EMAIL}
                                inputValidation={(value: string | undefined | null | number) => !isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, value as string)}
                                errorMessage={'Pole nie może być puste'}
                                triggerValidation={triggerValidation}
                            />
                        </InputContainer>
                        <InputContainer title="">
                            <TextInput
                                value={userNotificationData.notificationPhone}
                                onChange={(e) => handleUserDataChange('notificationPhone', e.target.value)}
                                placeholder={userNotificationData.notificationPhone}
                                type="tel"
                                icon={IconEnum.PHONE}
                                inputValidation={(value: string | undefined | null | number) => !isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, value as string)}
                                errorMessage={'Pole nie może być puste'}
                                triggerValidation={triggerValidation}

                            />
                        </InputContainer>
                    </NotificationsInputContainer>
                    <SaveButton onClick={handleSave}>Zapisz zmiany</SaveButton>
                    <ToggleInpuContainer>
                        {notificationSettingsStruture.map((setting, index) => <InputContainer
                            key={index}
                            title={setting.title}
                            icon={setting.icon}
                            visibleHelperText={setting.helperText}
                        >
                            {setting.formRadio}
                        </InputContainer>)}
                    </ToggleInpuContainer>
                </Wrapper>

                <Wrapper>
                    {textBoxesStructure.map((el) => {
                        return (
                            <TextBox
                                key={el.label}
                                label={el.label}
                                title={el.title}
                                icon={el.titleIcon}
                                labelIcon={el.labelIcon}
                                actionText={el.actions?.actionText}
                                editedValue={el.editedValue}
                                onActionClick={el.actions?.actionClick}
                                isEditMode={el.actions?.isEditable}
                                value={el.value}
                                onChange={el.actions?.onChange}
                                onOutsideClick={el.actions?.onOutsideClick}>

                            </TextBox>
                        );
                    })}
                    <AutocompleteWrapper>
                        <InputContainer title={'Dokup pakiet SMS:'}
                            icon={IconEnum.LABEL}
                            visibleHelperText={'Cena 100 wiadomości SMS wynosi 25,00zł'}>
                            <InputAndButtonWrapper>
                                <AutocompleteInput
                                    noAction
                                    noTyping
                                    placeholder={'Wybierz ilość sms...'}
                                    value={smsToBuy}
                                    options={extraSmsToBuyOptions}
                                    // @ts-expect-error value is only string here
                                    onChange={handleSmsToBuyChange} />
                                <Button darkMode={user.isDarkMode} onClick={handleSubmitBuySms}><span>Kup</span></Button>
                            </InputAndButtonWrapper>
                        </InputContainer>

                    </AutocompleteWrapper>

                </Wrapper>
            </NotificationsContainer>
        </div>
    );
});

export default NotificationsTab;
