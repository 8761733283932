
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import { brokerApiRequests } from 'utils/api-requests/broker';
import { commonApiRequests } from 'utils/api-requests/common';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { offersApiRequests } from 'utils/api-requests/offers';
import { saleOffersApiRequests } from 'utils/api-requests/sale';
import { createUniqueFilterName } from 'utils/createUniqueFilterName';
import { generateUniqueId } from 'utils/formatters/generateUniqueId';
import { getSaleValueFromState, initialState, setSaleStateValues } from 'utils/state-managment/sale/saleFilter';
import { saleInitialState, SaleOffersResponse, SaleStateType } from 'utils/state-managment/sale/saleOffer';
import { SavedFilter } from 'utils/types/Filter';
import { MultipleFilterType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { UserReactions } from 'utils/types/OfferData';
import { SaleFilterReturnData, SaleFiltersStateType } from 'utils/types/Sale';
import { Module } from 'utils/types/SaleModels';
import { AdvertValidatorType, isFieldValid } from 'utils/validators/advertAddingValidator';

import { activeNotification } from 'components/functions/activeNotification';

interface LoadSaleOffersType {
    requestData?: SaleFilterReturnData,
    page?: number,
    rows?: number
}

export interface SaleContextType {
    handleChangePropertyType: (value: Module) => void,
    propertyType: Module,
    saleFiltersState: SaleFiltersStateType
    activeFiltersState: SaleFiltersStateType
    handleDeleteFilter: (id: number) => void,
    handleSetFiltersValues: (id: number) => void
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void
    validateField: (name: AdvertValidatorType, value: ObjectAndSingleValueChangeType) => boolean
    clearFilters: (reset?: boolean) => void
    validateAllFields: () => boolean
    getSavedFilters: (id?: number) => void
    handleChangeDateList: (fieldName: string, value: number) => void
    handleActiveFilterChange: (fieldName: string, value: string | number | boolean | MultipleFilterType) => void
    saleOfferState: SaleStateType,
    loadSaleOffers: (saleOffersOptions?: LoadSaleOffersType) => void;
    loadDuplicates: (type: Module, id: number) => void,
    deleteNote: (id: number, offerType: Module, noteId: number) => void,
    updateNote: (module: Module, noteContent: string, advertisementId: number, noteId: number) => void,
    reactionUpdate: (id: number, city: string, reaction: UserReactions, module: Module) => void,
    loadOfferDetailsAsync: (id: number, module: Module) => void,
    loadNotesAsync: (id: string, module: Module) => void,
    clearOffers: () => void,
    loadFavoriteOffers: (type: Module[], page?: number, size?: number, filters?: FavoritePageFiltersType) => void,
    updateOpinion: (module: Module, commentContent: string, rating: number, advertisementId: number, opinionId?: number) => Promise<void>,
    loadOpinionsAsync: (id: number, module: Module) => void,
    deleteOpinion: (id: number, module: Module, opinionId: number) => void,
    handleSaveOrUpdateFilter: () => void,
    handleCreateFromExistFilter: () => void,
    handleChangeNotificationAlert: (valueName: string, hashedId: number) => void,
    handleReservation: (days: number, advertisementId: number, module: Module, permanently?: boolean) => void,
    removeReservation: (advertisementId: number, module: Module) => void
}

const values: SaleContextType = {
    handleChangePropertyType: () => {},
    handleDeleteFilter: () => {},
    handleSetFiltersValues: () => {},
    handleChange: () => {},
    handleChangeDateList: () => {},
    clearFilters: () => {},
    validateField: () => false,
    validateAllFields: () => false,
    propertyType: Module.SALE_FLAT,
    saleFiltersState: {} as SaleFiltersStateType,
    activeFiltersState: {} as SaleFiltersStateType,
    saleOfferState: {} as SaleStateType,
    getSavedFilters: () => {},
    handleActiveFilterChange: () => {},
    loadSaleOffers: () => {},
    loadDuplicates: () => {},
    deleteNote: () => {},
    updateNote: () => {},
    reactionUpdate: () => {},
    loadOfferDetailsAsync: () => {},
    loadNotesAsync: () => {},
    clearOffers: () => {},
    loadFavoriteOffers: () => {},
    updateOpinion: () => new Promise(() => {}),
    loadOpinionsAsync: () => {},
    deleteOpinion: () => {},
    handleSaveOrUpdateFilter: () => {},
    handleCreateFromExistFilter: () => {},
    handleChangeNotificationAlert: () => {},
    handleReservation: () => {},
    removeReservation: () => {}
};

export const SaleContext = createContext(values);

type MappedIdType = Record<string, { id: number, module: Module}>;

export const SaleProvider = ({ children }: { children: React.ReactNode }) => {
    const { dispatch: dispatchUser } = useUser();
    const [offerType, setOfferType] = useState<Module>(Module.SALE_FLAT);
    const [saleFiltersState, setSaleFiltersState] = useState(initialState.saleFilterInitialState());
    const [activeFiltersState, setActiveFiltersState] = useState(initialState.saleFilterInitialState());
    const [saleOfferState, setSaleOfferState] = useState(saleInitialState);
    const [idMap, setIdMap] = useState<MappedIdType>({});

    const setLoadingPage = () => {
        setSaleOfferState((prev) => ({
            ...prev,
            isLoading: true
        }));
    };

    const loadSaleOffers = async (saleOffersOptions?: LoadSaleOffersType) => {
        const { requestData, page, rows } = saleOffersOptions || {};

        setLoadingPage();

        const response = await saleOffersApiRequests.getSaleOffers(requestData ? requestData : getSaleValueFromState(saleFiltersState), saleFiltersState.module, page ?? 0, rows ?? 25);

        if (response) {
            setSaleOfferState((prev) => ({
                ...prev,
                saleOfferList: response,
                isLoading: false
            }));
        }
    };

    const loadDuplicates = async (type: Module, id: number) => {
        setLoadingPage();

        const duplicates = await saleOffersApiRequests.getSaleOfferDuplicates(type, id);

        setSaleOfferState((prev) => ({
            ...prev,
            saleOfferList: {
                ...prev.saleOfferList,
                content: prev.saleOfferList.content.map((el) => {
                    if (el.advertisementId === id) {
                        return {
                            ...el,
                            duplicates: duplicates
                        };
                    }

                    return el;
                })
            },
            isLoading: false
        }));
    };

    const handleSaveOrUpdateFilter = async () => {
        if (saleFiltersState.city.length <= 0) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        const existFilter = saleFiltersState.savedFilter?.find(
            (filter) => filter.id === saleFiltersState.id
        );

        const existName = saleFiltersState.savedFilter?.find(
            (filter) => filter.name === saleFiltersState.name
        );

        let id;

        if (existName && !saleFiltersState.id) {
            activeNotification('Nazwa filtra', 'Istnieje już filtr o takiej nazwie', 'warning');

            return;
        }

        if (existFilter) {
            const id = idMap[existFilter.id].id;

            await saleOffersApiRequests.updateFilter({
                ...getSaleValueFromState({ ...saleFiltersState, id })
            }, saleFiltersState.module);
        } else {
            const resp = await saleOffersApiRequests.addFilter({
                ...getSaleValueFromState(saleFiltersState)
            }, saleFiltersState.module);

            id = resp?.data;
        }

        getSavedFilters(id);
    };

    const handleCreateFromExistFilter = async () => {
        if (saleFiltersState.city.length <= 0) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        const newName = createUniqueFilterName(saleFiltersState.name!, saleFiltersState.savedFilter as SavedFilter[]);

        const newFilterData = {
            ...getSaleValueFromState(saleFiltersState),
            name: newName
        };

        const resp = await saleOffersApiRequests.addFilter(newFilterData, saleFiltersState.module);

        const id = resp?.data;

        getSavedFilters(id);
    };

    const handleChangeNotificationAlert = async (valueName: string, hashedId: number) => {
        const currentState = { ...saleFiltersState };
        const newValue = !currentState[valueName as keyof SaleFiltersStateType];
        const realFilterId = idMap[hashedId].id;
        const filterModuleType = idMap[hashedId].module;

        const updatedState = {
            ...currentState,
            [valueName]: newValue
        };

        if (valueName === 'alertSms' && newValue) {
            updatedState.tossedUp = false;
            handleChange('tossedUp', false);
        }

        handleChange(valueName, newValue);

        await saleOffersApiRequests.updateFilter(getSaleValueFromState({ ...updatedState, id: realFilterId }), filterModuleType);
        getSavedFilters(realFilterId);
    };

    const loadOfferDetailsAsync = async (id: number, module: Module) => {
        setLoadingPage();

        const response = await saleOffersApiRequests.getSaleOfferDetails(module, id);

        setSaleOfferState((prevState) => ({
            ...prevState,
            saleOfferList: {
                ...prevState.saleOfferList,
                content: prevState.saleOfferList.content.map((el) => {
                    if (el.advertisementId === response.advertisementId) {
                        return {
                            ...el,
                            detailedContent: response
                        };
                    }

                    return el;
                })
            },
            isLoading: false,
            notes: response.note
        }));
    };

    const loadFavoriteOffers = async (type: Module[], page?: number, size?: number, filters?: FavoritePageFiltersType) => {
        setLoadingPage();

        const response = await offersApiRequests.getSaleFavoriteOffers(type, page ?? 1, size ?? 25, filters) as unknown as SaleOffersResponse;

        setSaleOfferState((prev) => ({
            ...prev,
            saleOfferList: response,
            isLoading: false
        }));
    };

    const loadNotesAsync = async (id: string, module: Module) => {
        const notes = await commonApiRequests.getNotes(module, id);

        setSaleOfferState((prev) => ({
            ...prev,
            notes
        }));
    };

    const loadOpinionsAsync = async (id: number, module: Module) => {
        const opinions = await saleOffersApiRequests.getOpinions(id, module);

        setSaleOfferState((prev) => ({
            ...prev,
            scoreAndOpinions: opinions
        }));
    };

    const clearOffers = () => {
        setSaleOfferState(saleInitialState);
    };

    const updateNote = async (module: Module, noteContent: string, advertisementId: number, noteId: number) => {
        setLoadingPage();

        const validChangeStatus = await commonApiRequests.updateNote(module, noteContent, advertisementId, noteId);

        if (validChangeStatus) {
            if (noteId) {
                setSaleOfferState((prev) => {
                    return {
                        ...prev,
                        notes: prev.notes!.map((item) => {
                            if (item.noteId === noteId) {
                                return {
                                    ...item,
                                    date: validChangeStatus.date,
                                    content: validChangeStatus.content
                                };
                            }

                            return item;
                        }),
                        saleOfferList: {
                            ...prev.saleOfferList,
                            content: prev.saleOfferList.content.map((x) => {
                                if (x.advertisementId === advertisementId) {
                                    return {
                                        ...x,
                                        note: {
                                            ...validChangeStatus,
                                            noteId: noteId
                                        }
                                    };
                                }

                                return x;
                            })
                        },
                        isLoading: false
                    };
                });
            } else {
                setSaleOfferState((prev) => {
                    return {
                        ...prev,
                        notes: prev.notes ? [validChangeStatus].concat(prev.notes) : [validChangeStatus],
                        saleOfferList: {
                            ...prev.saleOfferList,
                            content: prev.saleOfferList.content.map((x) => {
                                if (x.advertisementId === advertisementId) {
                                    return {
                                        ...x,
                                        note: {
                                            ...validChangeStatus
                                        },
                                        numberOfNotes: (x.numberOfNotes ?? 0) + 1
                                    };
                                }

                                return x;
                            })
                        },
                        isLoading: false
                    };
                });
            }
        }
    };

    const updateOpinion = async (module: Module, commentContent: string, rating: number, advertisementId: number, opinionId?: number) => {
        setLoadingPage();

        if (opinionId) {
            const response = await saleOffersApiRequests.updateOpinion(opinionId, commentContent, rating, module);

            if (response) {
                setSaleOfferState((prev) => {
                    return {
                        ...prev,
                        saleOfferList: {
                            ...prev.saleOfferList,
                            content: prev.saleOfferList.content.map((offer) => {
                                if (offer.advertisementId === advertisementId) {
                                    return {
                                        ...offer,
                                        ...response
                                    };
                                }

                                return offer;
                            })
                        },
                        isLoading: false
                    };
                });
            }
        } else {
            const response = await saleOffersApiRequests.addOpinion(advertisementId, commentContent, rating, module);
            const newOpinions = await saleOffersApiRequests.getOpinions(advertisementId, module);

            if (response) {
                setSaleOfferState((prev) => {
                    return {
                        ...prev,
                        scoreAndOpinions: newOpinions,
                        saleOfferList: {
                            ...prev.saleOfferList,
                            content: prev.saleOfferList.content.map((offer) => {
                                if (offer.advertisementId === advertisementId) {
                                    return {
                                        ...offer,
                                        ...response,
                                        myOpinion: {
                                            id: response.opinionId,
                                            dateAdded: new Date().toDateString(),
                                            numberOfStars: rating,
                                            content: commentContent
                                        }
                                    };
                                }

                                return offer;
                            })
                        },
                        isLoading: false
                    };
                });
            }
        }
    };

    const deleteOpinion = async (id: number, module: Module, opinionId: number) => {
        setLoadingPage();

        const response = await saleOffersApiRequests.deleteOpinion(opinionId, module);

        if (response) {
            setSaleOfferState((prev) => {
                return {
                    ...prev,
                    scoreAndOpinions: {
                        ...prev.scoreAndOpinions!,
                        opinions: prev.scoreAndOpinions!.opinions!.filter((item) => item.opinionId !== opinionId)
                    },
                    saleOfferList: {
                        ...prev.saleOfferList,
                        content: prev.saleOfferList.content.map((offer) => {
                            if (offer.advertisementId === id) {
                                return {
                                    ...offer,
                                    ...response,
                                    myOpinion: undefined
                                };
                            }

                            return offer;
                        })
                    },
                    isLoading: false
                };
            });
        }
    };

    const deleteNote = async (id: number, offerType: Module, noteId: number) => {
        setLoadingPage();

        const validChangeStatus = await commonApiRequests.deleteNote(offerType, noteId);

        if (validChangeStatus) {
            setSaleOfferState((prev) => {
                return {
                    ...prev,
                    notes: prev.notes!.filter((item) => item.noteId !== noteId),
                    saleOfferList: {
                        ...prev.saleOfferList,
                        content: prev.saleOfferList.content.map((offer) => {
                            if (offer.advertisementId === id) {
                                return {
                                    ...offer,
                                    numberOfNotes: (offer.numberOfNotes ?? 0) - 1
                                };
                            }

                            return offer;
                        })
                    },
                    isLoading: false
                };
            });
        }
    };

    const reactionUpdate = async (id: number, city: string, reaction: UserReactions, module: Module) => {
        const response = await commonApiRequests.setOfferReaction(id, city, reaction, module);
        setSaleOfferState((prev) => ({
            ...prev,
            saleOfferList: {
                ...prev.saleOfferList,
                content: prev.saleOfferList.content.map((el) => {
                    if (el.advertisementId === id) {
                        return {
                            ...el,
                            reaction: response
                        };
                    }

                    return {
                        ...el,
                        duplicates: el.duplicates?.map((item) => {
                            if (item.advertisementId === id) {
                                return {
                                    ...item,
                                    reaction: response
                                };
                            }

                            return item;
                        })
                    };
                })
            }
        }));
    };

    const handleSetFiltersValues = async (id: number, idMapParam?: MappedIdType) => {
        const currentIdMap = idMapParam || idMap;
        const filterId = currentIdMap[id].id;
        const filterModuleType = currentIdMap[id].module;
        const filterData = await saleOffersApiRequests.getFilterDetailsSale(filterId, filterModuleType ? Module[filterModuleType] : offerType) as SaleFilterReturnData;

        if (filterData) {
            setSaleFiltersState((filters: SaleFiltersStateType) => ({
                ...setSaleStateValues({ ...filterData, id, locations: saleFiltersState.locations }),
                savedFilter: filters.savedFilter
            }));
        } else {
            setSaleFiltersState((filters: SaleFiltersStateType) => ({
                ...filters,
                name: null,
                id: undefined
            }));
        }
    };

    const handleReservation = async (days: number, advertisementId: number, module: Module, permanently?: boolean) => {
        const response = permanently
            ? await brokerApiRequests.addBlockage({
                advertisementId,
                module
            })
            : await brokerApiRequests.addTempBlockage({
                advertisementId,
                days,
                module
            });

        if (response) {
            setSaleOfferState((prev) => ({
                ...prev,
                saleOfferList: {
                    ...prev.saleOfferList,
                    content: prev.saleOfferList.content.map((el) => {
                        if (el.advertisementId === advertisementId) {
                            return {
                                ...el,
                                blockageInfo: { ...response, module: response.module as Module }
                            };
                        }

                        return el;
                    })
                }
            }));
        }
    };

    const removeReservation = async (advertisementId: number, module: Module) => {
        const response = await brokerApiRequests.removeBlockage({
            advertisementId,
            module
        });

        if (response) {
            setSaleOfferState((prev) => ({
                ...prev,
                saleOfferList: {
                    ...prev.saleOfferList,
                    content: prev.saleOfferList.content.map((el) => {
                        if (el.advertisementId === advertisementId) {
                            return {
                                ...el,
                                blockageInfo: null
                            };
                        }

                        return el;
                    })
                }
            }));
        }
    };

    const getSavedFilters = useCallback(async (id?: number) => {
        const savedFilters = await saleOffersApiRequests.getSavedFilters();
        const newIdMap: MappedIdType = {};
        const savedFiltersHashedId = savedFilters.map((x) => {
            const hashedId = generateUniqueId(`${x.id}:${x.module}`);
            newIdMap[hashedId] = { id: x.id, module: Module[x.module] };

            return { ...x, id: hashedId };
        });

        setIdMap((prev) => ({
            ...prev,
            ...newIdMap
        }));

        setSaleFiltersState((filters: SaleFiltersStateType) => ({
            ...filters,
            savedFilter: savedFiltersHashedId
        }));

        if (id) {
            const hashedId = Object.keys(newIdMap).find((x) => newIdMap[x].id === id);

            await handleSetFiltersValues(Number(hashedId), newIdMap);
        }
    }, [offerType]);

    const clearFilters = useCallback((reset?: boolean) => {
        const initialFilters = initialState.saleFilterInitialState();

        if (reset) {
            setSaleFiltersState((prev) => ({
                ...initialFilters,
                module: offerType,
                locations: prev.locations
            }));
        } else {
            setSaleFiltersState((prev) => ({
                ...prev,
                module: offerType,
                locations: prev.locations,
                city: reset ? [] : prev.city,
                savedLocations: reset ? {} : prev.savedLocations,
                floor: initialFilters.floor,
                floors: initialFilters.floors,
                builtYear: initialFilters.builtYear,
                buildingType: initialFilters.buildingType,
                numberOfRooms: initialFilters.numberOfRooms
            }));
        }
        getSavedFilters();
        UserActions.changeErrorVisibility(dispatchUser, false);
    }, [
        dispatchUser,
        getSavedFilters,
        offerType
    ]);

    const handleChangePropertyType = useCallback((value: Module) => {
        setOfferType(value);
        clearFilters();
        setSaleFiltersState((prev) => ({
            ...prev,
            id: undefined
        }));
    }, [clearFilters]);

    const handleDeleteFilter = useCallback(async (filterId: number) => {
        const realFilterId = idMap[filterId].id;
        const filterModuleType = idMap[filterId].module;

        await saleOffersApiRequests.removeSavedFilter(realFilterId, filterModuleType ? Module[filterModuleType] : offerType);

        delete idMap[filterId];

        setSaleFiltersState(initialState.saleFilterInitialState());
        getSavedFilters();
    }, [
        saleFiltersState.id,
        saleFiltersState.savedFilter,
        getSavedFilters
    ]);

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setSaleFiltersState((filters: SaleFiltersStateType) => {
            const updatedFilters = {
                ...filters,
                [fieldName]: value
            };

            if (fieldName === 'alertSms' && value === true) {
                updatedFilters.tossedUp = false;
            }

            return updatedFilters;
        });
    }, [saleFiltersState]);

    const handleActiveFilterChange = useCallback((fieldName: string, value: string | number | boolean | MultipleFilterType) => {
        setActiveFiltersState((filters: SaleFiltersStateType) => ({
            ...filters,
            [fieldName]: value
        }));
    }, []);

    // TODO: Kandydat jako funkcja wspólna dla wszystkich kontekstów
    // src/components/functions/locations.ts
    const getVoivodeships = useCallback(async () => {
        const locations = await filtersApiRequests.getVoivodeships();
        setSaleFiltersState((filters: SaleFiltersStateType) => ({
            ...filters,
            locations
        }));
    }, []);

    const handleChangeDateList = (fieldName: string, value: number) => {
        setSaleFiltersState((filters: SaleFiltersStateType) => ({
            ...filters,
            offerAdded: {
                from: value === -1 ? null : new Date(new Date().setDate(new Date().getDate() - value)).toISOString().split('T')[0],
                to: new Date().toISOString().split('T')[0]
            },
            [fieldName]: value
        }));
    };

    useEffect(() => {
        clearFilters();
    }, [offerType]);

    useEffect(() => {
        if (saleFiltersState.locations.length === 0) {
            getVoivodeships();
        }
    }, [saleFiltersState.locations]);

    const providerValues = {
        handleChangePropertyType,
        handleDeleteFilter,
        handleSetFiltersValues,
        handleChange,
        clearFilters,
        validateField: isFieldValid,
        validateAllFields: () => false,
        propertyType: Module.SALE_FLAT,
        saleFiltersState,
        getSavedFilters,
        handleChangeDateList,
        handleActiveFilterChange,
        activeFiltersState,
        saleOfferState,
        loadOfferDetailsAsync,
        loadNotesAsync,
        reactionUpdate,
        updateNote,
        loadSaleOffers,
        loadDuplicates,
        deleteNote,
        clearOffers,
        loadFavoriteOffers,
        updateOpinion,
        loadOpinionsAsync,
        deleteOpinion,
        handleSaveOrUpdateFilter,
        handleChangeNotificationAlert,
        handleReservation,
        removeReservation,
        handleCreateFromExistFilter
    };

    return (
        <SaleContext.Provider value={providerValues}>
            {children}
        </SaleContext.Provider>
    );
};

export default SaleContext;
