import React, { FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';

const Options = styled.div`
    position: absolute;
    right: 0;
    ${({ isInModal }) => isInModal ? 'top: 55px;' : 'bottom: 55px;'}
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    z-index: 100;
`;

interface ActionWrapperProps {
    darkMode?: boolean;
    isBlocked?: boolean;
}

const ActionWrapper = styled.div<ActionWrapperProps>`
    background-color: var(--color-alt-second);
    border-radius: 20px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    gap: 8px;
    
    p {
        text-align: center;
        color: ${({ isBlocked }) => isBlocked ? 'var(--color-alt-second-10)' : 'var(--color-primary)'};
    }

    &:hover {
        ${({ isBlocked, darkMode }) => !isBlocked && `
            transition: background-color ease-in .2s;
            background-color: var(--color-alt);
            cursor: pointer;

            p {
                color: white;
            }

            img {
                filter: ${darkMode ? 'none' : 'brightness(0) invert(1)'};
            }
        `}
    }

    ${({ isBlocked }) => isBlocked && `
        cursor: default;
    `}
`;

interface OfferActionsProps {
    elements: {
        onClick: (event: MouseEvent) => void;
        darkMode?: boolean;
        children: ReactNode;
        isBlocked?: boolean;
    }[];
    isInModal?: boolean;
    onClickOutside?: (event: MouseEvent) => void;
}

const OfferActions: FC<OfferActionsProps> = ({ elements, isInModal, onClickOutside }) => {
    const handleClickOutside = (event: MouseEvent) => {
        if (event.target instanceof HTMLElement && !event.target.closest('.offer-actions')) {
            onClickOutside?.(event);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <Options className={'offer-actions'} isInModal={isInModal}>
            {elements.map((element, index) => <ActionWrapper key={index} onClick={element.onClick} darkMode={element.darkMode} isBlocked={element.isBlocked}>
                {element.children}
            </ActionWrapper>)}
        </Options>
    );
};

export default OfferActions;
