import React, { FC } from 'react';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: ${({ fitParentContainer }) => fitParentContainer ? 'absolute' : 'fixed'};
  top: 0;
  left: 0;
  height: ${({ fitParentContainer }) => fitParentContainer ? '100%' : '100vh'};
  width: ${({ fitParentContainer }) => fitParentContainer ? '100%' : '100vw'};
  background: rgba(${(props) => props.darkMode ? '0, 0, 0' : '255, 255, 255'}, 0.6);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Root = styled.div`
    position: relative;
    width: 64px;
    height: 64px;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 56px;
        height:56px;
        filter: ${(props) => props.darkMode ? 'brightness(0) invert(1)' : 'brightness(0) saturate(100%) invert(21%) sepia(95%) saturate(2285%) hue-rotate(201deg) brightness(97%) contrast(101%)'};
    }

    .circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 3px solid transparent;
        border-top-color: ${(props) => props.darkMode ? '#FFFFFF' : '#0255aa'};
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

interface IndicatorPropsType {
    fitParentContainer?: boolean;
}

const Indicator: FC<IndicatorPropsType> = ({ fitParentContainer }) => {
    const { user } = useUser();

    return (
        <Wrapper fitParentContainer={fitParentContainer} darkMode={user.isDarkMode}>
            <Root darkMode={user.isDarkMode}>
                <img src="/logo_favicon.png" alt="Skaner logo" />
                <div className="circle"></div>
            </Root>
        </Wrapper>
    );
};

export default Indicator;
