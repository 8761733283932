import React, { FC, useMemo } from 'react';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { AuctionFiltersStateType } from 'utils/types/Auction';
import { BailiffFiltersStateType } from 'utils/types/Bailiff';
import { ModulesType } from 'utils/types/ModulesType';
import { RentFiltersStateType } from 'utils/types/Rent';
import { SaleFiltersStateType } from 'utils/types/Sale';
import { TenderFiltersStateType } from 'utils/types/Tender';

import Button from 'components/atom/Button';
import Pagination from 'components/common/Pagination/Pagination';
import V2ActiveFilters, { FilterItem } from 'components/common/V2ActiveFilters';

import SingleOffer, { SingleOfferProps } from './SingleOffer';

const Container = styled.div`
    padding: 30px 20px 0;

    @media (max-width: 1100px) {
        padding: 20px 20px 0;
    }
`;

const ContainerList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const NoItemsInfo = styled.div`
    text-align: center;
    margin-top: 50px;
    margin-left: 15px;
    margin-right: 15px;
    min-height: 300px;
    height: fit-content;
    padding: 30px 0;
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    h1 {
        --color-primary: ${(props) => props.darkMode ? '#fff' : '#000'};
        line-height: 30px;
        padding: 40px;
    }

    div {
        margin-top: 15px;
        padding: 0 40px;

        p {
            line-height: 30px;
            margin-top: 10px;

            &:first-child {
                margin-bottom: 35px;
            }
        }
    }
`;

const ShowMoreSection = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShowMoreButton = styled(Button)`
  background: var(--color-alt) !important;
  margin-bottom: 12px;

  img {
    filter: ${(props) => props.darkMode ? 'invert(77%) sepia(83%) saturate(1712%) hue-rotate(194deg) brightness(90%) contrast(103%)' : 'invert(14%) sepia(99%) saturate(2391%) hue-rotate(198deg) brightness(93%) contrast(101%)'};
  }
`;

const ShowMoreDescription = styled.p`
  --font-size-body: var(--font-size-body-3);

  opacity: .7;
  line-height: 18px;
  text-align: center;
`;

// TODO: Po zmianie API w rent tylko string
export interface RenderList extends SingleOfferProps {
    id: string | number;
}

export interface OfferListProperties {
    totalPages: number,
    handleChangePage: (currentPage: number, rows?: number) => void,
    handleActiveFiltersChange?: () => void,
    renderList: RenderList[],
    showList?: boolean,
    showNoItemsText?: boolean,
    activeFiltersState?: RentFiltersStateType | SaleFiltersStateType | BailiffFiltersStateType | TenderFiltersStateType | AuctionFiltersStateType,
    pageNumber: number,
    lastPage: boolean,
    handleOlderAdverts?: () => void,
    type?: ModulesType,
    isLoadMoreButton?: boolean,
    customNoOffersMessage?: JSX.Element;
    filters?: FilterItem[],
    onDeleteFilter?: (filterName: string, value?: string | number | null | string[], index?: number) => void,
}

const V2OfferList: FC<OfferListProperties> = ({
    totalPages,
    handleChangePage,
    handleActiveFiltersChange,
    renderList,
    showList,
    showNoItemsText,
    activeFiltersState,
    pageNumber,
    lastPage,
    handleOlderAdverts,
    isLoadMoreButton,
    customNoOffersMessage,
    filters,
    onDeleteFilter
}) => {
    const { user } = useUser();

    const renderedElements = useMemo(() => renderList?.map((el) => {
        return (
            <SingleOffer
                {...el}
                key={el.id}
            />
        );
    }), [renderList]);

    const activeFilters = useMemo(() => {
        if (handleActiveFiltersChange && filters && onDeleteFilter) {
            return (
                <V2ActiveFilters
                    filters={filters}
                    onDeleteFilter={onDeleteFilter}
                    onChange={handleActiveFiltersChange}
                />
            );
        }
    }, [renderList, activeFiltersState]);

    return (
        showList || showNoItemsText
            ? <Container id="offer-list">
                {activeFilters}
                {showList
                    ? <>
                        <ContainerList>
                            {renderedElements}
                        </ContainerList>
                        {lastPage && isLoadMoreButton
                            ? <ShowMoreSection>
                                <ShowMoreButton darkMode={user.isDarkMode} onClick={handleOlderAdverts}>
                                    Pokaż starsze ogłoszenia...
                                </ShowMoreButton>
                                <ShowMoreDescription>
                                    Skończyły się ogłoszenia w wybranym przedziale.
                                </ShowMoreDescription>
                                <ShowMoreDescription>
                                    Aby zobaczyć starsze ogłoszenia naciśnij powyższy przycisk.
                                </ShowMoreDescription>
                            </ShowMoreSection>
                            : null}
                        <Pagination onChangePage={handleChangePage} amountSites={totalPages} pageNumber={pageNumber}/>
                    </>
                    : showNoItemsText
                        ? customNoOffersMessage
                            ? customNoOffersMessage
                            : <NoItemsInfo darkMode={user.isDarkMode}>
                                <h1>Nie znaleźliśmy żadnych ogłoszeń</h1>
                                <div><p>Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria.
                                    Spróbuj
                                    zmienić kryteria i wyszukaj jeszcze raz.</p>

                                <p>Chcesz otrzymać powiadomienie, jeśli znajdziemy nieruchomość spełniającą Twoje
                                    kryteria?
                                    Zapisz swoje wyszukiwanie, aby otrzymywać powiadomienia.</p></div>
                            </NoItemsInfo>
                        : null}
            </Container>
            : null
    );
};

export default V2OfferList;
