import { AxiosResponse } from 'axios';
import qs from 'qs';
import { axiosApiInstance, axiosApiInstance2 } from 'Store';
import {
    MessagesAdvertType,
    MessageSenders,
    UserOffersResponseContent,
    UserProfileOffersResponseContent
} from 'store/User/State';
import { convertPrice } from 'utils/convertPrice';
import { getPropertyByTypeUser } from 'utils/formatters/getPropertyByType';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import { UploadRequestData } from 'utils/types/File';
import { UserSavedFilter } from 'utils/types/Filter';
import { SavedLocationsType } from 'utils/types/Locations';
import { OfferData, UserReactions } from 'utils/types/OfferData';
import {
    UserAddAdvertStateType,
    UserFilterReturnData,
    UserOfferRequestData,
    V2OfferType
} from 'utils/types/UsersAdvert';
import { MyDataDto } from 'utils/types/UserSettingsModels';

import { activeNotification } from 'components/functions/activeNotification';

const authenticate = async (email: string, password: string) => {
    return axiosApiInstance.post('/api/auth/login', { username: email, password })
        .then((resp) => {
            return {
                user: resp.data.profile,
                token: resp.data.accessToken,
                refreshToken: resp.data.refreshToken,
                roles: resp.data.roles
            };
        })
        .catch(({ response }) => {
            switch (response.status) {
            case 403:
                switch (response.data?.code) {
                case '[jwt_auth] incorrect_password':
                    activeNotification(
                        'Błędne hasło',
                        'Wpisz poprawne hasło',
                        'danger'
                    );
                    break;
                default:
                    activeNotification(
                        'Coś poszło nie tak!',
                        response.data.message,
                        'danger',
                        5000
                    );
                    break;
                }
                break;
            case 500:
                activeNotification(
                    'Błąd serwera',
                    response.data,
                    'danger'
                );
                break;
            default:
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );
                break;
            }

            return false;
        });
};

const authenticate2 = async (email: string, password: string) => {
    return axiosApiInstance2.post('/auth/authenticate', { username: email, password })
        .then((resp) => {
            return {
                user: resp.data.profile,
                token: resp.data.accessToken,
                refreshToken: resp.data.refreshToken,
                roles: resp.data.roles,
                organization: resp.data.organization
            };
        })
        .catch(({ response }) => {
            switch (response.status) {
            case 403:
                switch (response.data?.code) {
                case '[jwt_auth] incorrect_password':
                    activeNotification(
                        'Błędne hasło',
                        'Wpisz poprawne hasło',
                        'danger'
                    );
                    break;
                default:
                    activeNotification(
                        'Coś poszło nie tak!',
                        response.data.message,
                        'danger',
                        5000
                    );
                    break;
                }
                break;
            case 500:
                activeNotification(
                    'Błąd serwera',
                    response.data,
                    'danger'
                );
                break;
            default:
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );
                break;
            }

            return false;
        });
};

const authenticateByToken = async (refreshToken: string) => {
    axiosApiInstance.defaults.headers.common = { Authorization: false };

    return axiosApiInstance.put('/api/auth/refreshToken', {
        refreshToken
    })
        .then((resp) => {
            if (resp) {
                return {
                    user: {},
                    token: resp.data.accessToken,
                    refreshToken: resp.data.refreshToken
                };
            }
        })
        .catch((resp) => {
            if (resp.response.status === 403) {
                return false;
            } else if (resp.response.status === 500) {
                activeNotification(
                    'Błąd serwera',
                    resp.data.message,
                    'danger'
                );
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Nie udało się odświeżyć sesji, zaloguj się ponownie',
                    'danger'
                );
            }

            return false;
        });
};

const authenticateByToken2 = async (refreshToken: string) => {
    axiosApiInstance2.defaults.headers.common = { Authorization: false };

    return axiosApiInstance2.put('/auth/refreshToken', {
        refreshToken
    })
        .then((resp) => {
            if (resp) {
                return {
                    user: {},
                    token: resp.data.accessToken,
                    refreshToken: resp.data.refreshToken
                };
            }
        })
        .catch((resp) => {
            if (resp.response.status === 403) {
                return false;
            } else if (resp.response.status === 500) {
                activeNotification(
                    'Błąd serwera',
                    resp.data.message,
                    'danger'
                );
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Nie udało się odświeżyć sesji, zaloguj się ponownie',
                    'danger'
                );
            }

            return false;
        });
};

const logout = async () => {
    return axiosApiInstance.get('/api/auth/logout')
        .then(() => {
            activeNotification(
                'Zrobione!',
                'Pomyślnie wylogowano',
                'success'
            );

            return true;
        })
        .catch((resp) => {
            if (resp.response.status === 500) {
                activeNotification(
                    'Błąd serwera',
                    resp.data.message,
                    'danger'
                );
            }

            return false;
        });
};

const getSavedFilters = async (): Promise<UserSavedFilter[]> => {
    return axiosApiInstance2
        .get<UserSavedFilter[]>('/user-offer/filter/list')
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    name: el.name
                }));
            }

            return [];
        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                window.parent.location.reload();
            }

            return [];
        });
};

const getFilter = (filterId: number, filterOfferType: V2OfferType): Promise<UserFilterReturnData | void> => {
    return axiosApiInstance2
        .get<UserFilterReturnData>(`/user-offer/filter/detail/${filterOfferType}?filterId=${filterId}`)
        .then((resp) => {
            const filters = resp.data;
            const savedLocations: SavedLocationsType = {};

            filters.city.forEach((city) => {
                // @ts-expect-error dont need districts and cities
                if (city.fullName && city.fullName.length > 0) savedLocations[city.fullName] = { ...city, label: city.fullName };
            });

            return {
                ...filters,
                offerType: filterOfferType,
                savedLocations
            };
        })
        .catch(() => {
            activeNotification('Problem z załadowaniem danych', 'Niestety nie udało się załadować danych. Spróbuj ponownie później', 'danger');
        });
};

const updateFilter = (params: UserOfferRequestData, propertyType: V2OfferType): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'put',
        url: `/user-offer/filter/edit/${propertyType}`,
        // @ts-expect-error fix in next phase
        params: { id: params.id },
        data: {
            ...params
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został zaktualizowany', 'success');

            return resp;
        })
        .catch((err) => {
            activeNotification('Problem z zapisem', 'Niestety nie udało się zapisać danych. Spróbuj ponownie później', 'danger');

            return Promise.reject(err);
        });
};

const deleteFilter = (filterId: number, adType: V2OfferType) => {
    return axiosApiInstance2
        .delete(`/user-offer/filter/delete?filterId=${filterId}&module=${getPropertyByTypeUser(adType)}`)
        .then(() => {
            activeNotification('Udało się!', 'Filtr został usunięty', 'success');
        })
        .catch(() => {
            activeNotification('Problem z usunięciem', 'Niestety nie udało się usunąć danych. Spróbuj ponownie później', 'danger');
        });
};

const addFilter = (params: UserOfferRequestData, propertyType: V2OfferType): Promise<AxiosResponse<number>> => {
    const savedLocations = Object.values(params.savedLocations).map((x) => {
        // @ts-expect-error dont need districts and cities
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { districts, cities, ...rest } = x;

        return { ...rest };
    });

    return axiosApiInstance2({
        method: 'post',
        url: `/user-offer/filter/add/${propertyType}`,
        data: {
            ...params,
            city: savedLocations
        },
        paramsSerializer: function (params) {
            return qs.stringify(params);
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został dodany', 'success');

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const addImage = (params: UploadRequestData, offerType: V2OfferType): Promise<AxiosResponse<string>> => {
    const formData = new FormData();
    formData.append('file', params.file);

    return axiosApiInstance2.post(`/user-offer/photo/advertisement/add?module=${getPropertyByTypeUser(offerType)}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const addFile = (params: UploadRequestData, offerType: V2OfferType): Promise<AxiosResponse<string>> => {
    const formData = new FormData();
    formData.append('file', params.file);

    return axiosApiInstance2.post(`/user-offer/document/add?module=${getPropertyByTypeUser(offerType)}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const downloadFile = (documentId: string, offerType: V2OfferType) => {
    return axiosApiInstance2.get('/user-offer/document/download', {
        responseType: 'blob',
        params: {
            fileName: documentId,
            module: getPropertyByTypeUser(offerType)
        }
    })
        .then((resp) => {
            const url = URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');

            link.setAttribute('href', url);
            link.setAttribute('download', documentId);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const getOffers = (page: number, size: number, params?: UserOfferRequestData, offerType?: V2OfferType): Promise<AxiosResponse> => {
    if (params) {
        Object.keys(params).forEach((key) => {
            if ((Array.isArray(params[key as keyof typeof params]) || typeof params[key as keyof typeof params] === 'string') && (params[key as keyof typeof params] as []).length === 0) {
                delete params[key as keyof typeof params];
            }
        });
    }

    return axiosApiInstance2({
        method: offerType ? 'post' : 'get',
        url: `/user-offer/advertisement/search/${offerType || 'all'}`,
        params: {
            page,
            size
        },
        data: {
            ...params,
            ...params?.daysAmount === -1 && { dateAddedFrom: new Date(1970) }
            // priceFrom: stringToNumber(params.priceFrom),
            // priceTo: stringToNumber(params.priceTo),
            // areaFrom: stringToNumber(params.areaFrom),
            // areaTo: stringToNumber(params.areaTo),
            // pricePerSquareMeterFrom: stringToNumber(params.pricePerSquareMeterFrom),
            // pricePerSquareMeterTo: stringToNumber(params.pricePerSquareMeterTo),
            // rentPriceFrom: stringToNumber(params.rentPriceFrom),
            // rentPriceTo: stringToNumber(params.rentPriceTo),
            // depositPriceFrom: stringToNumber(params.depositPriceFrom),
            // depositPriceTo: stringToNumber(params.depositPriceTo),
        }
    })
        .then((resp) => {
            if (resp.status === 204) {
                return { empty: true };
            }

            if (resp.data) {
                return {
                    ...resp.data,
                    content: resp.data.content.map((el: UserOffersResponseContent) => ({
                        ...el,
                        price: el.price ? convertPrice(el.price) : '',
                        maximumInvestmentValue: el.maximumInvestmentValue ? convertPrice(el.maximumInvestmentValue) : '',
                        area: el.area ? convertPrice(el.area) : '',
                        investorContribution: el.investorContribution ? convertPrice(el.investorContribution) : ''
                    }))
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getAllOffers = (page: number, size: number): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'post',
        url: '/user-offer/advertisement/search/all',
        params: {
            page,
            size
        }
    })
        .then((resp) => {
            if (resp.status === 204) {
                return { empty: true };
            }

            if (resp.data) {
                return {
                    ...resp.data,
                    content: resp.data.content.map((el: UserProfileOffersResponseContent) => ({
                        ...el,
                        price: el.price ? convertPrice(el.price) : '',
                        maximumInvestmentValue: el.maximumInvestmentValue ? convertPrice(el.maximumInvestmentValue) : '',
                        area: el.area ? convertPrice(el.area) : '',
                        investorContribution: el.investorContribution ? convertPrice(el.investorContribution) : ''
                    }))
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getOfferDetails = (offerType: V2OfferType, id: string): Promise<AxiosResponse> => {
    return axiosApiInstance2
        .get(`/user-offer/advertisement/detail/${offerType}?advertisementId=${id}`)
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    price: resp.data.price ? convertPrice(resp.data.price) : ''
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać ogłoszenia',
                'Nie udało się pobrać danych szczegółowych ogłoszenia',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getMyOffers = (offerType: V2OfferType, page: number, size: number): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'get',
        url: '/user-offer/advertisement/edit/list',
        params: {
            page,
            module: getPropertyByTypeUser(offerType),
            size
        }
    })
        .then((resp) => {
            if (resp.status === 204) {
                return { empty: true };
            }

            if (resp.data) {
                return {
                    ...resp.data,
                    content: resp.data.content.map((el: UserOffersResponseContent) => ({
                        ...el,
                        price: el.price ? convertPrice(el.price) : '',
                        area: el.area ? convertPrice(el.area) : '',
                        maximumInvestmentValue: el.maximumInvestmentValue ? convertPrice(el.maximumInvestmentValue) : '',
                        investorContribution: el.investorContribution ? convertPrice(el.investorContribution) : ''
                    }))
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getMyOfferDetails = (offerType: V2OfferType, advertisementId: number | string): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'get',
        url: `/user-offer/advertisement/edit/${offerType}/detail`,
        params: {
            advertisementId
        }
    })
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return [{ empty: true }];
        });
};

const deleteMyOffer = (advertisementId: number | string, offerType: V2OfferType): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'delete',
        url: '/user-offer/advertisement/edit/delete',
        params: {
            module: getPropertyByTypeUser(offerType),
            advertisementId
        }
    })
        .then((resp) => {
            if (resp.data) {
                activeNotification(
                    'Udało się!',
                    'Ogłoszenie zostało usunięte',
                    'success'
                );

                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak!',
                'Nie udało się usunąć ogłoszenia',
                'warning'
            );
        });
};

const addNewOffer = (params: UserAddAdvertStateType): Promise<AxiosResponse> => {
    const requestParams = {
        ...params,
        price: stringToNumber(params.price),
        area: stringToNumber(params.area),
        expectedProfit: stringToNumber(params.expectedProfit),
        renovationExpenses: stringToNumber(params.renovationExpenses),
        investmentBudget: stringToNumber(params.investmentBudget),
        roi: stringToNumber(params.roi),
        leaseFee: stringToNumber(params.leaseFee),
        ownContribution: stringToNumber(params.ownContribution),
        investorContribution: stringToNumber(params.investorContribution),
        totalInvestmentValue: stringToNumber(params.totalInvestmentValue),
        minimumSalePrice: stringToNumber(params.minimumSalePrice),
        maximumSalePrice: stringToNumber(params.maximumSalePrice),
        formOfProfit: params.formOfProfit,
        minimumProfitAmount: stringToNumber(params.minimumProfitAmount),
        expectedReturnRate: stringToNumber(params.expectedReturnRate),
        floor: stringToNumber(params.floor),
        floors: stringToNumber(params.floors),
        rentalIncome: stringToNumber(params.rentalIncome),
        propertyCondition: params.propertyCondition,
        percentageOfInvestment: stringToNumber(params.percentageOfInvestment),
        investmentAmountFrom: stringToNumber(params.investmentAmountFrom),
        investmentAmountTo: stringToNumber(params.investmentAmountTo),
        minimumInvestmentValue: stringToNumber(params.minimumInvestmentValue),
        maximumInvestmentValue: stringToNumber(params.maximumInvestmentValue),
        investmentTimeFrom: stringToNumber(params.investmentTimeFrom),
        investmentTimeTo: stringToNumber(params.investmentTimeTo),
        availableFrom: params.availableFrom ? new Date(params.availableFrom) : undefined,
        estimatedSaleDate: params.estimatedSaleDate ? new Date(params.estimatedSaleDate) : undefined
    };
    const json = JSON.stringify({ ...requestParams });
    const blob = new Blob([json], {
        type: 'application/json'
    });
    const formData = new FormData();
    formData.append('addDto', blob);

    return axiosApiInstance2({
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        url: `/user-offer/advertisement/add/${params.offerType}`,
        data: requestParams
    })
        .then((resp) => {
            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const setOfferReaction = async (id: string, city: string, reactions: UserReactions, offerType: V2OfferType) => {
    return axiosApiInstance2({
        method: 'PUT',
        url: 'reaction/process-uuid',
        params: {
            module: getPropertyByTypeUser(offerType)
        },
        data: {
            ownerId: id,
            ...reactions,
            city
        }
    })
        .then((resp) => {
            return resp.data;
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak!',
                'Spróbuj ponownie',
                'danger'
            );

            return false;
        });
};

const getNotes = (offerType: V2OfferType, id: string) => {
    return axiosApiInstance2({
        url: 'note/get-notes-uuid',
        method: 'get',
        params: {
            module: getPropertyByTypeUser(offerType),
            ownerId: id
        }
    })
        .then((resp) => {
            if (resp.data && resp.data.length > 0) {
                return resp.data;
            }

            return [];
        })
        .catch(() => {
            return [];
        });
};

const deleteNote = (offerType: V2OfferType, noteId: string) => {
    return axiosApiInstance2({
        method: 'delete',
        url: `note/delete-uuid/${noteId}`,
        params: {
            module: getPropertyByTypeUser(offerType)
        }
    })
        .then(() => {
            activeNotification(
                'Notatka usunięta',
                'Pomyślnie usunięto notatkę',
                'success'
            );

            return [];
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak',
                'Niestety nie udało się usunąć notatki. Spróbuj ponownie.',
                'warning'
            );

            return [];
        });
};

const updateNote = (offerType: V2OfferType, note: string, id: string, noteId?: string): Promise<OfferData[]> => {
    return axiosApiInstance2({
        method: 'put',
        url: 'note/process-uuid',
        params: {
            module: getPropertyByTypeUser(offerType)
        },
        data: {
            ownerId: id,
            content: note,
            noteId: !noteId ? undefined : noteId
        }
    })
        .then((resp) => {
            activeNotification(
                'Notatka zaaktualizowana',
                'Aktualizacja notatki przebiegła pomyślnie',
                'success'
            );

            return { ...resp.data, advertisementId: resp.data.advertisementId ? resp.data.advertisementId : id };
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak',
                'Niestety nie udało się zaaktualizować notatki. Spróbuj ponownie.',
                'warning'
            );
        });
};

const getOwnerProfile = (profileId: number, page: number, size: number): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'get',
        url: 'user-offer/profile/advertisements',
        params: {
            profileId,
            page,
            size
        }
    })
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    content: resp.data.content.map((el: UserOffersResponseContent) => ({
                        ...el,
                        price: el.price ? convertPrice(el.price) : '',
                        maximumInvestmentValue: el.maximumInvestmentValue ? convertPrice(el.maximumInvestmentValue) : '',
                        area: el.area ? convertPrice(el.area) : '',
                        investorContribution: el.investorContribution ? convertPrice(el.investorContribution) : ''
                    }))
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono użytkownika',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return [{ empty: true }];
        });
};

const saveProfilePhoto = (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);

    return axiosApiInstance2.post('/user/profile/image/create', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'warning'
                );

                return;
            }

            activeNotification(
                'Nie udało się zapisać zdjęcia',
                'Spróbuj ponownie',
                'warning'
            );
        });
};

const getProfile = (): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'get',
        url: 'user/profile/info'
    })
        .then((resp) => {
            if (resp.status === 204) {
                return {
                    name: '',
                    email: '',
                    phoneNumber: '',
                    description: '',
                    userPhoto: ''
                };
            }

            if (resp.data) {
                return resp.data;
            }
        })
        .catch(({ response }) => {
            activeNotification(
                'Nie udało się pobrać profilu',
                response.data,
                'warning'
            );

            return {
                name: '',
                email: '',
                phoneNumber: '',
                description: '',
                userPhoto: ''
            };
        });
};

const updateProfile = (params: MyDataDto, onDeletePhoto?: boolean): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        headers: { 'Content-Type': 'application/json' },
        method: 'put',
        url: '/user/profile/update',
        data: {
            profileName: params.profileName,
            profilePhone: params.profilePhone,
            profileEmail: params.profileEmail,
            description: params.description,
            userPhoto: params.userPhoto
        }
    })
        .then((resp) => {
            if (resp.data) {
                activeNotification(
                    onDeletePhoto ? 'Zdjęcie profilowe' : 'Edycja Twojego profilu',
                    onDeletePhoto ? 'Twoje zdjęcie profilowe zostało usunięte' : 'Twój profil został zaktualizowany pomyślnie',
                    'success'
                );

                return resp.data;
            }
        })
        .catch(({ response }) => {
            activeNotification(
                'Nie udało się aktualizować profilu',
                response.data,
                'warning'
            );
        });
};

const handleEditOffer = (params: UserAddAdvertStateType): Promise<AxiosResponse> => {
    const requestParams = {
        ...params,
        price: stringToNumber(params.price),
        area: stringToNumber(params.area),
        expectedProfit: stringToNumber(params.expectedProfit),
        renovationExpenses: stringToNumber(params.renovationExpenses),
        investmentBudget: stringToNumber(params.investmentBudget),
        roi: stringToNumber(params.roi),
        leaseFee: stringToNumber(params.leaseFee),
        ownContribution: stringToNumber(params.ownContribution),
        investorContribution: stringToNumber(params.investorContribution),
        totalInvestmentValue: stringToNumber(params.totalInvestmentValue),
        minimumSalePrice: stringToNumber(params.minimumSalePrice),
        maximumSalePrice: stringToNumber(params.maximumSalePrice),
        formOfProfit: params.formOfProfit,
        minimumProfitAmount: stringToNumber(params.minimumProfitAmount),
        expectedReturnRate: stringToNumber(params.expectedReturnRate),
        floor: stringToNumber(params.floor),
        floors: stringToNumber(params.floors),
        rentalIncome: stringToNumber(params.rentalIncome),
        propertyCondition: params.propertyCondition,
        percentageOfInvestment: stringToNumber(params.percentageOfInvestment),
        investmentAmountFrom: stringToNumber(params.investmentAmountFrom),
        investmentAmountTo: stringToNumber(params.investmentAmountTo),
        minimumInvestmentValue: stringToNumber(params.minimumInvestmentValue),
        maximumInvestmentValue: stringToNumber(params.maximumInvestmentValue),
        investmentTimeFrom: stringToNumber(params.investmentTimeFrom),
        investmentTimeTo: stringToNumber(params.investmentTimeTo),
        availableFrom: params.availableFrom ? new Date(params.availableFrom) : undefined,
        estimatedSaleDate: params.estimatedSaleDate ? new Date(params.estimatedSaleDate) : undefined
    };

    return axiosApiInstance2({
        headers: { 'Content-Type': 'application/json' },
        method: 'put',
        url: `/user-offer/advertisement/edit/${params.offerType}/save`,
        data: requestParams
    })
        .then((resp) => {
            activeNotification(
                'Udało się!',
                'Ogłoszenie zostało zaktualizowane',
                'success'
            );

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const getMessages = (module: MessagesAdvertType): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'get',
        url: `user-offer/message/my/${module}`
    })
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wiadomości',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );
        });
};

const sendMessage = (module: string, messageSender: MessageSenders, content: string, advertisementId: string, firstMessageId?: string): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'post',
        url: 'user-offer/message/send',
        params: {
            module
        },
        data: {
            content,
            advertisementId,
            messageSender,
            firstMessageId
        }
    })
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się wysłać wiadomości',
                'Wysyłanie wiadomości nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );
        });
};

const removeMessages = (lastMessageId: string): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'delete',
        url: 'user-offer/message/delete',
        params: {
            lastMessageId
        }
    })
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się wysłać wiadomości',
                'Wysyłanie wiadomości nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );
        });
};

const getMessagesDetail = (lastMessageId: string): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'get',
        url: 'user-offer/message/detail',
        params: {
            lastMessageId
        }
    })
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wiadomości',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );
        });
};

const readMessage = (lastMessageId: string): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'put',
        url: 'user-offer/message/read',
        params: {
            lastMessageId
        }
    })
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wiadomości',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );
        });
};

const checkIfProfileExists = async () => {
    return axiosApiInstance2.get('/user/profile/activation-status')
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
};

const changeVisibilityOffer = async (module: string, advertisementId: string, value: boolean): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'put',
        url: 'user-offer/advertisement/edit/status',
        params: {
            module,
            advertisementId,
            status: value
        }
    })
        .then((resp) => {
            if (resp.data) {
                activeNotification(
                    'Edycja ogłoszenia',
                    'Aktualizacja widoczności przebiegła pomyślnie.',
                    'success'
                );

                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Edycja ogłoszenia',
                'Aktualizacja zmiany widoczności oferty nie powiodła się, spróbuj jeszcze raz.',
                'warning'
            );
        });
};

const changePushNotificationStatus = async (value: boolean): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'put',
        url: `user/notification/edit/push-notification?activeStatus=${value}`
    })
        .then((resp) => {
            if (resp.data) {
                activeNotification(
                    'Udało się!',
                    'Aktualizacja ustawień notyfikacji przebiegła pomyślnie.',
                    'success'
                );

                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Ups, coś poszło nie tak',
                'Aktualizacja ustawień notyfikacji nie powiodła się, spróbuj jeszcze raz.',
                'warning'
            );
        });
};

const changeNotificationMailAndPhoneNumber = async (email: string, phone: string): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'put',
        url: 'user/notification/edit/notification',
        data: {
            notificationEmail: email,
            notificationPhone: phone
        }
    })
        .then((resp) => {
            if (resp.data) {
                activeNotification(
                    'Udało się!',
                    'Edyja danych do wysyłania notyfikacji przebiegła pomyślnie.',
                    'success'
                );

                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Ups, coś poszło nie tak',
                'Edyja danych do wysyłania notyfikacji nie powiodła się, spróbuj jeszcze raz.',
                'warning'
            );
        });
};

const changeSmsAndMailNotificationStatus = async (value: boolean): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'put',
        url: `user/notification/edit/active-notification?activeStatus=${value}`
    })
        .then((resp) => {
            if (resp.data) {
                activeNotification(
                    'Udało się!',
                    'Aktualizacja ustawień powiadomień SMS/mail przebiegła pomyślnie.',
                    'success'
                );

                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Ups, coś poszło nie tak',
                'Aktualizacja ustawień powiadomień SMS/mail nie powiodła się, spróbuj jeszcze raz.',
                'warning'
            );
        });
};

const changeDayLimit = async (value: number): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'put',
        url: `user/notification/edit/day-limit?dayLimit=${value}`
    })
        .then((resp) => {
            if (resp.data) {
                activeNotification(
                    'Udało się!',
                    'Aktualizacja dziennego limitu SMS przebiegła pomyślnie.',
                    'success'
                );

                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Ups, coś poszło nie tak',
                'Aktualizacja dziennego limitu SMS nie powiodła się, spróbuj jeszcze raz.',
                'warning'
            );
        });
};

const getActivePackageInfo = (): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'get',
        url: 'user/active-package/info'
    })
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch(({ response }) => {
            activeNotification(
                'Nie udało się  informacji o pakiecie',
                response.data,
                'warning'
            );
        });
};

const getNotificationSettings = (): Promise<AxiosResponse> => {
    return axiosApiInstance2({
        method: 'get',
        url: 'user/notification/info'
    })
        .then((resp) => {
            if (resp.status === 204) {
                return {
                    name: '',
                    email: '',
                    phoneNumber: '',
                    description: '',
                    userPhoto: ''
                };
            }

            if (resp.data) {
                return resp.data;
            }
        })
        .catch(({ response }) => {
            activeNotification(
                'Nie udało się pobrać profilu',
                response.data,
                'warning'
            );

            return {
                name: '',
                email: '',
                phoneNumber: '',
                description: '',
                userPhoto: ''
            };
        });
};

export const userApiRequests = {
    getAllOffers,
    authenticate,
    authenticate2,
    authenticateByToken,
    authenticateByToken2,
    logout,
    getSavedFilters,
    getFilter,
    updateFilter,
    addFilter,
    deleteFilter,
    getOffers,
    getMyOffers,
    getMyOfferDetails,
    getOfferDetails,
    addNewOffer,
    setOfferReaction,
    getNotes,
    deleteNote,
    updateNote,
    addImage,
    getOwnerProfile,
    deleteMyOffer,
    handleEditOffer,
    getProfile,
    saveProfilePhoto,
    updateProfile,
    getMessages,
    sendMessage,
    getMessagesDetail,
    removeMessages,
    readMessage,
    addFile,
    checkIfProfileExists,
    changeVisibilityOffer,
    downloadFile,
    changePushNotificationStatus,
    changeSmsAndMailNotificationStatus,
    changeNotificationMailAndPhoneNumber,
    changeDayLimit,
    getActivePackageInfo,
    getNotificationSettings
};
