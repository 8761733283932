import { BailiffSavedFilter, SavedFilter } from 'utils/types/Filter';

import { AuctionSavedFilter, TenderSavedFilter } from './types/Filter';

export const createUniqueFilterName = (baseName: string, existingFilters: TenderSavedFilter[] | AuctionSavedFilter[] | BailiffSavedFilter[] | SavedFilter[]): string => {
    const baseNameWithoutCopy = baseName.replace(/\s+kopia\s+\d+$/, '');
    let counter = 1;
    let newName = `${baseNameWithoutCopy} kopia ${counter}`;

    while (existingFilters.some((filter) => filter.name === newName)) {
        counter++;
        newName = `${baseNameWithoutCopy} kopia ${counter}`;
    }

    return newName;
};
