import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactionType } from 'store/Offers/State';
import styled from 'styled-components';
import { commonApiRequests } from 'utils/api-requests/common';
import { saleOffersApiRequests } from 'utils/api-requests/sale';
import { isTouchDevice } from 'utils/checkIsTouchDevice';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import {
    SaleOfferDuplicatesResponse,
    SaleOfferHashResponse,
    SaleOfferResponseContent
} from 'utils/state-managment/sale/saleOffer';
import { UserReactions } from 'utils/types/OfferData';
import { Module, NoteDto, SaleHashDto, SaleOpinionResult } from 'utils/types/SaleModels';

import Indicator from 'components/atom/Indicator';
import OfferActions from 'components/common/Card/common/OfferActions/OfferActions';
import {
    InteractiveElements
} from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import SingleOfferDetailsRenderer, {
    SingleOfferDetailsRendererProps
} from 'components/common/SingleOfferDetailsRenderer/SingleOfferDetailsRenderer';
import { SliderImages } from 'components/common/Slider/Slider';
import { V2DuplicatesTabProps } from 'components/common/Tabs/tabs/V2DuplicatesTab';
import { ExtendedNote } from 'components/common/Tabs/tabs/V2NoteTab';
import { TabType } from 'components/common/Tabs/V2Tabs';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import {
    saleAndRentOfferListFunctions
} from 'components/functions/offerListFunctions/saleAndRentOfferListFunctions/saleAndRentOfferListFunctions';
import { generateOfferActionsElements } from 'components/functions/shared/generateOfferActionsElements';

const Wrapper = styled.div`
    margin: 15px;
`;

const NoAdvertContainer = styled.h1`
    text-align: center;
    margin-top: 15%;
`;

const SingleSaleDetailPage = () => {
    const { hash } = useParams();
    const [preparedAdvert, setPreparedAdvert] = useState<SingleOfferDetailsRendererProps>();
    const [moreButtonActiveId, setMoreButtonActiveId] = useState<string | null>(null);
    const [offer, setOffer] = useState<SaleHashDto>();
    const [isLoading, setIsLoading] = useState(true);
    const [duplicates, setDuplicates] = useState<SaleOfferDuplicatesResponse[]>();
    const [notes, setNotes] = useState();
    const [comments, setComments] = useState<SaleOpinionResult>();

    const deleteOpinion = async (id: number, module: Module, opinionId: number) => {
        const response = await saleOffersApiRequests.deleteOpinion(opinionId, module);

        if (response) {
            setPreparedAdvert((prev) => {
                return {
                    ...prev,
                    detailsCardData: {
                        ...prev!.detailsCardData,
                        tabsContent: {
                            ...prev!.detailsCardData.tabsContent,
                            opinionTabContent: {
                                ...prev!.detailsCardData.tabsContent.opinionTabContent!,
                                comments: prev!.detailsCardData.tabsContent.opinionTabContent!.comments!.filter((x) => x.id !== opinionId),
                                commentsCount: response.numberOfCommunityComments,
                                opinionsNumber: response.numberOfCommunityOpinions,
                                opinionsRating: response.communityScore || 0
                            }
                        }
                    }
                };
            });
        }
    };

    const handleChangeReaction = async (name: keyof ReactionType, value: boolean, offer: SaleOfferHashResponse) => {
        const userReaction = Object.assign({}, offer.reaction);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location;

        const response = await commonApiRequests.setOfferReaction(offer.advertisementId, city, userReaction as UserReactions, offer.module);
        setPreparedAdvert((prev) => ({
            ...prev,
            detailsCardData: {
                ...prev!.detailsCardData,
                interactiveElements: prev!.detailsCardData.id === offer.advertisementId ? generateInteractiveElements({ ...offer, reaction: response }) : prev!.detailsCardData.interactiveElements,
                tabsContent: {
                    ...prev!.detailsCardData.tabsContent,
                    duplicatesTabContent: {
                        ...prev!.detailsCardData.tabsContent.duplicatesTabContent,
                        duplicates: prev!.detailsCardData.tabsContent.duplicatesTabContent?.duplicates?.map((x) => {
                            if (x.id === offer.advertisementId) {
                                return {
                                    ...x,
                                    cardData: {
                                        ...x.cardData,
                                        interactiveElements: generateInteractiveElements({
                                            ...offer,
                                            reaction: response
                                        })
                                    }
                                };
                            }

                            return x;
                        })
                    }
                }
            }
        }));
    };

    const handleSaveNote = async (value: string, advertId: number, type: Module, id?: number) => {
        const response = await commonApiRequests.updateNote(type, value, advertId, id || 0);

        if (response) {
            if (id) {
                setPreparedAdvert((prev) => {
                    return {
                        ...prev,
                        detailsCardData: {
                            ...prev!.detailsCardData,
                            tabsContent: {
                                ...prev!.detailsCardData.tabsContent,
                                notesTabContent: {
                                    ...prev!.detailsCardData.tabsContent.notesTabContent!,
                                    notes: prev!.detailsCardData.tabsContent.notesTabContent!.notes!.map((x) => {
                                        if (x.noteId === id) {
                                            return {
                                                ...x,
                                                content: value
                                            };
                                        }

                                        return x;
                                    })
                                }
                            }
                        }
                    };
                });
            } else {
                setPreparedAdvert((prev) => {
                    return {
                        ...prev,
                        detailsCardData: {
                            ...prev!.detailsCardData,
                            tabsContent: {
                                ...prev!.detailsCardData.tabsContent,
                                notesTabContent: {
                                    ...prev!.detailsCardData.tabsContent.notesTabContent!,
                                    notes: prev!.detailsCardData.tabsContent.notesTabContent!.notes ? [response].concat(prev!.detailsCardData.tabsContent.notesTabContent!.notes as unknown as ExtendedNote) : [response]
                                },
                                numberOfNotes: (prev!.detailsCardData.tabsContent.numberOfNotes ?? 0) + 1
                            }
                        }
                    };
                });
            }
        }
    };

    const generateInteractiveElements = (offer: SaleHashDto): InteractiveElements[] => {
        const offerLink = `${window.location.origin}/sale/${offer.encryptedId}`;
        const isMoreButtonActive = moreButtonActiveId === offer.advertisementId.toString();
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link, true);

        return [
            {
                tooltipText: 'Wyświetlono',
                icon: IconEnum.EYE,
                active: offer.reaction?.viewedStatus,
                disabled: offer.reaction?.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reaction?.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reaction?.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reaction?.likeStatus, offer)
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reaction?.unlikeStatus,
                disabled: offer.reaction?.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reaction?.unlikeStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes || 1 : undefined,
                openTabOnClick: TabType.NOTES
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reaction?.hideStatus,
                disabled: offer.reaction?.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reaction?.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reaction?.phoneStatus,
                disabled: offer.reaction?.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reaction?.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reaction?.arrangedStatus,
                disabled: offer.reaction?.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reaction?.arrangedStatus, offer)
            },
            ...offer.phoneNumber
                ? [
                    {
                        icon: IconEnum.PHONE,
                        isLink: true,
                        active: false,
                        isPhoneBtn: false,
                        text: <a href={`tel:${offer.phoneNumber}`}>{offer.phoneNumber}</a>
                    }
                ]
                : [],
            {
                tooltipText: 'Przejdź do ogłoszenia',
                icon: IconEnum.LINK,
                isLink: true,
                noText: true,
                text: <a target="_blank" rel="noopener noreferrer" href={offer.link}/>
            },
            ...offer.photos && offer.photos.length > 0
                ? [
                    {
                        icon: IconEnum.PHOTOS,
                        isModalOpen: true,
                        isLink: true,
                        text: <p>Zobacz zdjęcia</p>,
                        isPhotoBtn: true,
                        visibleDuringModal: true,
                        sliderElements: {
                            photos: createImagesForSlider(offer.photos, offer.module) as SliderImages[],
                            title: offer.title
                        }
                    }
                ]
                : [],
            {
                icon: 'squares' as IconEnum,
                tooltipText: isTouchDevice() ? undefined : 'Więcej',
                onClick: () => isMoreButtonActive ? setMoreButtonActiveId(null) : setMoreButtonActiveId(offer.advertisementId.toString()),
                additionalJSXSibling: isMoreButtonActive ? <OfferActions onClickOutside={() => setMoreButtonActiveId(null)} isInModal elements={offerActionsElements} /> : undefined
            }
        ];
    };

    const handleDelete = async (noteId: number, id: number, type: Module) => {
        const response = await commonApiRequests.deleteNote(type, noteId);

        if (response) {
            setPreparedAdvert((prev) => {
                return {
                    ...prev,
                    detailsCardData: {
                        ...prev!.detailsCardData,
                        tabsContent: {
                            ...prev!.detailsCardData.tabsContent,
                            notesTabContent: {
                                ...prev!.detailsCardData.tabsContent.notesTabContent!,
                                notes: prev!.detailsCardData.tabsContent.notesTabContent!.notes!.filter((x) => x.noteId !== noteId)
                            },
                            numberOfNotes: prev!.detailsCardData.tabsContent.numberOfNotes! - 1
                        }
                    }
                };
            });
        }
    };

    const handleSaveOpinion = async (module: Module, commentContent: string, rating: number, advertisementId: number, opinionId?: number) => {
        if (opinionId) {
            const response = await saleOffersApiRequests.updateOpinion(opinionId, commentContent, rating, module);

            if (response) {
                setPreparedAdvert((prev) => {
                    return {
                        ...prev,
                        detailsCardData: {
                            ...prev!.detailsCardData,
                            tabsContent: {
                                ...prev!.detailsCardData.tabsContent,
                                opinionTabContent: {
                                    ...prev!.detailsCardData.tabsContent.opinionTabContent!,
                                    comments: prev!.detailsCardData.tabsContent.opinionTabContent!.comments!.map((x) => {
                                        if (x.id === opinionId) {
                                            return {
                                                ...x,
                                                content: commentContent,
                                                rating
                                            };
                                        }

                                        return x;
                                    }),
                                    opinionsNumber: response.numberOfCommunityOpinions,
                                    opinionsRating: response.communityScore || 0
                                }
                            }
                        }
                    };
                });
            }
        } else {
            const response = await saleOffersApiRequests.addOpinion(advertisementId, commentContent, rating, module);
            const newOpinions = await saleOffersApiRequests.getOpinions(advertisementId, module);

            if (response) {
                setPreparedAdvert((prev) => {
                    return {
                        ...prev,
                        detailsCardData: {
                            ...prev!.detailsCardData,
                            tabsContent: {
                                ...prev!.detailsCardData.tabsContent,
                                opinionTabContent: {
                                    ...prev!.detailsCardData.tabsContent.opinionTabContent!,
                                    comments: newOpinions?.opinions?.map((opinion) => ({
                                        id: opinion.opinionId,
                                        date: opinion.dateAdded,
                                        content: opinion.content || '',
                                        rating: opinion.numberOfStars,
                                        isUserComment: opinion.loggedUserOpinion
                                    })),
                                    commentsCount: response.numberOfCommunityComments,
                                    opinionsNumber: response.numberOfCommunityOpinions,
                                    opinionsRating: response.communityScore || 0
                                }
                            }
                        }
                    };
                });
            }
        }
    };

    useEffect(() => {
        setIsLoading(true);
        saleOffersApiRequests.getSaleOfferByHashId(hash).then(async (offer) => {
            setOffer(offer);
            const duplicates = await saleOffersApiRequests.getSaleOfferDuplicates(offer.module, offer.advertisementId);
            const notes = await commonApiRequests.getNotes(offer.module, offer.advertisementId.toString());
            const comments = await saleOffersApiRequests.getOpinions(offer.advertisementId, offer.module);

            setDuplicates(duplicates);
            setNotes(notes);
            setComments(comments);
            setIsLoading(false);
        }).catch(() => {
            setPreparedAdvert(undefined);
            setIsLoading(false);
        });
    }, [hash]);

    useEffect(() => {
        if (offer && comments) {
            const generateDuplicateCardData = (offer: SaleOfferDuplicatesResponse) => {
                return {
                    preTitle: offer.location,
                    oldPrice: formatFilterPrice(offer.priceBeforeModification)?.toString(),
                    date: offer.dateAdded,
                    title: offer.title,
                    infoElements: saleAndRentOfferListFunctions.generateInfoElements(offer as SaleOfferResponseContent),
                    interactiveElements: generateInteractiveElements(offer as SaleHashDto),
                    modifications: {
                        modificationsNumber: offer.numberOfModifications ?? 0,
                        boostsNumber: offer.numberOfRaises ?? 0
                    },
                    note: offer.note,
                    modificationDate: offer.lastUpdated,
                    thumbnailPath: offer.photos ? offer.photos[0] : undefined,
                    ratingScore: offer.score ?? 0,
                    additionalInfoBadge: offer.offerFrom,
                    images: createImagesForSlider(offer.photos || [], offer.module!),
                    mainValue: offer.price ? `${formatFilterPrice(offer.price)} zł` : '- zł',
                    link: {
                        url: offer.link,
                        name: offer.portal
                    }
                };
            };
            const duplicatesTabContent: V2DuplicatesTabProps = {
                duplicates: duplicates?.map((duplicate) => {
                    return {
                        id: duplicate.advertisementId,
                        bigImage: true,
                        cardData: generateDuplicateCardData({ ...duplicate, module: offer.module }),
                        onClick: () => {
                        },
                        openOfferTab: () => {
                        }
                    };
                })
            };
            const detailsTabContent = saleAndRentOfferListFunctions.generateOfferDetailsTabContent({
                ...offer,
                note: offer.note as unknown as NoteDto,
                detailedContent: { description: offer.description, metaValue: offer.metaValue }
            });

            const detailsCardData = {
                id: offer.advertisementId,
                interactiveElements: generateInteractiveElements(offer),
                modificationDate: offer.lastUpdated,
                additionalInfoBadge: offer.offerFrom,
                thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './../defaultImg.png',
                date: offer.dateAdded!,
                tabsContent: {
                    interactiveElements: generateInteractiveElements(offer),
                    detailsTabContent,
                    duplicatesTabContent,
                    modificationTabContent: {
                        modifications: offer.modifications || []
                    },
                    notesTabContent: {
                        notes: notes,
                        handleDelete: (noteId: string) => handleDelete(Number(noteId), offer.advertisementId, offer.module),
                        handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.advertisementId, offer.module, Number(id))
                    },
                    opinionTabContent: {
                        handleSaveOpinion: (rating: number, content: string, id?: number) => handleSaveOpinion(offer.module, content, rating, offer.advertisementId, id || offer.myOpinion?.id),
                        comments: comments?.opinions?.map((opinion) => ({
                            id: opinion.opinionId,
                            date: opinion.dateAdded,
                            content: opinion.content || '',
                            rating: opinion.numberOfStars,
                            isUserComment: opinion.loggedUserOpinion
                        })),
                        commentsCount: offer.numberOfCommunityComments,
                        opinionsNumber: offer.numberOfCommunityOpinions,
                        opinionsRating: comments.communityScore || 0,
                        handleDeleteOpinion: (id: number) => deleteOpinion(offer.advertisementId, offer.module, id),
                        applicationScore: offer.score,
                        applicationScorePrecision: offer.scorePrecision
                    },
                    numberOfModifications: offer.numberOfModifications || 0,
                    numberOfDuplicates: offer.numberOfDuplicates || 0,
                    numberOfNotes: offer.numberOfNotes || 0,
                    handleLoadDuplicates: async () => {},
                    handleLoadNotes: () => {
                    },
                    handleLoadComments: () => {
                    }
                }
            };

            setPreparedAdvert({ detailsCardData });
        }
    }, [
        offer,
        comments,
        moreButtonActiveId
    ]);

    return (
        <Wrapper>
            {isLoading
                ? <Indicator/>
                : preparedAdvert?.detailsCardData
                    ? <SingleOfferDetailsRenderer detailsCardData={preparedAdvert.detailsCardData}/>
                    : <NoAdvertContainer>Nie znaleziono ogłoszenia o podanym id.</NoAdvertContainer>}
        </Wrapper>
    );
};

export default SingleSaleDetailPage;
