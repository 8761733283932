import { Dispatch } from 'react';
import Cookies from 'js-cookie';
import { userApiRequests } from 'utils/api-requests/user';
import { stringToBoolean } from 'utils/formatters/stringToBoolean';
import { UserReactions } from 'utils/types/OfferData';
import { UserOfferRequestData, V2OfferType } from 'utils/types/UsersAdvert';

import { activeNotification } from 'components/functions/activeNotification';

import { Action } from './Reducer';
import { MessagesAdvertType } from './State';

const loadMyOffers = async (dispatch: Dispatch<Action>, offerType: V2OfferType, page: number, size: number): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_MY_OFFERS',
        payload: await userApiRequests.getMyOffers(offerType, page, size)
    });
};

const logInUser = async (dispatch: Dispatch<Action>, login: string, password: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    const loggedUser = await userApiRequests.authenticate(login, password);
    const loggedUser2 = await userApiRequests.authenticate2(login, password);

    if (loggedUser && typeof loggedUser === 'object' && loggedUser2 && typeof loggedUser2 === 'object') {
        activeNotification(
            'Zrobione!',
            'Pomyślnie zalogowano',
            'success'
        );
    }

    if (loggedUser && typeof loggedUser === 'object') {
        localStorage.setItem('MonitorApiToken', loggedUser.token);
        localStorage.setItem('MonitorApiRefreshToken', loggedUser.refreshToken);
        localStorage.setItem('MonitorRoles', loggedUser.roles);

        dispatch({
            type: 'LOGIN',
            payload: loggedUser
        });
    }

    if (loggedUser2 && typeof loggedUser2 === 'object') {
        localStorage.setItem('MonitorApiToken2', loggedUser2.token);
        localStorage.setItem('MonitorApiRefreshToken2', loggedUser2.refreshToken);
        localStorage.setItem('MonitorRoles2', loggedUser2.roles);
        localStorage.setItem('isInOrganization', loggedUser2.organization);

        dispatch({
            type: 'LOGIN2',
            payload: loggedUser2
        });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
};

const loginByToken = async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    const apiToken = localStorage.getItem('MonitorApiToken') || Cookies.get('MonitorApiToken');
    const refreshToken = localStorage.getItem('MonitorApiRefreshToken') || Cookies.get('MonitorApiRefresh');
    const roles = localStorage.getItem('MonitorRoles') || Cookies.get('MonitorRoles')?.split(',');

    if (apiToken && refreshToken && roles) {
        dispatch({
            type: 'LOGIN',
            payload: {
                token: apiToken,
                refreshToken,
                roles,
                user: ''
            }
        });
    }

    const apiToken2 = localStorage.getItem('MonitorApiToken2') || Cookies.get('MonitorApiToken2');
    const refreshToken2 = localStorage.getItem('MonitorApiRefreshToken2') || Cookies.get('MonitorApiRefresh2');
    const roles2 = localStorage.getItem('MonitorRoles2') || Cookies.get('MonitorRoles2')?.split(',');
    const organization = stringToBoolean(localStorage.getItem('isInOrganization') || undefined);

    if (apiToken2 && refreshToken2 && roles2) {
        dispatch({
            type: 'LOGIN2',
            payload: {
                token: apiToken2,
                refreshToken2,
                roles2,
                user: '',
                organization
            }
        });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
};

const logout = async (dispatch: Dispatch<Action>): Promise<void> => {
    localStorage.removeItem('MonitorApiToken');
    localStorage.removeItem('MonitorApiRefreshToken');
    localStorage.removeItem('MonitorRoles');

    await userApiRequests.logout();
    dispatch({ type: 'LOGOUT' });
};

const changeTheme = async (dispatch: Dispatch<Action>, isDarkMode: boolean): Promise<void> => {
    localStorage.setItem('darkTheme', isDarkMode.toString());

    dispatch({
        type: 'CHANGE_THEME',
        payload: isDarkMode
    });
};

const changeErrorVisibility = async (dispatch: Dispatch<Action>, error: boolean): Promise<void> => {
    dispatch({
        type: 'CHANGE_ERROR_VISIBILITY',
        payload: error
    });
};

const loadUserOffersAsync = async (dispatch: Dispatch<Action>, page: number, size: number, params?: UserOfferRequestData, offerType?: V2OfferType): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_FILTERS',
        payload: await userApiRequests.getOffers(page, size, params, offerType)
    });
};

const loadOfferDetailsAsync = async (dispatch: Dispatch<Action>, offerType: V2OfferType, id: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_DETAILED_OFFER',
        payload: await userApiRequests.getOfferDetails(offerType, id)
    });
};

const loadMyOfferToEdit = async (dispatch: Dispatch<Action>, offerType: V2OfferType, id: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_DETAILS_TO_EDIT',
        payload: await userApiRequests.getMyOfferDetails(offerType, id)
    });
};

const loadMyOfferDetailsAsync = async (dispatch: Dispatch<Action>, offerType: V2OfferType, id: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_DETAILED_MYOFFER',
        payload: await userApiRequests.getMyOfferDetails(offerType, id)
    });
};

const getOwnerProfile = async (dispatch: Dispatch<Action>, id: number, page: number, size: number): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_PROFILE_OFFERS',
        payload: await userApiRequests.getOwnerProfile(id, page, size)
    });
};

const getProfile = async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_PROFILE',
        payload: await userApiRequests.getProfile()
    });
};

const getNotificationSettings = async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_NOTIFICATION_SETTINGS',
        payload: await userApiRequests.getNotificationSettings()
    });
};

const getActivePackageInfo = async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_ACTIVE_PACKAGE_INFO',
        payload: await userApiRequests.getActivePackageInfo()
    });
};

const loadProfileOfferDetailsAsync = async (dispatch: Dispatch<Action>, offerType: V2OfferType, id: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_DETAILED_PROFILE_OFFER',
        payload: await userApiRequests.getOfferDetails(offerType, id)
    });
};

const clearOffers = async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch({
        type: 'CLEAR'
    });
};

const deleteMyOffer = async (dispatch: Dispatch<Action>, advertisementId: string, offerType: V2OfferType): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    const validChangeStatus = await userApiRequests.deleteMyOffer(advertisementId, offerType);

    if (validChangeStatus) {
        dispatch({
            type: 'DELETE_MY_OFFER',
            payload: advertisementId
        });
    }
};

const reactionUpdate = async (dispatch: Dispatch<Action>, id: string, city: string, reactions: UserReactions, offerType: V2OfferType): Promise<void> => {
    const response = await userApiRequests.setOfferReaction(id, city, reactions, offerType);

    if (response) {
        dispatch({
            type: 'REACTION_UPDATE',
            payload: {
                id, reactions: response
            }
        });
    }
};

const loadNotesAsync = async (dispatch: Dispatch<Action>, offerType: V2OfferType, id: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    dispatch({
        type: 'SET_NOTES',
        payload: {
            id,
            data: await userApiRequests.getNotes(offerType, id)
        }
    });
};

const deleteNote = async (dispatch: Dispatch<Action>, id: string, offerType: V2OfferType, noteId: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    const validChangeStatus = await userApiRequests.deleteNote(offerType, noteId);

    if (validChangeStatus) {
        dispatch({
            type: 'DELETE_NOTE',
            payload: {
                noteId, id
            }
        });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
};

const deleteProfileOffersNote = async (dispatch: Dispatch<Action>, id: string, offerType: V2OfferType, noteId: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    const validChangeStatus = await userApiRequests.deleteNote(offerType, noteId);

    if (validChangeStatus) {
        dispatch({
            type: 'DELETE_PROFILE_OFFERS_NOTE',
            payload: {
                noteId, id
            }
        });
    }

    dispatch({ type: 'SET_LOADING', payload: false });
};

const updateNote = async (dispatch: Dispatch<Action>, id: string, offerType: V2OfferType, note: string, noteId?: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    const validChangeStatus = await userApiRequests.updateNote(offerType, note, id, noteId);

    if (validChangeStatus) {
        if (noteId) {
            dispatch({
                type: 'UPDATE_NOTE',
                payload: {
                    ...validChangeStatus
                }
            });
        } else {
            dispatch({
                type: 'NEW_NOTE',
                payload: {
                    ...validChangeStatus
                }
            });
        }
    }

    dispatch({ type: 'SET_LOADING', payload: false });
};

const updateProfileOffersNote = async (dispatch: Dispatch<Action>, id: string, offerType: V2OfferType, note: string, noteId?: string): Promise<void> => {
    dispatch({ type: 'SET_LOADING' });
    const validChangeStatus = await userApiRequests.updateNote(offerType, note, id, noteId);

    if (validChangeStatus) {
        if (noteId) {
            dispatch({
                type: 'UPDATE_NOTE',
                payload: {
                    ...validChangeStatus
                }
            });
        } else {
            dispatch({
                type: 'NEW_PROFILE_OFFERS_NOTE',
                payload: {
                    ...validChangeStatus
                }
            });
        }
    }
    dispatch({ type: 'SET_LOADING', payload: false });
};

const loadUserMessagesAsync = async (dispatch: Dispatch<Action>, module: MessagesAdvertType): Promise<void> => {
    dispatch({
        type: 'SET_MESSAGES',
        payload: await userApiRequests.getMessages(module)
    });
};

const changeVisibilityOffer = async (dispatch: Dispatch<Action>, module: string, id: string, visibility: boolean): Promise<void> => {
    dispatch({ type: 'SET_LOADING', payload: true });
    await userApiRequests.changeVisibilityOffer(module, id, visibility);
    dispatch({
        type: 'SET_VISIBILITY_OFFER',
        payload: { id, status: visibility }
    });
    dispatch({ type: 'SET_LOADING', payload: false });
};

export const UserActions = {
    logInUser,
    loginByToken,
    logout,
    changeTheme,
    changeErrorVisibility,
    loadUserOffersAsync,
    loadOfferDetailsAsync,
    loadMyOffers,
    loadMyOfferDetailsAsync,
    clearOffers,
    deleteMyOffer,
    reactionUpdate,
    loadNotesAsync,
    updateNote,
    updateProfileOffersNote,
    deleteNote,
    deleteProfileOffersNote,
    getOwnerProfile,
    loadProfileOfferDetailsAsync,
    loadMyOfferToEdit,
    getProfile,
    loadUserMessagesAsync,
    changeVisibilityOffer,
    getNotificationSettings,
    getActivePackageInfo
};
