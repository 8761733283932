import React, { FC, ReactElement, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { PropertyTypes } from 'utils/formatters/getNormalizedPropertyType';
import { dateOptionsLabels } from 'utils/options';
import { UploadRequestData } from 'utils/types/File';
import { InputType, MultipleFilterType, ObjectAndSingleValueChangeType, RangeFilterType } from 'utils/types/InputTypes';
import { LocationOptionsType, SavedLocationsType, SearchLocationResultType } from 'utils/types/Locations';
import { ModulesType } from 'utils/types/ModulesType';

import Button from 'components/atom/Button';
import AccordionCard from 'components/common/AccordionCard';
import FileUploader from 'components/common/FileUpload/FileUploader';
import { IconEnum } from 'components/common/Icon';
import IconButton from 'components/common/IconButton';
import ImageUploader from 'components/common/ImageUpload/ImageUploader';
import InputContainer from 'components/common/InputContainer';
import AutocompleteFiltersInput, { FilterOptions } from 'components/common/Inputs/AutocompleteFiltersInput';
import AutocompleteInput from 'components/common/Inputs/AutocompleteInput/AutocompleteInput';
import DateInput from 'components/common/Inputs/DateInput';
import KeywordsInput, { ButtonWrapper, Keywords } from 'components/common/Inputs/KeywordsInput';
import LocalizationAutocompleteInput from 'components/common/Inputs/LocalizationAutocompleteInput';
import MultiSelectInput from 'components/common/Inputs/MultiSelectInput';
import NotificationsInput from 'components/common/Inputs/NotificationsInput';
import RangeDateInput from 'components/common/Inputs/RangeDateInput';
import RangeSelectInput from 'components/common/Inputs/RangeSelectInput';
import TextInput from 'components/common/Inputs/TextInput';
import StickyBottomChildItem from 'components/common/StickyBottomChildItem';
import { FormRadio } from 'components/InputFilters';

const CostEstimationWrapper = styled.div`
    grid-column-start: 1;
    grid-column-end: 5;
    border-top: 1px solid rgba(3, 13, 69, 0.1);
    padding-top: 20px;
`;

const EstimationElementsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    
    .estimation-input-container {
        flex-direction: row;
        flex-wrap: wrap;
        
        div:first-child {
            display: none;
        }
    }
`;

const Label = styled.span`
    --font-size-body: var(--font-size-body-3);

    display: inherit;
    padding: 10px 20px 10px 20px;
    background: ${(props) => props.summary ? '#EEEEEE' : 'var(--color-alt-second)'};
    border-radius: 20px;
    height: min-content;
    white-space: pre-wrap;

    b {
        font-weight: 500;
        font-size: var(--font-size-body-1);
    }
    
    span {
        --font-weight: 400;
    }
    
    button {
        margin-left: 4px;
    }
`;

interface FilterInputFunctions {
    handleDeleteFilter: (id: number) => void;
    handleSetFiltersValues: (id: number) => void;
}

interface LocationInputFunctions {
    getCities: (voivodeship: string) => void;
    handleRemoveSavedLocation: (location: string) => void;
    inputValidation?: InputValidationType;
    handleChangeSingleValueField?: (name: string, value: string) => void,
}

interface FilterProps {
    filterId: number | null;
    options: FilterOptions[];
}

interface LocationProps {
    savedLocations: SavedLocationsType;
    searchLocationResult: SearchLocationResultType[];
    locations: LocationOptionsType[];
    singleValue?: boolean;
    errorMessage?: string;
    cityName?: string;
    selectAllCheckbox?: boolean;
    required?: boolean;
    triggerValidation?: boolean;
    savedLocationsFieldName: string;
    cityFieldName: string;
}

interface DateRangeProps {
    date: MultipleFilterType;
    dateRangeFilter: boolean;
    fieldName: string;
}

interface FlexibleDateProps {
    daysAmount?: number;
    isSingleDate?: boolean;
}

interface DateProps {
    fieldName: string;
    value: string;
}

interface MultipleSelectProps {
    value: string[];
    optionsIcon: IconEnum;
}

interface RangeProps {
    value: MultipleFilterType;
    options: GenericOptionsValue<string>[];
    secondInputOptions?: GenericOptionsValue<string>[];
    maxInputLength?: number;
    fieldName: string;
    customSpacer?: string;
    placeholderFrom?: string;
    placeholderTo?: string;
    suffix?: string;
}

interface GenericOptionsValue<T> { label: string, value: T }

interface SharedProps<T, R = T> {
    fieldName: string;
    options: GenericOptionsValue<T>[];
    value: R;
}

interface KeywordsProps {
    value: string[];
    icon: IconEnum;
    actionIcon: IconEnum;
    errorMessage: string;
    minLength: number;
    maxLength?: number;
    fieldName: string;
}

interface RadioProps {
    value: boolean;
    fieldName: string;
}

interface AutocompletePropertyOptions {
    label: string;
    value: string;
}

interface AutocompletePropertyProps {
    value: string;
    options: AutocompletePropertyOptions[];
}

interface AutocompleteProps {
    noTyping?: boolean;
}

type HandleChangeType = (name: string, value: ObjectAndSingleValueChangeType) => void;

interface SharedContextFunctions {
    handleChange: HandleChangeType;
}

interface FlexibleDateInputFunctions {
    handleChangeDateList: (fieldName: string, value: number) => void;
}

type InputValidationType = (value?: string | number | null) => boolean;

interface KeywordsInputFunctions {
    inputValidation: InputValidationType;
}

type HandleChangePropertyType = (value: PropertyTypes) => void;

interface AutocompletePropertyInputFunctions {
    handleChangePropertyType: HandleChangePropertyType;
}

interface NotifyProps {
    alertSms: boolean;
    alertEmail: boolean;
    pushAlert: boolean;
    filterId: number | null;
}

interface NotifyFunctions {
    handleChange: (name: string, id: number) => void;
}

interface TextProps {
    value: string;
    fieldName: string;
    important?: boolean;
    actionIcon?: IconEnum;
    isSaveFilterButton?: boolean;
    inputIcon?: IconEnum;
    max?: string;
    maxLength?: number;
    required?: boolean;
    errorMessage?: string;
    textareaMode?: boolean;
    triggerValidation?: boolean;
}

interface TextFunctions {
    handleChange: HandleChangeType;
    inputActionHandler?: () => void;
    inputValidation?: InputValidationType;
}

interface SaveButtonProps {
    actionText: string;
}

interface SaveButtonFunctions {
    onChangeVisibleButtonFilter: (visible: boolean) => void;
    onActionClick: () => void;
    onClick: () => void;
}

type ButtonProps = object

interface ButtonFunctions {
    onClick: () => void;
}

interface NumericProps {
    value: number | string;
    suffix?: string;
    thousandSeparator?: string;
    required?: boolean;
    errorMessage?: string;
    fieldName: string;
}

interface NumericFunctions {
    handleChange: HandleChangeType;
    inputValidation?: InputValidationType;
}

interface ImageUploadProps {
    value: { preview: string, name: string; }[];
    fieldName: string;
}

interface ImageUploadFunctions {
    handleChange: HandleChangeType;
    fileUploadHandler: (params: UploadRequestData) => Promise<AxiosResponse<string>>;
}

interface FileUploadProps {
    value: string[];
    fieldName: string;
}

interface CostEstimationProps {
    costEstimation: { value: number, label: string }[];
}

interface CostEstimationFunctions {
    handleDeleteEstimation: (label: string) => void;
}

interface FiltersRendererProperties {
    title: string;
    type: InputType;
    icon: IconEnum;
    helperText?: string;
    additionalText?: string;
    placeholder: string;
    containerActionText?: string;
    containerActionClick?: () => void;
    inputColumnPosition?: string;
    options: {
        [InputType.AUTOCOMPLETE]?: {
            modulesProps: AutocompleteProps & SharedProps<string>,
            contextFunctions: SharedContextFunctions
        };
        [InputType.AUTOCOMPLETE_PROPERTY]?: {
            modulesProps: AutocompletePropertyProps,
            contextFunctions: AutocompletePropertyInputFunctions & SharedContextFunctions
        };
        [InputType.FILTER_AUTOCOMPLETE]?: { modulesProps: FilterProps, contextFunctions: FilterInputFunctions };
        [InputType.LOCATION]?: {
            modulesProps: LocationProps,
            contextFunctions: LocationInputFunctions & SharedContextFunctions
        };
        [InputType.FLEXIBLE_DATE]?: {
            modulesProps: FlexibleDateProps & DateRangeProps,
            contextFunctions: FlexibleDateInputFunctions & SharedContextFunctions
        };
        [InputType.DATE]?: { modulesProps: DateProps, contextFunctions: SharedContextFunctions };
        [InputType.MULTIPLE_SELECT]?: {
            modulesProps: MultipleSelectProps & SharedProps<string, string[]>,
            contextFunctions: SharedContextFunctions
        };
        [InputType.RANGE_PRICE_SELECT]?: { modulesProps: RangeProps, contextFunctions: SharedContextFunctions };
        [InputType.RANGE_METERS_SELECT]?: { modulesProps: RangeProps, contextFunctions: SharedContextFunctions };
        [InputType.RANGE_NUMBER_SELECT]?: { modulesProps: RangeProps, contextFunctions: SharedContextFunctions };
        [InputType.KEYWORDS_TEXT]?: {
            modulesProps: KeywordsProps,
            contextFunctions: KeywordsInputFunctions & SharedContextFunctions
        };
        [InputType.RADIO]?: { modulesProps: RadioProps, contextFunctions: SharedContextFunctions };
        [InputType.NOTIFICATIONS]?: { modulesProps: NotifyProps, contextFunctions: NotifyFunctions };
        [InputType.TEXT]?: { modulesProps: TextProps, contextFunctions: TextFunctions };
        [InputType.SAVE_BUTTON]?: { modulesProps: SaveButtonProps, contextFunctions: SaveButtonFunctions };
        [InputType.BUTTON]?: { modulesProps: ButtonProps, contextFunctions: ButtonFunctions };
        [InputType.NUMERIC]?: { modulesProps: NumericProps, contextFunctions: NumericFunctions };
        [InputType.IMAGE_UPLOAD]?: { modulesProps: ImageUploadProps, contextFunctions: ImageUploadFunctions };
        [InputType.FILE_UPLOAD]?: { modulesProps: FileUploadProps, contextFunctions: ImageUploadFunctions };
        [InputType.COST_ESTIMATION_SUMMARY]?: {
            modulesProps: CostEstimationProps,
            contextFunctions: CostEstimationFunctions
        };
    };
}

export interface ElementsToRender {
    accordionTitle: string;
    lastElement?: boolean;
    filters: FiltersRendererProperties[];
    isWideSpace?: boolean;
    oneItem?: boolean;
    fullWidth?: boolean;
    additionalText?: string;
    hideTitle?: boolean;
    alignToEnd?: boolean;
}

interface V2FiltersRendererProperties {
    type: ModulesType;
    elementsToRender: ElementsToRender[];
    bottomActions: ReactElement | null;
}

const V2FiltersRenderer: FC<V2FiltersRendererProperties> = ({ type, elementsToRender, bottomActions }) => {
    const renderInputs = (filter: FiltersRendererProperties) => {
        const autocompleteInst = filter.options[InputType.AUTOCOMPLETE];
        const autocompletePropertyInst = filter.options[InputType.AUTOCOMPLETE_PROPERTY];
        const filterInst = filter.options[InputType.FILTER_AUTOCOMPLETE];
        const locationInst = filter.options[InputType.LOCATION];
        const flexibleDateInst = filter.options[InputType.FLEXIBLE_DATE];
        const dateInst = filter.options[InputType.DATE];
        const multipleSelectInst = filter.options[InputType.MULTIPLE_SELECT];
        const rangePriceSelectInst = filter.options[InputType.RANGE_PRICE_SELECT];
        const rangeMetersSelectInst = filter.options[InputType.RANGE_METERS_SELECT];
        const rangeNumberSelectInst = filter.options[InputType.RANGE_NUMBER_SELECT];
        const keywordsInst = filter.options[InputType.KEYWORDS_TEXT];
        const radioInst = filter.options[InputType.RADIO];
        const notifyInst = filter.options[InputType.NOTIFICATIONS];
        const textInst = filter.options[InputType.TEXT];
        const saveButtonInst = filter.options[InputType.SAVE_BUTTON];
        const buttonInst = filter.options[InputType.BUTTON];
        const numericInst = filter.options[InputType.NUMERIC];
        const imageUploadInst = filter.options[InputType.IMAGE_UPLOAD];
        const fileUploadInst = filter.options[InputType.FILE_UPLOAD];
        const costEstimationInst = filter.options[InputType.COST_ESTIMATION_SUMMARY];
        const defaultReturn = <h1>brak danych dla pola {filter.title} - {filter.type}</h1>;

        switch (filter.type) {
        case InputType.NUMERIC:
            if (!numericInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title} icon={filter.icon}
                    required={numericInst.modulesProps.required}>
                    <NumericFormat
                        value={numericInst.modulesProps.value}
                        onChange={(e) => numericInst.contextFunctions.handleChange(numericInst.modulesProps.fieldName, e.target.value)}
                        placeholder={filter.placeholder}
                        suff={numericInst.modulesProps.suffix}
                        thousandSeparator={numericInst.modulesProps.thousandSeparator}
                        customInput={TextInput}
                        required={numericInst.modulesProps.required}
                        inputValidation={numericInst.contextFunctions.inputValidation}
                        errorMessage={numericInst.modulesProps.errorMessage}
                        allowNegative={false}
                    />
                </InputContainer>
            );
        case InputType.AUTOCOMPLETE:
            if (!autocompleteInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title} icon={filter.icon} helperText={filter.helperText}
                    onActionClick={filter.containerActionClick} actionText={filter.containerActionText}>
                    <AutocompleteInput
                        noTyping={autocompleteInst.modulesProps.noTyping}
                        value={autocompleteInst.modulesProps.value}
                        onChange={(value) => autocompleteInst.contextFunctions.handleChange(autocompleteInst.modulesProps.fieldName, value!)}
                        placeholder={filter.placeholder}
                        options={autocompleteInst.modulesProps.options}
                    />
                </InputContainer>
            );
        case InputType.AUTOCOMPLETE_PROPERTY:
            if (!autocompletePropertyInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title} icon={filter.icon}
                    onActionClick={filter.containerActionClick} actionText={filter.containerActionText}>
                    <AutocompleteInput
                        noTyping
                        value={autocompletePropertyInst.modulesProps.value}
                        onChange={(value) => autocompletePropertyInst.contextFunctions.handleChangePropertyType(value as unknown as PropertyTypes)}
                        placeholder={filter.placeholder}
                        options={autocompletePropertyInst.modulesProps.options}
                    />
                </InputContainer>
            );
        case InputType.FILTER_AUTOCOMPLETE:
            if (!filterInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title} icon={filter.icon}
                    onActionClick={filter.containerActionClick} actionText={filter.containerActionText}>
                    <AutocompleteFiltersInput
                        filterId={filterInst.modulesProps.filterId}
                        handleSetFiltersValues={filterInst.contextFunctions.handleSetFiltersValues}
                        handleDeleteFilter={filterInst.contextFunctions.handleDeleteFilter}
                        options={filterInst.modulesProps.options}
                    />
                </InputContainer>
            );
        case InputType.LOCATION:
            if (!locationInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title}
                    helperText={filter.helperText}
                    icon={filter.icon} onActionClick={filter.containerActionClick}
                    actionText={filter.containerActionText} required={locationInst.modulesProps.required}>
                    <LocalizationAutocompleteInput
                        placeholder={filter.placeholder}
                        options={locationInst.modulesProps.locations}
                        searchResultsOptions={locationInst.modulesProps.searchLocationResult}
                        onAction={locationInst.contextFunctions.getCities}
                        handleChange={locationInst.contextFunctions.handleChange}
                        savedLocations={locationInst.modulesProps.savedLocations}
                        singleValue={locationInst.modulesProps.singleValue}
                        inputValidation={locationInst.contextFunctions.inputValidation}
                        errorMessage={locationInst.modulesProps.errorMessage}
                        cityName={locationInst.modulesProps.cityName}
                        handleChangeSingleValueField={locationInst.contextFunctions.handleChangeSingleValueField}
                        selectAllCheckbox={locationInst.modulesProps.selectAllCheckbox}
                        triggerValidation={locationInst.modulesProps.triggerValidation}
                        savedLocationsFieldName={locationInst.modulesProps.savedLocationsFieldName}
                        cityFieldName={locationInst.modulesProps.cityFieldName}

                    />
                    <Keywords>
                        {Object.keys(locationInst.modulesProps.savedLocations).map((x) => <ButtonWrapper key={x}>
                            <p>{x}</p>
                            <IconButton icon={IconEnum.CIRCLE_CROSS}
                                onClick={() => locationInst.contextFunctions.handleRemoveSavedLocation(x)}/>
                        </ButtonWrapper>)}
                    </Keywords>
                </InputContainer>
            );
        case InputType.FLEXIBLE_DATE:
            if (!flexibleDateInst) return defaultReturn;

            return (
                flexibleDateInst.modulesProps.dateRangeFilter
                    ? <RangeDateInput key={filter.title} date={flexibleDateInst.modulesProps.date}
                        dateRangeFilter={flexibleDateInst.modulesProps.dateRangeFilter}
                        isSingleDate={flexibleDateInst.modulesProps.isSingleDate}
                        handleChange={flexibleDateInst.contextFunctions.handleChange}
                        fieldName={flexibleDateInst.modulesProps.fieldName}
                        inputTitle={!flexibleDateInst.modulesProps.isSingleDate ? filter.title : undefined}
                    />
                    : <InputContainer key={filter.title} title={filter.title} icon={filter.icon}
                        helperText={filter.helperText}
                        onActionClick={filter.containerActionClick} actionText={filter.containerActionText}>
                        <AutocompleteInput
                            noTyping
                            value={flexibleDateInst.modulesProps.daysAmount!}
                            onChange={(value) => flexibleDateInst.contextFunctions.handleChangeDateList('daysAmount', value! as number)}
                            placeholder={filter.placeholder}
                            options={dateOptionsLabels}
                        />
                    </InputContainer>
            );
        case InputType.DATE:
            if (!dateInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title} icon={filter.icon}
                    helperText={filter.helperText}>
                    <DateInput
                        date={dateInst.modulesProps.value}
                        handleChangeDate={(value) => dateInst.contextFunctions.handleChange(dateInst.modulesProps.fieldName, value)}
                        placeholder={filter.placeholder}/>
                </InputContainer>
            );
        case InputType.MULTIPLE_SELECT:
            if (!multipleSelectInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title}
                    icon={filter.icon} onActionClick={filter.containerActionClick}
                    actionText={filter.containerActionText} helperText={filter.helperText}>
                    <MultiSelectInput
                        optionsIcon={filter.icon}
                        value={multipleSelectInst.modulesProps.value}
                        onChange={(value) => multipleSelectInst.contextFunctions.handleChange(multipleSelectInst.modulesProps.fieldName, value!)}
                        placeholder={filter.placeholder}
                        options={multipleSelectInst.modulesProps.options}
                    />
                </InputContainer>
            );
        case InputType.RANGE_PRICE_SELECT:
            if (!rangePriceSelectInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title} icon={filter.icon}
                    onActionClick={filter.containerActionClick} actionText={filter.containerActionText}>
                    <RangeSelectInput
                        value={rangePriceSelectInst.modulesProps.value as RangeFilterType}
                        onBlur={(value) => rangePriceSelectInst.contextFunctions.handleChange(rangePriceSelectInst.modulesProps.fieldName, {
                            from: formatFilterPrice(value.from)!,
                            to: formatFilterPrice(value.to)!
                        })}
                        onChange={(value) => rangePriceSelectInst.contextFunctions.handleChange(rangePriceSelectInst.modulesProps.fieldName, value as MultipleFilterType)}
                        placeholderFrom={'Od'}
                        placeholderTo={'Do'}
                        options={rangePriceSelectInst.modulesProps.options}
                        numeric
                        suffix={'zł'}
                        inputType={'money'}
                    />
                </InputContainer>
            );
        case InputType.RANGE_METERS_SELECT:
            if (!rangeMetersSelectInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title} icon={filter.icon}
                    onActionClick={filter.containerActionClick} actionText={filter.containerActionText}>
                    <RangeSelectInput
                        value={rangeMetersSelectInst.modulesProps.value as RangeFilterType}
                        onChange={(value) => rangeMetersSelectInst.contextFunctions.handleChange(rangeMetersSelectInst.modulesProps.fieldName, value as MultipleFilterType)}
                        placeholderFrom={'Od'}
                        placeholderTo={'Do'}
                        options={rangeMetersSelectInst.modulesProps.options}
                        numeric
                        suffix={'m²'}
                        allowComma
                    />
                </InputContainer>
            );
        case InputType.RANGE_NUMBER_SELECT:
            if (!rangeNumberSelectInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title} icon={filter.icon}
                    onActionClick={filter.containerActionClick} actionText={filter.containerActionText}>
                    <RangeSelectInput
                        value={rangeNumberSelectInst.modulesProps.value as RangeFilterType}
                        onChange={(value) => rangeNumberSelectInst.contextFunctions.handleChange(rangeNumberSelectInst.modulesProps.fieldName, value as MultipleFilterType)}
                        placeholderFrom={rangeNumberSelectInst.modulesProps.placeholderFrom || 'Od'}
                        placeholderTo={rangeNumberSelectInst.modulesProps.placeholderTo || 'Do'}
                        options={rangeNumberSelectInst.modulesProps.options}
                        customOptionsSecondInput={rangeNumberSelectInst.modulesProps.secondInputOptions}
                        numeric
                        maxInputLength={rangeNumberSelectInst.modulesProps.maxInputLength}
                        customSpacer={rangeNumberSelectInst.modulesProps.customSpacer}
                        suffix={rangeNumberSelectInst.modulesProps.suffix}
                    />
                </InputContainer>
            );
        case InputType.KEYWORDS_TEXT:
            if (!keywordsInst) return defaultReturn;

            return (
                <KeywordsInput
                    key={filter.title}
                    title={filter.title}
                    helperText={filter.helperText!}
                    icon={filter.icon}
                    placeholder={filter.placeholder}
                    inputIcon={keywordsInst.modulesProps.icon}
                    actionIcon={keywordsInst.modulesProps.actionIcon}
                    inputValidation={keywordsInst.contextFunctions.inputValidation}
                    errorMessage={keywordsInst.modulesProps.errorMessage}
                    handleChange={keywordsInst.contextFunctions.handleChange}
                    value={keywordsInst.modulesProps.value}
                    fieldName={keywordsInst.modulesProps.fieldName}
                    minLength={keywordsInst.modulesProps.minLength}
                    maxLength={keywordsInst.modulesProps.maxLength}
                />
            );
        case InputType.RADIO:
            if (!radioInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title}
                    helperText={filter.helperText}
                    icon={filter.icon} onActionClick={filter.containerActionClick}
                    actionText={filter.containerActionText}>
                    <FormRadio
                        onChange={radioInst.contextFunctions.handleChange}
                        value={radioInst.modulesProps.value}
                        name={radioInst.modulesProps.fieldName}
                    />
                </InputContainer>
            );
        case InputType.NOTIFICATIONS:
            if (!notifyInst) return defaultReturn;

            return (
                <NotificationsInput
                    key={filter.title}
                    notifications={{
                        alertSms: notifyInst.modulesProps.alertSms,
                        alertEmail: notifyInst.modulesProps.alertEmail,
                        pushAlert: notifyInst.modulesProps.pushAlert
                    }}
                    filterId={notifyInst.modulesProps.filterId!}
                    handleChangeNotificationAlert={notifyInst.contextFunctions.handleChange}
                    title={filter.title}
                    icon={filter.icon}
                    helperText={filter.helperText!}
                />
            );
        case InputType.TEXT:
            if (!textInst) return defaultReturn;

            return (
                <InputContainer key={filter.title} title={filter.title} icon={filter.icon}
                    onActionClick={filter.containerActionClick} actionText={filter.containerActionText}
                    additionalText={filter.additionalText} inputColumnPosition={filter.inputColumnPosition}
                    required={textInst.modulesProps.required} helperText={filter.helperText}
                >
                    <TextInput
                        description={textInst.modulesProps.textareaMode}
                        placeholder={filter.placeholder}
                        onChange={({ target: { value } }) => textInst.contextFunctions.handleChange(textInst.modulesProps.fieldName, value)}
                        value={textInst.modulesProps.value}
                        onAction={textInst.contextFunctions.inputActionHandler}
                        icon={textInst.modulesProps.inputIcon}
                        actionIcon={textInst.modulesProps.actionIcon}
                        important={textInst.modulesProps.important}
                        isSaveFilterButton={textInst.modulesProps.isSaveFilterButton}
                        max={textInst.modulesProps.max}
                        maxLength={textInst.modulesProps.maxLength}
                        required={textInst.modulesProps.required}
                        inputValidation={textInst.contextFunctions.inputValidation}
                        errorMessage={textInst.modulesProps.errorMessage}
                        triggerValidation={textInst.modulesProps.triggerValidation}
                    />
                </InputContainer>
            );
        case InputType.SAVE_BUTTON:
            if (!saveButtonInst) return defaultReturn;

            return (
                <StickyBottomChildItem onVisibleChange={saveButtonInst.contextFunctions.onChangeVisibleButtonFilter} key={filter.title}>
                    <InputContainer title={filter.title} icon={filter.icon}
                        actionText={saveButtonInst.modulesProps.actionText}
                        onActionClick={saveButtonInst.contextFunctions.onActionClick}>
                        <Button onClick={saveButtonInst.contextFunctions.onClick}>
                            {filter.placeholder}
                        </Button>
                    </InputContainer>
                </StickyBottomChildItem>
            );
        case InputType.BUTTON:
            if (!buttonInst) return defaultReturn;

            return (
                <InputContainer title={filter.title} icon={filter.icon} inputColumnPosition={filter.inputColumnPosition}>
                    <Button onClick={buttonInst.contextFunctions.onClick}>
                        {filter.placeholder}
                    </Button>
                </InputContainer>
            );
        case InputType.IMAGE_UPLOAD:
            if (!imageUploadInst) return defaultReturn;

            return (
                <ImageUploader value={imageUploadInst.modulesProps.value}
                    onChange={(value) => imageUploadInst.contextFunctions.handleChange(imageUploadInst.modulesProps.fieldName, value)}
                    imageUploadHandler={imageUploadInst.contextFunctions.fileUploadHandler}/>
            );
        case InputType.FILE_UPLOAD:
            if (!fileUploadInst) return defaultReturn;

            return (
                <FileUploader value={fileUploadInst.modulesProps.value}
                    onChange={(value) => fileUploadInst.contextFunctions.handleChange(fileUploadInst.modulesProps.fieldName, value)}
                    fileUploadHandler={fileUploadInst.contextFunctions.fileUploadHandler}/>
            );

        case InputType.COST_ESTIMATION_SUMMARY: {
            if (!costEstimationInst) return defaultReturn;

            const countEstimation = useMemo(() => {
                return costEstimationInst.modulesProps.costEstimation.reduce((acc, curr) => acc + Number(curr.value), 0);
            }, [costEstimationInst.modulesProps.costEstimation]);

            return (
                <CostEstimationWrapper>
                    <EstimationElementsWrapper>
                        <InputContainer title={filter.title} icon={filter.icon} className="estimation-input-container">
                            {costEstimationInst.modulesProps.costEstimation.map((cost) => <Label key={cost.label}><b>{`${cost.label} `}</b><span> | {cost.value} PLN</span>
                                <IconButton
                                    onClick={() => costEstimationInst.contextFunctions.handleDeleteEstimation(cost.label)}
                                    icon={IconEnum.CIRCLE_CROSS}/>
                            </Label>)}
                            <Label summary><b>Łączna kwota to</b><span>{` ${countEstimation}`} PLN</span></Label>
                        </InputContainer>
                    </EstimationElementsWrapper>
                </CostEstimationWrapper>
            );
        }
        default:
            return null;
        }
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            {elementsToRender.map((element) => {
                return (
                    <AccordionCard key={element.accordionTitle} title={element.accordionTitle} type={type} lastElement={element.lastElement}
                        moveLast={element.isWideSpace} oneItem={element.oneItem}
                        fullWidth={element.fullWidth} additionalText={element.additionalText} hideTitle={element.hideTitle} alignToEnd={element.alignToEnd}>
                        {element.filters.map((filter) => renderInputs(filter))}
                    </AccordionCard>
                );
            })}
            {bottomActions}
        </form>
    );
};

export default V2FiltersRenderer;
