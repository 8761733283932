import React, { useContext } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import TenderContext from 'utils/context/TenderContext';
import { tenderSortOptions } from 'utils/options';
import { getTenderValueFromState } from 'utils/state-managment/tender/tenderFilter';
import { TenderSearchingRequestSortTypeEnum } from 'utils/types/TenderModels';

import Indicator from 'components/atom/Indicator';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import FiltersTender from 'components/tender/FiltersTender';
import TenderOffers from 'components/tender/TenderOffers';
import V2HeaderSection from 'components/V2HeaderSection';

const Container = styled.div`
    padding: 15px 20px 0;
`;

const PageContainer = styled.div`
    height: 100%;
`;

const TenderPage = () => {
    const { tenderOfferState, handleChange, loadTenderOffers, tenderFiltersState, clearOffers } = useContext(TenderContext);

    const handleChangeSort = (sortType: string) => {
        handleChange('sortType', sortType);
        loadTenderOffers({
            requestData: {
                ...getTenderValueFromState(tenderFiltersState),
                sortType: sortType as TenderSearchingRequestSortTypeEnum
            },
            page: 1,
            rows: 25
        });
    };

    // const handleCSVFile = useCallback(() => {
    //     tenderOffersApiRequests.getTenderOffersCSVFile(getTenderValueFromState(tenderFiltersState), tenderFiltersState.module);
    // }, [tenderFiltersState]);

    return (
        <PageContainer>
            <V2HeaderSection
                title={'Przetargi'}
                offersCount={tenderOfferState.tenderOfferList?.totalElements}
                sortType={tenderOfferState.tenderOfferList.content?.length ? tenderOfferState.sortType : undefined}
                sortOptions={tenderSortOptions}
                handleChangeSort={handleChangeSort}
                handleBackToFilters={tenderOfferState.tenderOfferList.content?.length || tenderOfferState.tenderOfferList.empty ? clearOffers : undefined}
            />
            <FiltersContentWrapper className={clsx('offers-list', { active: tenderOfferState.tenderOfferList?.content && tenderOfferState.tenderOfferList?.content.length > 0 || tenderOfferState.tenderOfferList?.empty })}>
                <Container id="filters">
                    <FiltersTender/>
                </Container>
                <TenderOffers/>
                {tenderOfferState.isLoading && <Indicator/>}
            </FiltersContentWrapper>
        </PageContainer>
    );
};

export default TenderPage;
