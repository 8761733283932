import React from 'react';
import { TenderOfferResponseContent } from 'utils/state-managment/tender/tenderOffer';

import { InfoElementType } from 'components/common/Card/common/V2CardBodyDefault/InfoElements';
import { IconEnum } from 'components/common/Icon';
import { V2DetailsTabsProps } from 'components/common/Tabs/tabs/V2DetailsTab';

type Offer = Omit<TenderOfferResponseContent, 'detailedContent' | 'encryptedId'> & {
    detailedContent?: {
        description?: string;
        meta?: {
            value?: string;
            key: string;
        }[]
    },
};

const generateOfferDetailsTabContent = (offer: Offer) => {
    const detailsElements = [
        {
            icon: IconEnum.CALENDAR,
            text: 'Data dodania:',
            value: offer.dateAdded
        },
        ...offer.type
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Typ nieruchomości:',
                    value: offer.type
                }
            ]
            : [],
        ...offer.price
            ? [
                {
                    icon: IconEnum.WALLET,
                    text: 'Cena:',
                    value: `${offer.price} zł`
                }
            ]
            : [],
        ...offer.pricePerSquareMeter
            ? [
                {
                    icon: IconEnum.WALLET,
                    text: 'Cena za m<sup>2</sup>:',
                    value: `${offer.pricePerSquareMeter} zł`
                }
            ]
            : [],
        ...offer.tenderDate
            ? [
                {
                    icon: IconEnum.CALENDAR,
                    text: 'Data przetargu:',
                    value: offer.tenderDate
                }
            ]
            : [],
        ...offer.area
            ? [
                {
                    icon: IconEnum.SQUARE,
                    text: 'Metraż:',
                    value: `${offer.area} m2`
                }
            ]
            : [],
        ...offer.portal
            ? [
                {
                    icon: IconEnum.LINK,
                    text: 'Portal:',
                    value: offer.portal
                }
            ]
            : []
    ];

    const offerDetailsTabContent: V2DetailsTabsProps = {
        title: offer.title,
        address: offer?.location,
        detailsElements,
        description: offer.detailedContent?.description || '-',
        meta: offer.detailedContent?.meta
    };

    return offerDetailsTabContent;
};

const generateInfoElements = (offer: TenderOfferResponseContent): InfoElementType[] => {
    return [

        {
            icon: IconEnum.BUILDING,
            isVisible: !!offer.type,
            children: <b>{offer.type}</b>
        },
        ...offer.area
            ? [
                {
                    icon: IconEnum.SQUARE,
                    isVisible: !!offer.area,
                    children: <><b>{offer.area}</b> m<sup>2</sup></>
                }
            ]
            : []
    ];
};

export const tenderOfferListFunctions = {
    generateOfferDetailsTabContent,
    generateInfoElements
};
