import React, { FC, useContext, useEffect, useState } from 'react';
import { auctionOffersApiRequests } from 'utils/api-requests/auction';
import AuctionContext from 'utils/context/AuctionContext';
import { createUniqueFilterName } from 'utils/createUniqueFilterName';
import { generateOptionsFromEnum } from 'utils/generateOptionsFromEnum';
import { areaOptions, buildYearOptions, priceOptions, pricePerSquareMeterSaleOptions, roomOptions, scheduleOptions, userFloorOptions } from 'utils/options';
import { getAuctionValueFromState } from 'utils/state-managment/auction/auctionFilter';
import { AuctionFiltersStateType } from 'utils/types/Auction';
import { AuctionFilterResponseTypesEnum } from 'utils/types/AuctionModels';
import { InputType, LocationChangeType } from 'utils/types/InputTypes';

import Button from 'components/atom/Button';
import StickyBottom from 'components/common/FiltersRenderer/StickyBottom';
import V2FiltersRenderer from 'components/common/FiltersRenderer/V2FiltersRenderer';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import { activeNotification } from 'components/functions/activeNotification';
import { getVoivodeshipCities } from 'components/functions/locations';

type FiltersAuctionProperties = object;

const FiltersAuction: FC<FiltersAuctionProperties> = () => {
    const {
        auctionFiltersState,
        auctionOfferState,
        handleChange,
        handleDeleteFilter,
        handleSetFiltersValues,
        handleChangeDateList,
        getSavedFilters,
        clearFilters,
        loadAuctionOffers
    } = useContext(AuctionContext);

    const [isFilterButtonVisible, setIsFilterButtonVisible] = useState(false);

    const getCities = async (voivodeship: string) => {
        const newLocations = await getVoivodeshipCities(voivodeship, auctionFiltersState.locations);
        handleChange('locations', newLocations || []);
    };

    const handleRemoveSavedLocation = (location: string) => {
        const savedLocations = auctionFiltersState.savedLocations;
        delete savedLocations[location];

        handleChange('savedLocations', savedLocations);
        handleChange('city', Object.values(savedLocations as LocationChangeType));
    };

    const locationCompleted =
       auctionFiltersState.city.length > 0 &&
       !(auctionFiltersState.city.length === 0);

    const handleSubmit = () => {
        if (!locationCompleted) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');
        } else {
            loadAuctionOffers();
            document.body.scrollTo(0, 0);
        }
    };

    const handleSaveOrUpdateFilter = async () => {
        if (!locationCompleted) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        const existFilter = auctionFiltersState.savedFilter?.find(
            (filter) => filter.filterId === auctionFiltersState.filterId
        );

        const existName = auctionFiltersState.savedFilter?.find(
            (filter) => filter.name === auctionFiltersState.name
        );

        let id;

        if (existName && !auctionFiltersState.filterId) {
            activeNotification('Nazwa filtra', 'Istnieje już filtr o takiej nazwie', 'warning');

            return;
        }

        if (existFilter) {
            await auctionOffersApiRequests.updateFilter({
                ...getAuctionValueFromState(auctionFiltersState)
            });
        } else {
            const resp = await auctionOffersApiRequests.addFilter({
                ...getAuctionValueFromState(auctionFiltersState)
            });

            id = resp?.data;
        }

        getSavedFilters(id);
    };

    const handleCreateFromExistFilter = async () => {
        if (auctionFiltersState.city.length <= 0) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        const newName = createUniqueFilterName(auctionFiltersState.name!, auctionFiltersState.savedFilter);

        const newFilterData = {
            ...getAuctionValueFromState(auctionFiltersState),
            name: newName
        };

        const resp = await auctionOffersApiRequests.addFilter(newFilterData);

        const id = resp?.data;

        getSavedFilters(id);
    };

    useEffect(() => {
        getSavedFilters();
    }, []);

    const handleChangeNotificationAlert = async (valueName: string, id: number) => {
        const fieldName = valueName === 'pushAlert' ? 'alertPush' : valueName;
        const updatedState = {
            ...auctionFiltersState,
            [fieldName]: !auctionFiltersState[fieldName as keyof AuctionFiltersStateType]
        };

        await auctionOffersApiRequests.updateFilter(getAuctionValueFromState(updatedState));
        getSavedFilters(id);
    };

    const shouldShowFiltersForBuildings = auctionFiltersState.types.some((type) => type === AuctionFilterResponseTypesEnum.Mieszkania ||
        type === AuctionFilterResponseTypesEnum.Domy ||
        type === AuctionFilterResponseTypesEnum.Pozostale) || auctionFiltersState.types.length === 0;

    const auctionFiltersStructure = [
        {
            accordionTitle: 'Podstawowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Zapisane filtry',
                    type: InputType.FILTER_AUTOCOMPLETE,
                    icon: IconEnum.BOOKMARK,
                    placeholder: 'Wybierz filtr...',
                    options: {
                        [InputType.FILTER_AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleDeleteFilter,
                                handleSetFiltersValues
                            },
                            modulesProps: {
                                filterId: auctionFiltersState.filterId ?? null,
                                options: auctionFiltersState.savedFilter.map((x) => ({
                                    ...x,
                                    pushAlert: x.alertPush,
                                    id: x.filterId
                                }
                                ))
                            }
                        }
                    }
                },
                {
                    title: 'Lokalizacja',
                    type: InputType.LOCATION,
                    helperText: 'Wybierz z listy interesującą Ciebie lokalizację. Możesz zaznaczyć dowolną ilość.',
                    icon: IconEnum.MAP_PIN,
                    placeholder: 'Szukaj lokalizacji...',
                    options: {
                        [InputType.LOCATION]: {
                            modulesProps: {
                                locations: auctionFiltersState.locations,
                                searchLocationResult: auctionFiltersState.searchLocationResult,
                                savedLocations: auctionFiltersState.savedLocations,
                                savedLocationsFieldName: 'savedLocations',
                                cityFieldName: 'city'
                            },
                            contextFunctions: {
                                getCities,
                                handleRemoveSavedLocation,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Data',
                    type: InputType.FLEXIBLE_DATE,
                    helperText: 'Wybierz, z jakiego okresu chcesz wyświetlić oferty. Wybierz opcję "Przedział dat" aby precyzyjnie wskazać datę. Ten parametr nie wpływa na natychmiastowe wysyłanie alertów.',
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Data dodania oferty...',
                    containerActionText: 'Przedział dat',
                    containerActionClick: () => handleChange('dateRangeFilter', !auctionFiltersState.dateRangeFilter),
                    options: {
                        [InputType.FLEXIBLE_DATE]: {
                            modulesProps: {
                                date: auctionFiltersState.offerAdded,
                                dateRangeFilter: auctionFiltersState.dateRangeFilter,
                                daysAmount: auctionFiltersState.daysAmount,
                                fieldName: 'offerAdded'
                            },
                            contextFunctions: {
                                handleChange,
                                handleChangeDateList
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Parametry',
            hideTitle: true,
            filters: [
                {
                    title: 'Typ nieruchomości',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz rodzaj...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: auctionFiltersState.types,
                                options: generateOptionsFromEnum(AuctionFilterResponseTypesEnum),
                                optionsIcon: IconEnum.TWO_TYPE,
                                fieldName: 'types'
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Cena',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: auctionFiltersState.price,
                                fieldName: 'price',
                                options: priceOptions
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Cena za metr',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE_PART,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: auctionFiltersState.pricePerSquareMeter,
                                fieldName: 'pricePerSquareMeter',
                                options: pricePerSquareMeterSaleOptions
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Metraż',
                    type: InputType.RANGE_METERS_SELECT,
                    icon: IconEnum.SQUARE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_METERS_SELECT]: {
                            modulesProps: {
                                value: auctionFiltersState.area,
                                fieldName: 'area',
                                options: areaOptions
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                ...shouldShowFiltersForBuildings
                    ? [
                        {
                            title: 'Rok budowy',
                            type: InputType.RANGE_NUMBER_SELECT,
                            icon: IconEnum.CALENDAR,
                            placeholder: '',
                            options: {
                                [InputType.RANGE_NUMBER_SELECT]: {
                                    modulesProps: {
                                        value: auctionFiltersState.builtYear,
                                        fieldName: 'builtYear',
                                        options: buildYearOptions,
                                        maxInputLength: 4
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...shouldShowFiltersForBuildings
                    ? [
                        {
                            title: 'Piętro',
                            type: InputType.RANGE_NUMBER_SELECT,
                            icon: IconEnum.FLOOR_LEVEL,
                            placeholder: '',
                            options: {
                                [InputType.RANGE_NUMBER_SELECT]: {
                                    modulesProps: {
                                        value: auctionFiltersState.floor,
                                        fieldName: 'floor',
                                        options: userFloorOptions,
                                        maxInputLength: 3
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...shouldShowFiltersForBuildings
                    ? [
                        {
                            title: 'Ilość pięter',
                            type: InputType.RANGE_NUMBER_SELECT,
                            icon: IconEnum.FLOOR_LEVEL,
                            placeholder: '',
                            options: {
                                [InputType.RANGE_NUMBER_SELECT]: {
                                    modulesProps: {
                                        value: auctionFiltersState.floors,
                                        fieldName: 'floors',
                                        options: userFloorOptions,
                                        maxInputLength: 3
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                ...shouldShowFiltersForBuildings
                    ? [
                        {
                            title: 'Pokoje',
                            type: InputType.RANGE_NUMBER_SELECT,
                            icon: 'bed' as IconEnum,
                            placeholder: '',
                            options: {
                                [InputType.RANGE_NUMBER_SELECT]: {
                                    modulesProps: {
                                        value: auctionFiltersState.numberOfRooms,
                                        fieldName: 'numberOfRooms',
                                        options: roomOptions,
                                        maxInputLength: 3
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                    : [],
                {
                    title: 'Słowa pożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: auctionFiltersState.wantedKeywords.filter(Boolean) as string[],
                                fieldName: 'wantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Słowa niepożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które nie mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: auctionFiltersState.unwantedKeywords.filter(Boolean) as string[],
                                fieldName: 'unwantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Ustawienia',
            hideTitle: true,
            filters: [
                {
                    title: 'Zerowe lub puste wartości',
                    type: InputType.RADIO,
                    helperText: 'Wybierz czy mają pojawiać się ogłoszenia, które nie spełniają parametrów filtra z powodu braku danych. Więcej w zakładce Pomoc',
                    icon: IconEnum.OPTIONS,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: auctionFiltersState.includeZeroArea,
                                fieldName: 'includeZeroArea'
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Dodatkowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Harmonogram',
                    type: InputType.AUTOCOMPLETE,
                    helperText: 'Co jaki okres chcesz otrzymywać powiadomienia? Jeżeli wybierzesz "Natychmiast" to każda oferta zostanie wysłana w osobnym mailu. w pozostałych przypadkach otrzymasz jednego maila np. co 5 min z listą ogłoszeń, które pojawiły się w tym czasie.',
                    icon: IconEnum.TIMER,
                    placeholder: 'Wybierz godziny...',
                    options: {
                        [InputType.AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleChange
                            },
                            modulesProps: {
                                value: auctionFiltersState.notificationsDelay,
                                fieldName: 'notificationsDelay',
                                options: scheduleOptions
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Podsumowanie',
            hideTitle: true,
            isWideSpace: true,
            lastElement: true,
            filters: [
                {
                    title: 'Zapisz nowy filtr',
                    type: InputType.TEXT,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz nazwę...',
                    containerActionText: auctionFiltersState.filterId ? 'Duplikuj' : undefined,
                    containerActionClick: handleCreateFromExistFilter,
                    options: {
                        [InputType.TEXT]: {
                            modulesProps: {
                                value: auctionFiltersState.name || '',
                                fieldName: 'name',
                                inputIcon: IconEnum.BOOKMARK_WHITE,
                                actionIcon: IconEnum.ARROW_RIGHT,
                                important: true,
                                isSaveFilterButton: true
                            },
                            contextFunctions: {
                                handleChange,
                                inputActionHandler: handleSaveOrUpdateFilter
                            }
                        }
                    }
                },
                {
                    title: 'Aktywacja powiadomień',
                    type: InputType.NOTIFICATIONS,
                    helperText: 'Przed aktywacją powiadomienia zapisz filtr',
                    icon: IconEnum.ALERT,
                    placeholder: '',
                    options: {
                        [InputType.NOTIFICATIONS]: {
                            contextFunctions: {
                                handleChange: handleChangeNotificationAlert
                            },
                            modulesProps: {
                                alertEmail: auctionFiltersState.alertEmail,
                                alertSms: auctionFiltersState.alertSms,
                                pushAlert: auctionFiltersState.alertPush,
                                filterId: auctionFiltersState.filterId ?? null
                            }
                        }
                    }
                },
                {
                    title: 'Zastosuj filtry',
                    type: InputType.SAVE_BUTTON,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Przeglądaj oferty...',
                    options: {
                        [InputType.SAVE_BUTTON]: {
                            contextFunctions: {
                                onChangeVisibleButtonFilter: setIsFilterButtonVisible,
                                onActionClick: clearFilters,
                                onClick: handleSubmit
                            },
                            modulesProps: {
                                actionText: 'Resetuj'
                            }
                        }
                    }
                }
            ]
        }
    ];

    return (
        <V2FiltersRenderer elementsToRender={auctionFiltersStructure} type={'auction'} bottomActions={
            <StickyBottom
                isVisible={isFilterButtonVisible}
                active={auctionOfferState.auctionOfferList?.content?.length > 0 || auctionOfferState.auctionOfferList.empty}
                className={'sticky-filter-button'}>
                <InputContainer title={'Zastosuj filtry'} icon={IconEnum.DICTIONARY}
                    actionText={'Resetuj'}
                    onActionClick={clearFilters}>
                    <Button onClick={handleSubmit}>
                        {'Przeglądaj oferty...'}
                    </Button>
                </InputContainer>
            </StickyBottom>
        }/>
    );
};

export default FiltersAuction;

