import { FiltersStateType } from 'utils/types/FiltersStateType';
import { ModulesType } from 'utils/types/ModulesType';
import { NoteResponse,
    TenderDetailsResponse,
    TenderResponse,
    TenderSearchingRequestSortTypeEnum } from 'utils/types/TenderModels';

export interface TenderOfferStateType {
  isLoading: boolean,
  tenderOfferList: TenderOffersResponse,
  sortType: TenderSearchingRequestSortTypeEnum;
  filtersState: FiltersStateType;
  pageType: ModulesType;
  filteredCity: string;
  notes?: NoteResponse[],
}

export type TenderOfferDetailsResponse = TenderDetailsResponse;

export interface TenderOfferResponseContent extends TenderResponse {
  detailedContent?: TenderOfferDetailsResponse;
}

export interface TenderOffersResponse {
  empty: boolean,
  content: TenderOfferResponseContent[],
  pageable: {
      pageNumber: number,
      pageSize: number,
  },
  numberOfElements: number,
  totalPages: number,
  totalElements: number,
  last: boolean,
  size: number,
  afterSearch: boolean,
}

export const tenderInitialState: TenderOfferStateType = {
    isLoading: false,
    tenderOfferList: {
        empty: false,
        content: [],
        pageable: {
            pageNumber: 1,
            pageSize: 0
        },
        numberOfElements: 0,
        size: 25,
        totalPages: 0,
        totalElements: 0,
        last: false,
        afterSearch: false
    },
    sortType: TenderSearchingRequestSortTypeEnum.DATE_DESC,
    filtersState: {} as FiltersStateType,
    pageType: 'tender',
    filteredCity: ''
};
