import React, { FC } from 'react';
import styled from 'styled-components';
import { useWindowWidth } from 'utils/useWindowWidth';

import InfoElements, { InfoElementType } from 'components/common/Card/common/V2CardBodyDefault/InfoElements';
import ModificationsContainer, { ModificationsProps } from 'components/common/Card/common/V2CardBodyDefault/ModificationsContainer/ModificationsContainer';
import OrganizationContainer, { OrganizationProps } from 'components/common/Card/common/V2CardBodyDefault/OrganizationContainer';
import RatingContainer, { RatingProps } from 'components/common/Card/common/V2CardBodyDefault/RatingContainer';
import V2InteractiveIconsWrapper, {
    InteractiveIconsWrapperProperties
} from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';

const IconsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
`;

const OrganizationAndRatingActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    @media (max-width: 586px) {
        flex-direction: column;
    }
`;

const Wrapper = styled.div`
    grid-area: bottom;
`;

const PaddingContainer = styled.div`
    margin: 10px 0;
`;

export interface OrganizationAndRatingProps {
    organizationContainer: OrganizationProps;
    ratingContainer?: RatingProps;
}

export type BottomProps = {
    infoElements?: InfoElementType[];
    modifications?: ModificationsProps;
    organizationAndRating?: OrganizationAndRatingProps;
} & InteractiveIconsWrapperProperties;

const Bottom: FC<BottomProps> = ({
    infoElements,
    modifications,
    userDetails,
    isNotInteractive,
    notInteractiveElements,
    interactiveElements,
    showMessages,
    setShowMessages,
    openOfferTab,
    organizationAndRating
}) => {
    const width = useWindowWidth();

    return (
        <Wrapper>
            {organizationAndRating
                ? <OrganizationAndRatingActionsWrapper>
                    {organizationAndRating.ratingContainer
                        ? <RatingContainer ratingContainer={organizationAndRating.ratingContainer} openOfferTab={openOfferTab} />
                        : null}
                    <OrganizationContainer organizationContainer={organizationAndRating.organizationContainer}/>
                </OrganizationAndRatingActionsWrapper>
                : null}
            <IconsWrapper>
                {width <= 490 && infoElements && infoElements.length > 0 &&
                    <InfoElements infoElements={infoElements}/>
                }
                {width < 991 && modifications && <ModificationsContainer modifications={modifications}/>}
                {width < 991 && !modifications && <PaddingContainer/>}
                <V2InteractiveIconsWrapper
                    userDetails={userDetails}
                    isNotInteractive={isNotInteractive}
                    notInteractiveElements={notInteractiveElements}
                    interactiveElements={interactiveElements}
                    showMessages={showMessages}
                    setShowMessages={setShowMessages}
                    openOfferTab={openOfferTab}
                />
            </IconsWrapper>
        </Wrapper>
    );
};

export default Bottom;
