import React, { ReactNode } from 'react';
import dayjs from 'dayjs';
import { AuctionOfferResponseContent } from 'utils/state-managment/auction/auctionOffer';

import { InfoElementType } from 'components/common/Card/common/V2CardBodyDefault/InfoElements';
import { IconEnum } from 'components/common/Icon';
import { V2DetailsTabsProps } from 'components/common/Tabs/tabs/V2DetailsTab';

type Offer = Omit<AuctionOfferResponseContent, 'detailedContent' | 'encryptedId'> & {
    detailedContent?: {
        description?: string;
        meta?: {
            value?: string;
            key: string;
        }[],
        ownerType?: string;
    }
};

const generateOfferDetailsTabContent = (offer: Offer) => {
    const detailsElements = [
        {
            icon: IconEnum.CALENDAR,
            text: 'Data dodania:',
            value: offer.dateAdded
        },
        ...offer.type
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Typ nieruchomości:',
                    value: offer.type
                }
            ]
            : [],
        ...offer.price
            ? [
                {
                    icon: IconEnum.WALLET,
                    text: 'Cena:',
                    value: `${offer.price} zł`
                }
            ]
            : [],
        ...offer.pricePerSquareMeter
            ? [
                {
                    icon: IconEnum.WALLET,
                    text: 'Cena za m<sup>2</sup>:',
                    value: `${offer.pricePerSquareMeter} zł`
                }
            ]
            : [],
        ...offer.auctionDate
            ? [
                {
                    icon: IconEnum.CALENDAR,
                    text: 'Data licytacji:',
                    value: offer.auctionDate
                }
            ]
            : [],
        ...offer.area
            ? [
                {
                    icon: IconEnum.SQUARE,
                    text: 'Metraż:',
                    value: `${offer.area} m2`
                }
            ]
            : [],
        ...offer.numberOfRooms
            ? [
                {
                    icon: IconEnum.BED,
                    text: 'Liczba pokoi:',
                    value: `${offer.numberOfRooms} pokoje`
                }
            ]
            : [],
        ...offer.builtYear
            ? [
                {
                    icon: IconEnum.CALENDAR,
                    text: 'Rok budowy:',
                    value: `${offer.builtYear}`
                }
            ]
            : [],
        ...offer.floor
            ? [
                {
                    icon: IconEnum.FLOOR_LEVEL,
                    text: 'Piętro:',
                    value: `${offer.floor}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.ownerType
            ? [
                {
                    icon: IconEnum.PEOPLE,
                    text: 'Forma własności:',
                    value: `${offer.detailedContent.ownerType}`
                }
            ]
            : [],
        ...offer.portal
            ? [
                {
                    icon: IconEnum.LINK,
                    text: 'Portal:',
                    value: offer.portal
                }
            ]
            : []
    ];

    const offerDetailsTabContent: V2DetailsTabsProps = {
        title: offer.title,
        address: offer?.location,
        detailsElements,
        description: offer.detailedContent?.description || '-',
        meta: offer.detailedContent?.meta
    };

    return offerDetailsTabContent;
};

const generateInfoElements = (offer: AuctionOfferResponseContent): InfoElementType[] => {
    return [

        {
            icon: IconEnum.BUILDING,
            isVisible: !!offer.type,
            children: <b>{offer.type}</b>
        },
        ...offer.area
            ? [
                {
                    icon: IconEnum.SQUARE,
                    isVisible: !!offer.area,
                    children: <><b>{offer.area}</b> m<sup>2</sup></>
                }
            ]
            : [],
        ...offer.builtYear
            ? [
                {
                    icon: IconEnum.CALENDAR,
                    isVisible: !!offer.builtYear,
                    children: <><b>{offer.builtYear}</b></>
                }
            ]
            : [],
        ...offer.numberOfRooms
            ? [
                {
                    icon: IconEnum.BED,
                    isVisible: !!offer.numberOfRooms,
                    children: <><b>{offer.numberOfRooms}</b> pokoje</>
                }
            ]
            : [],
        ...offer.numberOfRooms
            ? [
                {
                    icon: IconEnum.FLOOR_LEVEL,
                    isVisible: !!offer.floor,
                    children: <><b>{offer.floor}</b> piętro</>
                }
            ]
            : []

    ];
};

interface AdditionalOfferActionElementType {
    children: ReactNode;
    onClick: (e: MouseEvent) => void;
    isBlocked?: boolean;
}

const generateAdditionalOfferActionElements = (offer: AuctionOfferResponseContent, handleUpdateReminder: (id: number, type: string) => void): AdditionalOfferActionElementType[] => {
    // auctionDate comes in format dd.mm.yyyy and must be converted to yyyy-mm-dd in order to compare with current date using dayjs
    const isAuctionDate = !!offer.auctionDate && dayjs(offer.auctionDate.split('.').reverse().join('-')).isAfter(dayjs(), 'day');

    const formatDateForGoogleCalendar = (dateString: string) => {
        const [
            day,
            month,
            year
        ] = dateString.split('.');

        return `${year}${month}${day}`;
    };
    const calendar = {
        title: offer.title,
        date: offer.auctionDate ? formatDateForGoogleCalendar(offer.auctionDate) : '',
        details: `Link do licytacji: <a href="${offer.link}">otwórz lin</a>`,
        location: offer.location
    };

    const calendarLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${calendar.title}&dates=${calendar.date}T050000Z/${calendar.date}T060000Z&details=${calendar.details}&location=${calendar.location}`;

    return [
        {
            children:
                <>
                    <p>Dodaj do kalendarza google</p>
                </>,
            onClick: async (e: MouseEvent) => {
                e.stopPropagation();
                if (isAuctionDate) window.open(calendarLink, '_blank');

                return;
            },
            isBlocked: !isAuctionDate
        },
        {
            children:
                <>
                    <p>{offer.reminds.smsRemindStatus ? 'Anuluj przypomnienie sms' : 'Przypomnienie sms'}</p>
                </>,
            onClick: async (e: MouseEvent) => {
                e.stopPropagation();
                handleUpdateReminder(offer.auctionId, 'sms');
            },
            isBlocked: !isAuctionDate
        },
        {
            children: <p>{offer.reminds.emailRemindStatus ? 'Anuluj przypomnienie email' : 'Przypomnienie email'}</p>,
            onClick: (e: MouseEvent) => {
                e.stopPropagation();
                handleUpdateReminder(offer.auctionId, 'email');
            },
            isBlocked: !isAuctionDate
        }
    ];
};

export const auctionOfferListFunctions = {
    generateOfferDetailsTabContent,
    generateInfoElements,
    generateAdditionalOfferActionElements
};
