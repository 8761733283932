
import { OfferData } from 'utils/types/OfferData';

import {
    compareAuctionDateAscending, compareAuctionDateDescending,
    compareByModifyDate,
    compareDateAscending,
    compareDateDescending,
    comparePriceAscending,
    comparePriceDescending,
    comparePricePerSquareMeterAscending,
    comparePricePerSquareMeterDescending,
    compareRentPriceAscending, compareRentPriceDescending
} from 'components/Sort';

import { State } from './State';

export interface Action {
    type: string,
    payload?: any // eslint-disable-line
}

// todo relocate below function to separate file - helper, utils... etc
const filterListByPageType = (list: OfferData[], pageType: string): OfferData[] => {
    if (pageType === 'auction') {
        return list.filter((el) => el.portal.link.includes('licytacje.komornik.pl'));
    } else if (pageType === 'tender') {
        return list.filter((el) => !el.portal.link.includes('licytacje.komornik.pl'));
    } else {
        return list;
    }
};

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
    case 'SET_LOADING':
        return {
            ...state,
            isLoading: action.payload === undefined
        };
    case 'CLEAR':
        return {
            ...state,
            isLoading: false,
            list: [],
            rentList: {
                empty: true,
                content: [],
                pageable: {
                    pageNumber: 1,
                    pageSize: 0
                },
                numberOfElements: 0,
                totalPages: 0,
                totalElements: 0,
                size: 25,
                last: false,
                afterSearch: false
            }
        };
    case 'SET_FILTERS':
        return {
            ...state,
            list: [...action.payload!],
            filtersState: action.payload![0].filtersState ?? {},
            pageType: action.payload![0].pageType!,
            filteredCity: action.payload![0].filteredCity!,
            isLoading: false
        };
    case 'SET_RENT_OFFERS':
        return {
            ...state,
            rentList: action.payload,
            isLoading: false
        };
    case 'SET_DETAILED_RENT_OFFER':
        return {
            ...state,
            rentList: {
                ...state.rentList,
                content: state.rentList.content.map((el) => {
                    if (el.advertisementId || el.rentId === action.payload.advertisementId) {
                        return {
                            ...el,
                            detailedContent: action.payload
                        };
                    }

                    return el;
                })
            },
            isLoading: false,
            notes: action.payload.note
        };
    case 'SET_DUPLICATES':
        return {
            ...state,
            rentList: {
                ...state.rentList,
                content: state.rentList.content.map((el) => {
                    if (el.advertisementId === action.payload.id) {
                        return {
                            ...el,
                            duplicates: action.payload.data
                        };
                    }

                    return el;
                })
            },
            isLoading: false
        };
    case 'SET_OLD_NOTES':
        return {
            ...state,
            oldNotes: [
                ...getNewNote(state.list, action),
                ...action.payload?.data ?? []
            ],
            isLoading: false
        };
    case 'SET_SORT':
        return {
            ...state,
            sortType: action.payload,
            list: [...filterListByPageType(state.list.sort(getSortByType(action.payload)), state.pageType)],
            isTendeOrAuctionListEmpty: filterListByPageType(state.list.sort(getSortByType(action.payload)), state.pageType).length <= 0
        };

    case 'RESET_IS_TENDER_OR_AUCTION_LIST_EMPTY':
        return {
            ...state,
            isTendeOrAuctionListEmpty: false
        };

    case 'V2_SET_NOTES':
        return {
            ...state,
            notes: action.payload.data
        };
    case 'UPDATE_OPINIONS':
        return {
            ...state,
            rentList: {
                ...state.rentList,
                content: state.rentList.content.map((offer) => {
                    if (offer.advertisementId === action.payload.advertisementId) {
                        return {
                            ...offer,
                            ...action.payload.response
                        };
                    }

                    return offer;
                })
            },
            isLoading: false
        };
    case 'NEW_OPINION':
        return {
            ...state,
            scoreAndOpinions: action.payload.newOpinions,
            rentList: {
                ...state.rentList,
                content: state.rentList.content.map((offer) => {
                    if (offer.advertisementId === action.payload.advertisementId) {
                        return {
                            ...offer,
                            ...action.payload.response,
                            myOpinion: {
                                id: action.payload.response.opinionId,
                                dateAdded: new Date().toDateString(),
                                numberOfStars: action.payload.rating,
                                content: action.payload.commentContent
                            }
                        };
                    }

                    return offer;
                })
            },
            isLoading: false
        };
    case 'SET_OPINIONS':
        return {
            ...state,
            scoreAndOpinions: action.payload.data
        };
    case 'DELETE_OPINION':
        return {
            ...state,
            scoreAndOpinions: {
                ...state.scoreAndOpinions!,
                opinions: state.scoreAndOpinions!.opinions!.filter((item) => item.opinionId !== action.payload.opinionId)
            },
            rentList: {
                ...state.rentList,
                content: state.rentList.content.map((offer) => {
                    if (offer.advertisementId === action.payload.id) {
                        return {
                            ...offer,
                            ...action.payload.response,
                            myOpinion: null
                        };
                    }

                    return offer;
                })
            },
            isLoading: false
        };
    case 'DELETE_NOTE':
        return {
            ...state,
            oldNotes: state.oldNotes.filter((item) => item.noteId !== action.payload.noteId),
            list: [
                ...state.list.map((x) => {
                    if (x.id === action.payload.id) {
                        return {
                            ...x,
                            note: { ...state.oldNotes.filter((item) => item.noteId !== action.payload.noteId)[0] },
                            numberOfNotes: (x.numberOfNotes ?? 0) - 1
                        };
                    }

                    return x;
                })
            ]
        };
    case 'V2_DELETE_NOTE':
        return {
            ...state,
            notes: state.notes!.filter((item) => item.noteId !== action.payload.noteId),
            isLoading: false
        };
    case 'UPDATE_NOTE':
        return {
            ...state,
            list: [
                ...state.list.map((x) => {
                    if (x.id === action.payload.advertisementId) {
                        return {
                            ...x,
                            note: {
                                content: action.payload.content,
                                date: action.payload.date,
                                noteId: action.payload.noteId
                            }
                        };
                    }

                    return x;
                })
            ],
            oldNotes: state.oldNotes.map((item) => {
                if (item.noteId === action.payload.noteId) {
                    return {
                        ...item,
                        date: action.payload.date,
                        content: action.payload.content
                    };
                }

                return item;
            })
        };
    case 'SET_NEW_NOTE':
        return {
            ...state,
            list: [
                ...state.list.map((x) => {
                    if (x.id === action.payload.id) {
                        return {
                            ...x,
                            note: {
                                content: action.payload.note,
                                date: action.payload.date,
                                noteId: action.payload.noteId
                            },
                            numberOfNotes: (x.numberOfNotes ?? 0) + 1
                        };
                    }

                    return x;
                })
            ],
            oldNotes: [
                {
                    content: action.payload.note,
                    date: action.payload.dateTime,
                    noteId: action.payload.noteId
                }, ...state.oldNotes
            ]
        };
    case 'V2_UPDATE_NOTE':
        return {
            ...state,
            notes: state.notes!.map((item) => {
                if (item.noteId === action.payload.noteId) {
                    return {
                        ...item,
                        date: action.payload.date,
                        content: action.payload.content
                    };
                }

                return item;
            }),
            rentList: {
                ...state.rentList,
                content: state.rentList.content.map((x) => {
                    if (x.advertisementId === action.payload.id) {
                        return {
                            ...x,
                            note: {
                                content: action.payload.content,
                                date: action.payload.date,
                                noteId: action.payload.noteId
                            }
                        };
                    }

                    return x;
                })
            },
            isLoading: false
        };
    case 'V2_NEW_NOTE':
        return {
            ...state,
            notes: state.notes ? [action.payload].concat(state.notes) : [action.payload],
            rentList: {
                ...state.rentList,
                content: state.rentList.content.map((x) => {
                    if (x.advertisementId === action.payload.id) {
                        return {
                            ...x,
                            note: {
                                content: action.payload.content,
                                date: action.payload.date,
                                noteId: action.payload.noteId
                            },
                            numberOfNotes: (x.numberOfNotes ?? 0) + 1
                        };
                    }

                    return x;
                })
            },
            isLoading: false
        };
    case 'TOGGLE_FAVOURITE':
        return {
            ...state,
            list: [
                ...state.list.map((x) => {
                    if (x.id === action.payload.id) {
                        return {
                            ...x,
                            reaction: {
                                ...x.reaction,
                                likeStatus: action.payload.status
                            }
                        };
                    }

                    return x;
                })
            ]
        };
    case 'REACTION_UPDATE':
        return {
            ...state,
            list: [
                ...state.list.map((x) => {
                    if (x.id === action.payload.id) {
                        return {
                            ...x,
                            reaction: {
                                ...action.payload.reactions
                            }
                        };
                    }

                    return x;
                })
            ]
        };
    case 'V2_REACTION_UPDATE':
        return {
            ...state,
            rentList: {
                ...state.rentList,
                content: state.rentList.content.map((el) => {
                    if (el.advertisementId === action.payload.id) {
                        return {
                            ...el,
                            reaction: action.payload.reactions
                        };
                    }

                    return {
                        ...el,
                        duplicates: el.duplicates?.map((item) => {
                            if (item.advertisementId === action.payload.id) {
                                return {
                                    ...item,
                                    reaction: action.payload.reactions
                                };
                            }

                            return item;
                        })
                    };
                })
            }
        };
    default:
        return state;
    }
};

const getSortByType = (sortType: string) => {
    switch (sortType) {
    case 'po czynszu rosnąco':
        return compareRentPriceAscending;
    case 'po cenie rosnąco':
        return comparePriceAscending;
    case 'po cenie malejąco':
        return comparePriceDescending;
    case 'po czynszu malejąco':
        return compareRentPriceDescending;
    case 'po dacie rosnąco':
        return compareDateAscending;
    case 'po dacie licytacji rosnąco':
        return compareAuctionDateAscending;
    case 'po dacie licytacji malejąco':
        return compareAuctionDateDescending;
    case 'po cenie za m2 rosnąco':
        return comparePricePerSquareMeterAscending;
    case 'po cenie za m2 malejąco':
        return comparePricePerSquareMeterDescending;
    case 'od największej różnicy dat modyfikacji i dodania':
        return compareByModifyDate;
    case 'po dacie malejąco':
    default:
        return compareDateDescending;
    }
};

// @ts-expect-error FIXME: need to fix value returned from function
const getNewNote = (list, action) => {
    const note: { content: string, date: string, noteId: string }[] = [];

    // @ts-expect-error FIXME: need to fix value returned from function
    list.forEach((x) => {
        if (x.id === action.payload.id && !!x.note) {
            note.push({ ...x.note });
        }
    });

    // remove duplicates
    return note.filter((item, index, self) => index === self.findIndex(({ noteId }) => noteId === item.noteId));
};
