import React, { FC, useContext, useEffect, useState } from 'react';
import { bailiffOffersApiRequests } from 'utils/api-requests/bailiff';
import BailiffContext from 'utils/context/BailiffContext';
import { createUniqueFilterName } from 'utils/createUniqueFilterName';
import { generateOptionsFromEnum } from 'utils/generateOptionsFromEnum';
import { scheduleOptions } from 'utils/options';
import { getBailiffValueFromState } from 'utils/state-managment/bailiff/bailiffFilter';
import { BailiffFiltersStateType } from 'utils/types/Bailiff';
import { BailiffNoticeFilterResponseTypesEnum } from 'utils/types/BailiffModels';
import { InputType, LocationChangeType } from 'utils/types/InputTypes';

import Button from 'components/atom/Button';
import StickyBottom from 'components/common/FiltersRenderer/StickyBottom';
import V2FiltersRenderer from 'components/common/FiltersRenderer/V2FiltersRenderer';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import { activeNotification } from 'components/functions/activeNotification';
import { getVoivodeshipCities } from 'components/functions/locations';

type FiltersBailiffProperties = object;

const FiltersBailiff: FC<FiltersBailiffProperties> = () => {
    const {
        bailiffFiltersState,
        bailiffOfferState,
        handleChange,
        handleDeleteFilter,
        handleSetFiltersValues,
        handleChangeDateList,
        getSavedFilters,
        clearFilters,
        loadBailiffOffers
    } = useContext(BailiffContext);

    const [isFilterButtonVisible, setIsFilterButtonVisible] = useState(false);

    const getCities = async (voivodeship: string) => {
        const newLocations = await getVoivodeshipCities(voivodeship, bailiffFiltersState.locations);
        handleChange('locations', newLocations || []);
    };

    const getOfficeCities = async (voivodeship: string) => {
        const newLocations = await getVoivodeshipCities(voivodeship, bailiffFiltersState.officeLocations);
        handleChange('officeLocations', newLocations || []);
    };

    const handleRemoveSavedLocation = (location: string) => {
        const savedLocations = bailiffFiltersState.savedLocations;
        delete savedLocations[location];

        handleChange('savedLocations', savedLocations);
        handleChange('city', Object.values(savedLocations as LocationChangeType));
    };

    const handleRemoveSavedOfficeLocation = (location: string) => {
        const savedOfficeLocations = bailiffFiltersState.savedOfficeLocations;
        delete savedOfficeLocations[location];

        handleChange('savedOfficeLocations', savedOfficeLocations);
        handleChange('officeCity', Object.values(savedOfficeLocations as LocationChangeType));
    };

    const locationOrOfficeLocationCompleted =
       (bailiffFiltersState.city.length > 0 || bailiffFiltersState.officeCity.length > 0) &&
       !(bailiffFiltersState.city.length === 0 && bailiffFiltersState.officeCity.length === 0);

    const handleSubmit = () => {
        if (!locationOrOfficeLocationCompleted) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');
        } else {
            loadBailiffOffers();
            document.body.scrollTo(0, 0);
        }
    };

    const handleSaveOrUpdateFilter = async () => {
        if (!locationOrOfficeLocationCompleted) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        const existFilter = bailiffFiltersState.savedFilter?.find(
            (filter) => filter.filterId === bailiffFiltersState.filterId
        );

        const existName = bailiffFiltersState.savedFilter?.find(
            (filter) => filter.name === bailiffFiltersState.name
        );

        let id;

        if (existName && !bailiffFiltersState.filterId) {
            activeNotification('Nazwa filtra', 'Istnieje już filtr o takiej nazwie', 'warning');

            return;
        }

        if (existFilter) {
            await bailiffOffersApiRequests.updateFilter({
                ...getBailiffValueFromState(bailiffFiltersState)
            });
        } else {
            const resp = await bailiffOffersApiRequests.addFilter({
                ...getBailiffValueFromState(bailiffFiltersState)
            });

            id = resp?.data;
        }

        getSavedFilters(id);
    };

    const handleCreateFromExistFilter = async () => {
        if (bailiffFiltersState.city.length <= 0) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        const newName = createUniqueFilterName(bailiffFiltersState.name!, bailiffFiltersState.savedFilter);

        const newFilterData = {
            ...getBailiffValueFromState(bailiffFiltersState),
            name: newName
        };

        const resp = await bailiffOffersApiRequests.addFilter(newFilterData);

        const id = resp?.data;

        getSavedFilters(id);
    };

    useEffect(() => {
        getSavedFilters();
    }, []);

    const handleChangeNotificationAlert = async (valueName: string, id: number) => {
        const fieldName = valueName === 'pushAlert' ? 'alertPush' : valueName;
        const updatedState = {
            ...bailiffFiltersState,
            [fieldName]: !bailiffFiltersState[fieldName as keyof BailiffFiltersStateType]
        };

        await bailiffOffersApiRequests.updateFilter(getBailiffValueFromState(updatedState));
        getSavedFilters(id);
    };

    const bailiffFiltersStructure = [
        {
            accordionTitle: 'Podstawowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Zapisane filtry',
                    type: InputType.FILTER_AUTOCOMPLETE,
                    icon: IconEnum.BOOKMARK,
                    placeholder: 'Wybierz filtr...',
                    options: {
                        [InputType.FILTER_AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleDeleteFilter,
                                handleSetFiltersValues
                            },
                            modulesProps: {
                                filterId: bailiffFiltersState.filterId ?? null,
                                options: bailiffFiltersState.savedFilter.map((x) => ({
                                    ...x,
                                    pushAlert: x.alertPush,
                                    id: x.filterId
                                }
                                ))
                            }
                        }
                    }
                },
                {
                    title: 'Lokalizacja',
                    type: InputType.LOCATION,
                    helperText: 'Wybierz z listy interesującą Ciebie lokalizację. Możesz zaznaczyć dowolną ilość.',
                    icon: IconEnum.MAP_PIN,
                    placeholder: 'Szukaj lokalizacji...',
                    options: {
                        [InputType.LOCATION]: {
                            modulesProps: {
                                locations: bailiffFiltersState.locations,
                                searchLocationResult: bailiffFiltersState.searchLocationResult,
                                savedLocations: bailiffFiltersState.savedLocations,
                                savedLocationsFieldName: 'savedLocations',
                                cityFieldName: 'city'
                            },
                            contextFunctions: {
                                getCities,
                                handleRemoveSavedLocation,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Lokalizacja sądu',
                    type: InputType.LOCATION,
                    helperText: 'Wybierz z listy interesującą Ciebie lokalizację. Możesz zaznaczyć dowolną ilość.',
                    icon: IconEnum.MAP_PIN,
                    placeholder: 'Szukaj lokalizacji...',
                    options: {
                        [InputType.LOCATION]: {
                            modulesProps: {
                                locations: bailiffFiltersState.officeLocations,
                                searchLocationResult: bailiffFiltersState.searchOfficeLocationResult,
                                savedLocations: bailiffFiltersState.savedOfficeLocations,
                                savedLocationsFieldName: 'savedOfficeLocations',
                                cityFieldName: 'officeCity'

                            },
                            contextFunctions: {
                                getCities: getOfficeCities,
                                handleRemoveSavedLocation: handleRemoveSavedOfficeLocation,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Data',
                    type: InputType.FLEXIBLE_DATE,
                    helperText: 'Wybierz, z jakiego okresu chcesz wyświetlić oferty. Wybierz opcję "Przedział dat" aby precyzyjnie wskazać datę. Ten parametr nie wpływa na natychmiastowe wysyłanie alertów.',
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Data dodania oferty...',
                    containerActionText: 'Przedział dat',
                    containerActionClick: () => handleChange('dateRangeFilter', !bailiffFiltersState.dateRangeFilter),
                    options: {
                        [InputType.FLEXIBLE_DATE]: {
                            modulesProps: {
                                date: bailiffFiltersState.offerAdded,
                                dateRangeFilter: bailiffFiltersState.dateRangeFilter,
                                daysAmount: bailiffFiltersState.daysAmount,
                                fieldName: 'offerAdded'
                            },
                            contextFunctions: {
                                handleChange,
                                handleChangeDateList
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Parametry',
            hideTitle: true,
            filters: [
                {
                    title: 'Typ nieruchomości',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz rodzaj...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: bailiffFiltersState.types,
                                options: generateOptionsFromEnum(BailiffNoticeFilterResponseTypesEnum),
                                optionsIcon: IconEnum.TWO_TYPE,
                                fieldName: 'types'
                            },
                            contextFunctions: {
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Słowa pożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: bailiffFiltersState.wantedKeywords.filter(Boolean) as string[],
                                fieldName: 'wantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Słowa niepożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które nie mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: bailiffFiltersState.unwantedKeywords.filter(Boolean) as string[],
                                fieldName: 'unwantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Dodatkowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Harmonogram',
                    type: InputType.AUTOCOMPLETE,
                    helperText: 'Co jaki okres chcesz otrzymywać powiadomienia? Jeżeli wybierzesz "Natychmiast" to każda oferta zostanie wysłana w osobnym mailu. w pozostałych przypadkach otrzymasz jednego maila np. co 5 min z listą ogłoszeń, które pojawiły się w tym czasie.',
                    icon: IconEnum.TIMER,
                    placeholder: 'Wybierz godziny...',
                    options: {
                        [InputType.AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleChange
                            },
                            modulesProps: {
                                value: bailiffFiltersState.notificationsDelay,
                                fieldName: 'notificationsDelay',
                                options: scheduleOptions
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Podsumowanie',
            hideTitle: true,
            isWideSpace: true,
            lastElement: true,
            filters: [
                {
                    title: 'Zapisz nowy filtr',
                    type: InputType.TEXT,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz nazwę...',
                    containerActionText: bailiffFiltersState.filterId ? 'Duplikuj' : undefined,
                    containerActionClick: handleCreateFromExistFilter,
                    options: {
                        [InputType.TEXT]: {
                            modulesProps: {
                                value: bailiffFiltersState.name || '',
                                fieldName: 'name',
                                inputIcon: IconEnum.BOOKMARK_WHITE,
                                actionIcon: IconEnum.ARROW_RIGHT,
                                important: true,
                                isSaveFilterButton: true
                            },
                            contextFunctions: {
                                handleChange,
                                inputActionHandler: handleSaveOrUpdateFilter
                            }
                        }
                    }
                },
                {
                    title: 'Aktywacja powiadomień',
                    type: InputType.NOTIFICATIONS,
                    helperText: 'Przed aktywacją powiadomienia zapisz filtr',
                    icon: IconEnum.ALERT,
                    placeholder: '',
                    options: {
                        [InputType.NOTIFICATIONS]: {
                            contextFunctions: {
                                handleChange: handleChangeNotificationAlert
                            },
                            modulesProps: {
                                alertEmail: bailiffFiltersState.alertEmail,
                                alertSms: bailiffFiltersState.alertSms,
                                pushAlert: bailiffFiltersState.alertPush,
                                filterId: bailiffFiltersState.filterId ?? null
                            }
                        }
                    }
                },
                {
                    title: 'Zastosuj filtry',
                    type: InputType.SAVE_BUTTON,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Przeglądaj oferty...',
                    options: {
                        [InputType.SAVE_BUTTON]: {
                            contextFunctions: {
                                onChangeVisibleButtonFilter: setIsFilterButtonVisible,
                                onActionClick: clearFilters,
                                onClick: handleSubmit
                            },
                            modulesProps: {
                                actionText: 'Resetuj'
                            }
                        }
                    }
                }
            ]
        }
    ];

    return (
        <V2FiltersRenderer elementsToRender={bailiffFiltersStructure} type={'bailiff_notice'} bottomActions={
            <StickyBottom
                isVisible={isFilterButtonVisible}
                active={bailiffOfferState.bailiffOfferList?.content?.length > 0 || bailiffOfferState.bailiffOfferList.empty}
                className={'sticky-filter-button'}>
                <InputContainer title={'Zastosuj filtry'} icon={IconEnum.DICTIONARY}
                    actionText={'Resetuj'}
                    onActionClick={clearFilters}>
                    <Button onClick={handleSubmit}>
                        {'Przeglądaj oferty...'}
                    </Button>
                </InputContainer>
            </StickyBottom>
        }/>
    );
};

export default FiltersBailiff;
