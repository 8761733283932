import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Icon, { IconEnum } from './Icon';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: var(--box-border-radius);
    color: var(--color-primary);
`;

const FiltersRow = styled.div<{ isExpanded: boolean }>`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: ${({ isExpanded }) => isExpanded ? 'none' : '100px'}; // przybliżona wartość dwóch linijek
    overflow: hidden;
    transition: max-height 0.3s ease;
`;

const Filter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-alt-second);
    padding: 10px 20px;
    border-radius: 20px;
    gap: 10px;

    span {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    p {
        font-weight: 400;
    }
`;

const ExpandButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

`;

const ExpandButton = styled.div`
    background: none;
    padding: 5px;
    border: none;
    color: var(--color-primary);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: var(--font-weight);

    &:hover {
        outline: none;
        background: none;
        border: none;
        color: var(--color-primary);
    }

`;

export interface FilterItem {
    label: string;
    value: string | number | null | string[];
    filterName?: string;
    index?: number;
    noDeleteButton?: boolean;
    suffix?: string;
}

interface ActiveFiltersProperties {
    filters: FilterItem[];
    onChange: () => void;
    onDeleteFilter: (filterName: string, value?: string | number | null | string[], index?: number) => void;
}

const V2ActiveFilters: FC<ActiveFiltersProperties> = ({ filters, onChange, onDeleteFilter }) => {
    const [isActionPerformed, setIsActionPerformed] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [hasOverflow, setHasOverflow] = useState(false);
    const [hiddenFiltersCount, setHiddenFiltersCount] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    const getHiddenFiltersCount = () => {
        if (containerRef.current) {
            const totalHeight = 100; // wysokość dwóch linii
            const filters = containerRef.current.querySelectorAll('#filter-item');
            let visibleCount = 0;

            for (const filter of filters) {
                if (filter.getBoundingClientRect().top < containerRef.current.getBoundingClientRect().top + totalHeight) {
                    visibleCount++;
                }
            }

            setHiddenFiltersCount(filters.length - visibleCount);
        }
    };

    const checkOverflow = useCallback(() => {
        if (containerRef.current) {
            const hasVerticalOverflow = containerRef.current.scrollHeight > 100;
            setHasOverflow(hasVerticalOverflow);
        }
    }, []);

    useEffect(() => {
        const handleSetOverflowAndHiddenFiltersCount = () => {
            checkOverflow();
            getHiddenFiltersCount();
        };

        handleSetOverflowAndHiddenFiltersCount();
        window.addEventListener('resize', handleSetOverflowAndHiddenFiltersCount);

        return () => window.removeEventListener('resize', handleSetOverflowAndHiddenFiltersCount);
    }, [filters]);

    useEffect(() => {
        if (isActionPerformed) {
            onChange();
            setIsActionPerformed(false);
        }
    }, [filters]);

    const handleDeleteFilter = (filterName: string, value?: string | number | null | string[], index?: number) => {
        onDeleteFilter(filterName, value, index);
        setIsActionPerformed(true);
    };

    const generateFilter = (item: FilterItem) => {
        const { label, value, filterName, index, suffix } = item;

        if (value !== null && value !== undefined && (typeof value !== 'string' || value.trim() !== '') && (!Array.isArray(value) || value.length > 0)) {
            return (
                <Filter id="filter-item" key={index !== undefined ? `${filterName}-${index}` : label}>
                    <span>{label}: <p>{Array.isArray(value) ? value.join(', ') : suffix ? `${value} ${suffix}` : value}</p></span>
                    {!item.noDeleteButton && <Icon onClick={() => handleDeleteFilter(filterName || label, value, index)} icon={IconEnum.CIRCLE_CROSS}/>}
                </Filter>
            );
        }

        return null;
    };

    return (
        <Container>
            <FiltersRow ref={containerRef} isExpanded={isExpanded}>
                {filters.map(generateFilter)}
            </FiltersRow>

            {hasOverflow &&
            <ExpandButtonWrapper>
                <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? 'Zwiń' : `Pokaż więcej(${hiddenFiltersCount})`}
                    <Icon icon={isExpanded ? IconEnum.ACCORDION_UP : IconEnum.ACCORDION_DOWN} />
                </ExpandButton>
            </ExpandButtonWrapper>
            }
        </Container>
    );
};

export default V2ActiveFilters;

