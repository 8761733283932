import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactionType } from 'store/Offers/State';
import styled from 'styled-components';
import { bailiffOffersApiRequests } from 'utils/api-requests/bailiff';
import { commonApiRequests } from 'utils/api-requests/common';
import { isTouchDevice } from 'utils/checkIsTouchDevice';
import { BailiffNoticeDetailsHashResponse } from 'utils/types/BailiffModels';
import { UserReactions } from 'utils/types/OfferData';

import Indicator from 'components/atom/Indicator';
import OfferActions from 'components/common/Card/common/OfferActions/OfferActions';
import {
    InteractiveElements
} from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import SingleOfferDetailsRenderer, {
    SingleOfferDetailsRendererProps
} from 'components/common/SingleOfferDetailsRenderer/SingleOfferDetailsRenderer';
import { ExtendedNote } from 'components/common/Tabs/tabs/V2NoteTab';
import { TabType } from 'components/common/Tabs/V2Tabs';
import {
    bailiffOfferListFunctions
} from 'components/functions/offerListFunctions/bailiffOfferListFunctions/bailiffOfferListFunctions';
import { generateOfferActionsElements } from 'components/functions/shared/generateOfferActionsElements';

const Wrapper = styled.div`
    margin: 15px;
`;

const NoAdvertContainer = styled.h1`
    text-align: center;
    margin-top: 15%;
`;

const BAILIFF_MODULE = 'BAILIFF_NOTICE';

const SingleBailiffDetailPage = () => {
    const { hash } = useParams();
    const [preparedAdvert, setPreparedAdvert] = useState<SingleOfferDetailsRendererProps>();
    const [moreButtonActiveId, setMoreButtonActiveId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [offer, setOffer] = useState<BailiffNoticeDetailsHashResponse>();
    const [notes, setNotes] = useState();

    const handleChangeReaction = async (name: keyof ReactionType, value: boolean, offer: BailiffNoticeDetailsHashResponse) => {
        const userReaction = Object.assign({}, offer.reaction);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location;

        const response = await commonApiRequests.setOfferReaction(offer.bailiffNoticeId, city, userReaction as UserReactions, BAILIFF_MODULE);
        setPreparedAdvert((prev) => ({
            ...prev,
            detailsCardData: {
                ...prev!.detailsCardData,
                interactiveElements: generateInteractiveElements({ ...offer, reaction: response })
            }
        }));
    };

    const handleSaveNote = async (value: string, advertId: number, type: string, id?: number) => {
        const response = await commonApiRequests.updateNote(type, value, advertId, id || 0);

        if (response) {
            if (id) {
                setPreparedAdvert((prev) => {
                    return {
                        ...prev,
                        detailsCardData: {
                            ...prev!.detailsCardData,
                            tabsContent: {
                                ...prev!.detailsCardData.tabsContent,
                                notesTabContent: {
                                    ...prev!.detailsCardData.tabsContent.notesTabContent!,
                                    notes: prev!.detailsCardData.tabsContent.notesTabContent!.notes!.map((x) => {
                                        if (x.noteId === id) {
                                            return {
                                                ...x,
                                                content: value
                                            };
                                        }

                                        return x;
                                    })
                                }
                            }
                        }
                    };
                });
            } else {
                setPreparedAdvert((prev) => {
                    return {
                        ...prev,
                        detailsCardData: {
                            ...prev!.detailsCardData,
                            tabsContent: {
                                ...prev!.detailsCardData.tabsContent,
                                notesTabContent: {
                                    ...prev!.detailsCardData.tabsContent.notesTabContent!,
                                    notes: prev!.detailsCardData.tabsContent.notesTabContent!.notes ? [response].concat(prev!.detailsCardData.tabsContent.notesTabContent!.notes as unknown as ExtendedNote) : [response]
                                },
                                numberOfNotes: (prev!.detailsCardData.tabsContent.numberOfNotes ?? 0) + 1
                            }
                        }
                    };
                });
            }
        }
    };

    const generateInteractiveElements = (offer: BailiffNoticeDetailsHashResponse): InteractiveElements[] => {
        const offerLink = `${window.location.origin}/bailiff-notice/${offer.encryptedId}`;
        const isMoreButtonActive = moreButtonActiveId === offer.bailiffNoticeId.toString();
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link, true);

        return [
            {
                tooltipText: 'Wyświetlono',
                icon: IconEnum.EYE,
                active: offer.reaction?.viewedStatus,
                disabled: offer.reaction?.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reaction?.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reaction?.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reaction?.likeStatus, offer)
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reaction?.unlikeStatus,
                disabled: offer.reaction?.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reaction?.unlikeStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes || 1 : undefined,
                openTabOnClick: TabType.NOTES
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reaction?.hideStatus,
                disabled: offer.reaction?.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reaction?.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reaction?.phoneStatus,
                disabled: offer.reaction?.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reaction?.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reaction?.arrangedStatus,
                disabled: offer.reaction?.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reaction?.arrangedStatus, offer)
            },
            {
                icon: 'squares' as IconEnum,
                tooltipText: isTouchDevice() ? undefined : 'Więcej',
                onClick: () => isMoreButtonActive ? setMoreButtonActiveId(null) : setMoreButtonActiveId(offer.bailiffNoticeId.toString()),
                additionalJSXSibling: isMoreButtonActive ? <OfferActions onClickOutside={() => setMoreButtonActiveId(null)} isInModal elements={offerActionsElements} /> : undefined
            }
        ];
    };

    const handleDelete = async (noteId: number, type: string) => {
        const response = await commonApiRequests.deleteNote(type, noteId);

        if (response) {
            setPreparedAdvert((prev) => {
                return {
                    ...prev,
                    detailsCardData: {
                        ...prev!.detailsCardData,
                        tabsContent: {
                            ...prev!.detailsCardData.tabsContent,
                            notesTabContent: {
                                ...prev!.detailsCardData.tabsContent.notesTabContent!,
                                notes: prev!.detailsCardData.tabsContent.notesTabContent!.notes!.filter((x) => x.noteId !== noteId)
                            },
                            numberOfNotes: prev!.detailsCardData.tabsContent.numberOfNotes! - 1
                        }
                    }
                };
            });
        }
    };

    useEffect(() => {
        setIsLoading(true);
        bailiffOffersApiRequests.getBailiffOfferByHashId(hash).then(async (offer) => {
            setOffer(offer);
            const notes = await commonApiRequests.getNotes(BAILIFF_MODULE, offer.bailiffNoticeId.toString());

            setNotes(notes);
            setIsLoading(false);
        }).catch(() => {
            setPreparedAdvert(undefined);
            setIsLoading(false);
        });
    }, [hash]);

    useEffect(() => {
        if (offer) {
            const detailsTabContent = bailiffOfferListFunctions.generateOfferDetailsTabContent({
                ...offer,
                detailedContent: { description: offer.description }
            });

            const detailsCardData = {
                id: offer.bailiffNoticeId,
                interactiveElements: generateInteractiveElements(offer),
                additionalInfoBadge: offer.dateAdded,
                thumbnailPath: offer.image ? offer.image : './../defaultImg.png',
                tabsContent: {
                    interactiveElements: generateInteractiveElements(offer),
                    detailsTabContent,
                    notesTabContent: {
                        notes: notes,
                        handleDelete: (noteId: string) => handleDelete(Number(noteId), BAILIFF_MODULE),
                        handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.bailiffNoticeId, BAILIFF_MODULE, Number(id))
                    },
                    numberOfNotes: offer.numberOfNotes || 0,
                    handleLoadNotes: () => {
                    }
                }
            };

            setPreparedAdvert({ detailsCardData });
        }
    }, [
        offer,
        moreButtonActiveId
    ]);

    return (
        <Wrapper>
            {isLoading
                ? <Indicator/>
                : preparedAdvert?.detailsCardData
                    ? <SingleOfferDetailsRenderer detailsCardData={preparedAdvert.detailsCardData}/>
                    : <NoAdvertContainer>Nie znaleziono ogłoszenia o podanym id.</NoAdvertContainer>}
        </Wrapper>
    );
};

export default SingleBailiffDetailPage;
