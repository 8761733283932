import axios from 'axios';
import { axiosApiInstance, axiosApiInstance2 } from 'Store';

import { State } from './State';

export interface Action {
    type: string,
    // TODO: fix any (need to fix value returned from actions)
    payload?: any // eslint-disable-line
}

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
    case 'SET_LOADING':
        return {
            ...state,
            isLoading: action.payload !== undefined ? action.payload : true
        };
    case 'SET_MY_OFFERS':
        return {
            ...state,
            myOfferList: action.payload,
            isLoading: false
        };
    case 'DELETE_MY_OFFER':
        return {
            ...state,
            myOfferList: {
                ...state.myOfferList,
                content: state.myOfferList.content.filter((item) => item.advertisementId !== action.payload)
            },
            isLoading: false
        };
    case 'SET_PROFILE_OFFERS':
        return {
            ...state,
            profileOfferList: action.payload,
            isLoading: false
        };
    case 'SET_PROFILE':
        return {
            ...state,
            profile: action.payload,
            isLoading: false
        };
    case 'SET_NOTIFICATION_SETTINGS':
        return {
            ...state,
            notificationSettings: action.payload,
            isLoading: false
        };
    case 'SET_ACTIVE_PACKAGE_INFO':
        return {
            ...state,
            activePackageInfo: action.payload,
            isLoading: false
        };
    case 'SET_FILTERS':
        return {
            ...state,
            usersOfferList: action.payload,
            isLoading: false
        };
    case 'CLEAR':
        return {
            ...state,
            usersOfferList: {
                last: false,
                empty: false,
                content: [],
                pageable: {
                    pageNumber: 0,
                    pageSize: 0
                },
                numberOfElements: 0,
                totalPages: 0,
                size: 25
            }
        };
    case 'SET_DETAILED_OFFER':
        return {
            ...state,
            usersOfferList: {
                ...state.usersOfferList,
                content: state.usersOfferList.content.map((el) => {
                    if (el.advertisementId === action.payload.advertisementId) {
                        return {
                            ...el,
                            detailedContent: action.payload
                        };
                    }

                    return el;
                })
            },
            isLoading: false
        };
    case 'SET_DETAILED_PROFILE_OFFER':
        return {
            ...state,
            profileOfferList: {
                ...state.profileOfferList,
                content: state.profileOfferList.content.map((el) => {
                    if (el.advertisementId === action.payload.advertisementId) {
                        return {
                            ...el,
                            detailedContent: action.payload
                        };
                    }

                    return el;
                })
            },
            isLoading: false
        };
    case 'SET_DETAILED_MYOFFER':
        return {
            ...state,
            myOfferList: {
                ...state.myOfferList,
                content: state.myOfferList.content.map((el) => {
                    if (el.advertisementId === action.payload.advertisementId) {
                        return {
                            ...el,
                            detailedContent: action.payload
                        };
                    }

                    return el;
                })
            },
            isLoading: false
        };
    case 'SET_DETAILS_TO_EDIT':
        return {
            ...state,
            offerToEdit: { ...action.payload, availableFrom: action.payload.availableFrom?.split('T')[0], estimatedSaleDate: action.payload.estimatedSaleDate?.split('T')[0] },
            isLoading: false
        };
    case 'REACTION_UPDATE':
        return {
            ...state,
            usersOfferList: {
                ...state.usersOfferList,
                content: state.usersOfferList.content.map((el) => {
                    if (el.advertisementId === action.payload.id) {
                        return {
                            ...el,
                            reactionDto: action.payload.reactions
                        };
                    }

                    return el;
                })
            },
            profileOfferList: {
                ...state.profileOfferList,
                content: state.profileOfferList.content.map((el) => {
                    if (el.advertisementId === action.payload.id) {
                        return {
                            ...el,
                            reactionDto: action.payload.reactions
                        };
                    }

                    return el;
                })
            }
        };
    case 'SET_NOTES':
        return {
            ...state,
            notes: action.payload.data,
            isLoading: false
        };
    case 'UPDATE_NOTE':
        return {
            ...state,
            notes: state.notes!.map((item) => {
                if (item.noteId === action.payload.noteId) {
                    return {
                        ...item,
                        content: action.payload.content
                    };
                }

                return item;
            }),
            isLoading: false
        };

    case 'NEW_PROFILE_OFFERS_NOTE':
        return {
            ...state,
            notes: state.notes ? state.notes.concat(action.payload) : [action.payload],
            profileOfferList: {
                ...state.profileOfferList,
                content: state.profileOfferList.content.map((offer) => {
                    if (offer.advertisementId === action.payload.advertisementId) {
                        return {
                            ...offer,
                            note: {
                                content: action.payload.content,
                                date: action.payload.date,
                                noteId: action.payload.noteId
                            },
                            numberOfNotes: (offer.numberOfNotes ?? 0) + 1
                        };
                    }

                    return offer;
                })
            },
            isLoading: false
        };

    case 'NEW_NOTE':
        return {
            ...state,
            notes: state.notes ? state.notes.concat(action.payload) : [action.payload],
            usersOfferList: {
                ...state.usersOfferList,
                content: state.usersOfferList.content.map((offer) => {
                    if (offer.advertisementId === action.payload.advertisementId) {
                        return {
                            ...offer,
                            note: {
                                content: action.payload.content,
                                date: action.payload.date,
                                noteId: action.payload.noteId
                            },
                            numberOfNotes: (offer.numberOfNotes ?? 0) + 1
                        };
                    }

                    return offer;
                })
            },
            isLoading: false
        };
    case 'DELETE_NOTE':
        return {
            ...state,
            notes: state.notes!.filter((item) => item.noteId !== action.payload.noteId),
            usersOfferList: {
                ...state.usersOfferList,
                content: state.usersOfferList.content.map((offer) => {
                    if (offer.advertisementId === action.payload.id) {
                        return {
                            ...offer,
                            numberOfNotes: offer.numberOfNotes - 1
                        };
                    }

                    return offer;
                })
            },
            isLoading: false
        };

    case 'DELETE_PROFILE_OFFERS_NOTE':
        return {
            ...state,
            notes: state.notes!.filter((item) => item.noteId !== action.payload.noteId),
            profileOfferList: {
                ...state.profileOfferList,
                content: state.profileOfferList.content.map((offer) => {
                    if (offer.advertisementId === action.payload.id) {
                        return {
                            ...offer,
                            numberOfNotes: offer.numberOfNotes - 1
                        };
                    }

                    return offer;
                })
            },
            isLoading: false
        };

    case 'LOGIN':
        axiosApiInstance.defaults.headers.common = { Authorization: `Bearer ${action.payload.token}` };

        return {
            ...state,
            isLoading: false,
            isLogged: true,
            user: action.payload.user,
            token: action.payload.token,
            refreshToken: action.payload.refreshToken,
            activePackages: action.payload.roles ? action.payload.roles : state.activePackages
        };
    case 'LOGIN2':
        axiosApiInstance2.defaults.headers.common = { Authorization: `Bearer ${action.payload.token}` };

        return {
            ...state,
            isLoading: false,
            isLogged2: true,
            user2: action.payload.user,
            token2: action.payload.token,
            refreshToken2: action.payload.refreshToken,
            activePackages2: action.payload.roles ? action.payload.roles : state.activePackages,
            isInOrganization: action.payload.organization ? action.payload.organization : state.isInOrganization
        };
    case 'LOGOUT':
        axios.defaults.headers.common = {};

        return {
            ...state,
            isLoading: false,
            isLogged: false,
            user: {},
            token: ''
        };
    case 'CHANGE_THEME':
        return {
            ...state,
            isDarkMode: action.payload
        };
    case 'CHANGE_ERROR_VISIBILITY':
        return {
            ...state,
            isInputError: action.payload
        };
    case 'SET_MESSAGES':
        return {
            ...state,
            userMessageList: action.payload,
            isLoading: false
        };
    case 'SET_ADVERT_MESSAGES':
        return {
            ...state,
            usersOfferList: {
                ...state.usersOfferList,
                content: state.usersOfferList.content.map((el) => {
                    if (el.advertisementId === action.payload.id) {
                        return {
                            ...el,
                            messageDetail: {
                                ...el.messageDetail,
                                messageDetails: action.payload.messages
                            }
                        };
                    }

                    return el;
                })
            },
            profileOfferList: {
                ...state.profileOfferList,
                content: state.profileOfferList.content.map((el) => {
                    if (el.advertisementId === action.payload.id) {
                        return {
                            ...el,
                            messageDetail: {
                                ...el.messageDetail,
                                messageDetails: action.payload.messages
                            }
                        };
                    }

                    return el;
                })
            }
        };

    case 'SET_VISIBILITY_OFFER':
        return {
            ...state,
            myOfferList: {
                ...state.myOfferList,
                content: state.myOfferList.content.map((el) => {
                    if (el.advertisementId === action.payload.id) {
                        return {
                            ...el,
                            activationStatus: el.activationStatus !== true
                        };
                    }

                    return el;
                })
            }

        };

    default:
        return state;
    }
};
