import React, { createContext, useCallback, useEffect, useState } from 'react';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import { auctionOffersApiRequests } from 'utils/api-requests/auction';
import { commonApiRequests } from 'utils/api-requests/common';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { offersApiRequests } from 'utils/api-requests/offers';
import { getAuctionValueFromState, initialState, setAuctionStateValues } from 'utils/state-managment/auction/auctionFilter';
import { auctionInitialState, AuctionOffersResponse, AuctionOfferStateType } from 'utils/state-managment/auction/auctionOffer';
import { AuctionFilterReturnData, AuctionFiltersStateType } from 'utils/types/Auction';
import { AuctionRemindResponse, AuctionSearchingRequestSortTypeEnum, NoteResponse } from 'utils/types/AuctionModels';
import { ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { UserReactions } from 'utils/types/OfferData';

interface LoadAuctionOffersType {
    requestData?: AuctionFilterReturnData,
    page?: number,
    rows?: number
}

export interface AuctionContextType {
    auctionFiltersState: AuctionFiltersStateType,
    auctionOfferState: AuctionOfferStateType,
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void,
    handleSetFiltersValues: (id: number) => void,
    handleDeleteFilter: (id: number) => void,
    handleChangeDateList: (fieldName: string, value: number) => void,
    getSavedFilters: (id?: number) => void,
    clearFilters: () => void,
    loadAuctionOffers: (auctionOffersOptions?: LoadAuctionOffersType) => void;
    deleteNote: (id: number, noteId: number) => void,
    updateNote: (noteContent: string, advertisementId: number, noteId: number) => void,
    reactionUpdate: (id: number, city: string, reaction: UserReactions) => void,
    loadOfferDetailsAsync: (id: number) => void;
    loadNotesAsync: (id: string) => void;
    loadFavoriteOffers: (page?: number, size?: number, filters?: FavoritePageFiltersType) => void,
    clearOffers: () => void,
    updateReminder: (id: number, reminder: 'sms' | 'email') => void,
}

const values: AuctionContextType = {
    auctionFiltersState: {} as AuctionFiltersStateType,
    auctionOfferState: {} as AuctionOfferStateType,
    handleChange: () => {},
    handleSetFiltersValues: () => {},
    handleDeleteFilter: () => {},
    handleChangeDateList: () => {},
    getSavedFilters: () => {},
    clearFilters: () => {},
    loadAuctionOffers: () => {},
    deleteNote: () => {},
    updateNote: () => {},
    reactionUpdate: () => {},
    loadOfferDetailsAsync: () => {},
    loadNotesAsync: () => {},
    loadFavoriteOffers: () => {},
    clearOffers: () => {},
    updateReminder: () => {}
};

const AUCTION_MODULE = 'AUCTION';

export const AuctionContext = createContext(values);

export const AuctionProvider = ({ children }: { children: React.ReactNode }) => {
    const [auctionFiltersState, setAuctionFiltersState] = useState(initialState.auctionFilterInitialState());
    const [auctionOfferState, setAuctionOfferState] = useState(auctionInitialState);

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setAuctionFiltersState((filters: AuctionFiltersStateType) => ({
            ...filters,
            [fieldName]: value
        }));

        if (fieldName === 'sortType') {
            setAuctionOfferState((prevState) => ({
                ...prevState,
                sortType: value as AuctionSearchingRequestSortTypeEnum
            }));
        }
    }, [auctionFiltersState]);

    const getSavedFilters = useCallback(async (id?: number) => {
        const savedFilters = await auctionOffersApiRequests.getSavedFilters();
        setAuctionFiltersState((filters: AuctionFiltersStateType) => ({
            ...filters,
            savedFilter: savedFilters
        }));

        if (id) {
            await handleSetFiltersValues(id);
        }
    }, []);

    const clearFilters = useCallback((reset?: boolean) => {
        setAuctionFiltersState((prev) => ({
            ...initialState.auctionFilterInitialState(),
            locations: prev.locations,
            city: reset ? [] : prev.city,
            savedLocations: reset ? {} : prev.savedLocations
        }));
        getSavedFilters();
    }, [getSavedFilters]);

    const handleDeleteFilter = useCallback(async (filterId: number) => {
        await auctionOffersApiRequests.removeSavedFilter(filterId);

        setAuctionFiltersState(initialState.auctionFilterInitialState());
        getSavedFilters();
    }, [
        auctionFiltersState.filterId,
        auctionFiltersState.savedFilter,
        getSavedFilters
    ]);

    const handleSetFiltersValues = async (id: number) => {
        const filterData = await auctionOffersApiRequests.getFilterDetailsAuction(id) as AuctionFilterReturnData;

        if (filterData) {
            setAuctionFiltersState((filters: AuctionFiltersStateType) => ({
                ...setAuctionStateValues({ ...filterData, locations: auctionFiltersState.locations }),
                savedFilter: filters.savedFilter,
                filterId: id
            }));
            getVoivodeships();
        } else {
            setAuctionFiltersState((filters: AuctionFiltersStateType) => ({
                ...filters,
                name: null,
                filterId: null
            }));
        }
    };

    const handleChangeDateList = (fieldName: string, value: number) => {
        setAuctionFiltersState((filters: AuctionFiltersStateType) => ({
            ...filters,
            offerAdded: {
                from: value === -1 ? null : new Date(new Date().setDate(new Date().getDate() - value)).toISOString().split('T')[0],
                to: new Date().toISOString().split('T')[0]
            },
            [fieldName]: value
        }));
    };

    const setLoadingPage = () => {
        setAuctionOfferState((prev) => ({
            ...prev,
            isLoading: true
        }));
    };

    const loadAuctionOffers = async (auctionOffersOptions?: LoadAuctionOffersType) => {
        const { requestData, page, rows } = auctionOffersOptions || {};

        setLoadingPage();

        const response = await auctionOffersApiRequests.getAuctionOffers(requestData ? requestData : getAuctionValueFromState(auctionFiltersState), page ?? 0, rows ?? 25);

        if (response) {
            setAuctionOfferState((prev) => ({
                ...prev,
                auctionOfferList: response,
                isLoading: false
            }));
        }
    };

    const loadFavoriteOffers = async (page?: number, size?: number, filters?: FavoritePageFiltersType) => {
        setLoadingPage();

        const response = await offersApiRequests.getUsersFavoriteOffers(AUCTION_MODULE, page ?? 1, size ?? 25, filters) as unknown as AuctionOffersResponse;
        setAuctionOfferState((prev) => {
            const newState = {
                ...prev,
                auctionOfferList: response,
                isLoading: false
            };

            return newState;
        });
    };

    const deleteNote = async (id: number, noteId: number) => {
        setLoadingPage();

        const validChangeStatus = await commonApiRequests.deleteNote(AUCTION_MODULE, noteId);

        if (validChangeStatus) {
            setAuctionOfferState((prev) => {
                return {
                    ...prev,
                    notes: prev.notes!.filter((item) => item.noteId !== noteId),
                    auctionOfferList: {
                        ...prev.auctionOfferList,
                        content: prev.auctionOfferList.content.map((offer) => {
                            if (offer.auctionId === id) {
                                return {
                                    ...offer,
                                    numberOfNotes: (offer.numberOfNotes ?? 0) - 1
                                };
                            }

                            return offer;
                        })
                    },
                    isLoading: false
                };
            });
        }
    };

    const updateNote = async (noteContent: string, advertisementId: number, noteId: number) => {
        setLoadingPage();

        const validChangeStatus = await commonApiRequests.updateNote(AUCTION_MODULE, noteContent, advertisementId, noteId) as NoteResponse;

        if (validChangeStatus) {
            if (noteId) {
                setAuctionOfferState((prev) => {
                    return {
                        ...prev,
                        notes: prev.notes!.map((item) => {
                            if (item.noteId === noteId) {
                                return {
                                    ...item,
                                    date: validChangeStatus.date,
                                    content: validChangeStatus.content
                                };
                            }

                            return item;
                        }),
                        auctionOfferList: {
                            ...prev.auctionOfferList,
                            content: prev.auctionOfferList.content.map((x) => {
                                if (x.auctionId === advertisementId) {
                                    return {
                                        ...x,
                                        note: {
                                            ...validChangeStatus,
                                            noteId: noteId
                                        }
                                    };
                                }

                                return x;
                            })
                        },
                        isLoading: false
                    };
                });
            } else {
                setAuctionOfferState((prev) => {
                    return {
                        ...prev,
                        notes: prev.notes ? [validChangeStatus].concat(prev.notes) : [validChangeStatus],
                        auctionOfferList: {
                            ...prev.auctionOfferList,
                            content: prev.auctionOfferList.content.map((x) => {
                                if (x.auctionId === advertisementId) {
                                    return {
                                        ...x,
                                        note: {
                                            ...validChangeStatus
                                        },
                                        numberOfNotes: (x.numberOfNotes ?? 0) + 1
                                    };
                                }

                                return x;
                            })
                        },
                        isLoading: false
                    };
                });
            }
        }
    };

    const reactionUpdate = async (id: number, city: string, reaction: UserReactions) => {
        const response = await commonApiRequests.setOfferReaction(id, city, reaction, AUCTION_MODULE);

        setAuctionOfferState((prev) => ({
            ...prev,
            auctionOfferList: {
                ...prev.auctionOfferList,
                content: prev.auctionOfferList.content.map((el) => {
                    if (el.auctionId === id) {
                        return {
                            ...el,
                            reaction: response
                        };
                    }

                    return el;
                })
            }
        }));
    };

    const loadOfferDetailsAsync = async (id: number) => {
        setLoadingPage();

        const response = await auctionOffersApiRequests.getAuctionOfferDetails(id);

        setAuctionOfferState((prevState) => ({
            ...prevState,
            auctionOfferList: {
                ...prevState.auctionOfferList,
                content: prevState.auctionOfferList.content.map((el) => {
                    if (el.auctionId === response.auctionId) {
                        return {
                            ...el,
                            detailedContent: response
                        };
                    }

                    return el;
                })
            },
            isLoading: false
        }));
    };

    const loadNotesAsync = async (id: string) => {
        const notes = await commonApiRequests.getNotes(AUCTION_MODULE, id);

        setAuctionOfferState((prev) => ({
            ...prev,
            notes
        }));
    };

    const getVoivodeships = useCallback(async () => {
        const locations = await filtersApiRequests.getVoivodeships();
        setAuctionFiltersState((filters: AuctionFiltersStateType) => ({
            ...filters,
            locations,
            officeLocations: locations
        }));
    }, []);

    const clearOffers = () => {
        setAuctionOfferState(auctionInitialState);
    };

    const updateReminder = async (id: number, remindType: string) => {
        const auctionRemindSmsAndEmailStatus: AuctionRemindResponse = await auctionOffersApiRequests.updateReminder(id, remindType);

        if (auctionRemindSmsAndEmailStatus) {
            setAuctionOfferState((prev) => ({
                ...prev,
                auctionOfferList: {
                    ...prev.auctionOfferList,
                    content: prev.auctionOfferList.content.map((offer) => {
                        if (offer.auctionId === id) {
                            return {
                                ...offer,
                                reminds: {
                                    ...offer.reminds,
                                    smsRemindStatus: auctionRemindSmsAndEmailStatus.smsRemindStatus,
                                    emailRemindStatus: auctionRemindSmsAndEmailStatus.emailRemindStatus
                                }
                            };
                        }

                        return offer;
                    })
                }
            }));
        }
    };

    useEffect(() => {
        if (auctionFiltersState.locations.length === 0) {
            getVoivodeships();
        }
    }, [auctionFiltersState.locations]);

    const providerValues = {
        auctionOfferState,
        auctionFiltersState,
        handleChange,
        handleSetFiltersValues,
        handleDeleteFilter,
        handleChangeDateList,
        getSavedFilters,
        clearFilters,
        loadAuctionOffers,
        deleteNote,
        updateNote,
        reactionUpdate,
        loadOfferDetailsAsync,
        loadNotesAsync,
        loadFavoriteOffers,
        clearOffers,
        updateReminder
    };

    return (
        <AuctionContext.Provider value={providerValues}>
            {children}
        </AuctionContext.Provider>
    );
};

export default AuctionContext;

