import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import { ReactionType } from 'store/Offers/State';
import TenderContext from 'utils/context/TenderContext';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { TenderOfferResponseContent } from 'utils/state-managment/tender/tenderOffer';
import { ModulesType } from 'utils/types/ModulesType';
import { UserReactions } from 'utils/types/OfferData';

import {
    InteractiveElements
} from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import { TabType } from 'components/common/Tabs/V2Tabs';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import { tenderOfferListFunctions } from 'components/functions/offerListFunctions/tenderOfferListFunctions/tenderOfferListFunctions';
import { RenderList } from 'components/offerList/V2OfferList';

export const useTenderHook = (filters: FavoritePageFiltersType, activeFilter: ModulesType) => {
    const {
        tenderOfferState,
        deleteNote,
        updateNote,
        reactionUpdate,
        loadOfferDetailsAsync,
        loadNotesAsync,
        clearOffers,
        loadFavoriteOffers
    } = useContext(TenderContext);
    const [renderList, setRenderList] = useState<RenderList[]>([]);

    const handleChangePage = useCallback((currentPage: number, rows = 25) => {
        loadFavoriteOffers(currentPage ?? 1, rows ?? 25, filters);
    }, [filters, activeFilter]);

    const onNoteAdd = () => {
        // TODO: INVEST-257 Czy ta funkcja jest potrzebna?
        // if (!showNote && showLastNote) {
        //     showLastNote();
        // }
        //
        // if (!data.note?.noteId) {
        //     openOfferTab('note');
        // }
    };

    const onMessageClick = () => {
        onNoteAdd();
    };

    const handleDelete = (noteId: number, id: number) => {
        deleteNote(id, noteId);
    };

    const handleSaveNote = async (value: string, advertId: number, id?: number) => {
        updateNote(value, advertId, id || 0);
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: TenderOfferResponseContent) => {
        if (!detailsOpen) {
            if (offer.reaction && !offer.reaction.viewedStatus) {
                reactionUpdate(offer.tenderId, offer.location!, {
                    ...offer.reaction,
                    openedStatus: true,
                    viewedStatus: true
                } as ReactionType);
            }
            loadOfferDetailsAsync(offer.tenderId);
        }
    };

    const handleChangeReaction = (name: keyof ReactionType, value: boolean, offer: TenderOfferResponseContent) => {
        const userReaction = Object.assign({}, offer.reaction);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location;

        reactionUpdate(offer.tenderId, city, userReaction as UserReactions);
    };

    const generateInteractiveElements = (offer: TenderOfferResponseContent): InteractiveElements[] => {
        return [
            {
                tooltipText: 'Wyświetlono',
                icon: IconEnum.EYE,
                active: offer.reaction?.viewedStatus,
                disabled: offer.reaction?.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reaction?.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reaction?.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reaction?.likeStatus, offer)
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reaction?.unlikeStatus,
                disabled: offer.reaction?.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reaction?.unlikeStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes || 1 : undefined,
                openTabOnClick: TabType.NOTES,
                onClick: onMessageClick
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reaction?.hideStatus,
                disabled: offer.reaction?.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reaction?.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reaction?.phoneStatus,
                disabled: offer.reaction?.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reaction?.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reaction?.arrangedStatus,
                disabled: offer.reaction?.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reaction?.arrangedStatus, offer)
            },
            {
                tooltipText: 'Przejdź do ogłoszenia',
                icon: IconEnum.LINK,
                isLink: true,
                noText: true,
                text: <a target="_blank" rel="noopener noreferrer" href={offer.link}/>
            }
        ];
    };

    const handleNotes = async (offer: TenderOfferResponseContent) => {
        loadNotesAsync(offer.tenderId.toString());
    };

    const generateCardData = (offer: TenderOfferResponseContent) => {
        const subMainValues: React.ReactNode[] = [];

        if (offer.pricePerSquareMeter) {
            subMainValues.push(
                <><b>{offer.pricePerSquareMeter.toString()}</b> zł / m<sup>2</sup></>
            );
        }

        return {
            additionalInfoBadge: offer.tenderDate ? `Data licytacji: ${offer.tenderDate}` : undefined,
            infoElements: tenderOfferListFunctions.generateInfoElements(offer),
            preTitle: offer.location,
            date: offer.dateAdded,
            title: offer.title,
            interactiveElements: generateInteractiveElements(offer),
            note: offer.newestNote,
            mainValue: offer.price ? `${formatFilterPrice(offer.price)} zł` : '- zł',
            subMainValues,
            images: createImagesForSlider(offer.photos || [], offer.module),
            thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
            link: {
                url: offer.link,
                name: offer.portal
            }
        };
    };

    useEffect(() => {
        const offersList = tenderOfferState.tenderOfferList?.content
            ? tenderOfferState.tenderOfferList.content?.map((offer): RenderList => {
                const interactiveElements = generateInteractiveElements(offer);
                const detailsTabContent = tenderOfferListFunctions.generateOfferDetailsTabContent(offer);

                return {
                    id: offer.tenderId,
                    onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                    baseCardContent: {
                        bigImage: true,
                        cardData: generateCardData(offer),
                        openOfferTab: () => {
                        }
                    },
                    detailsCardData: {
                        interactiveElements,
                        thumbnailPath: offer.photos && offer.photos.length ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
                        additionalInfoBadge: `${offer.dateAdded}`,
                        onToggleContainerDetails: () => {
                        },
                        tabsContent: {
                            toggleContainerDetails: () => {
                            },
                            interactiveElements,
                            detailsTabContent,
                            notesTabContent: {
                                notes: tenderOfferState.notes,
                                handleDelete: (noteId: string) => handleDelete(Number(noteId), offer.tenderId),
                                handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.tenderId, Number(id))
                            },
                            numberOfNotes: offer.numberOfNotes || 0,
                            handleLoadNotes: () => handleNotes(offer)
                        }
                    }
                };
            })
            : [];

        setRenderList(offersList);

        return () => {
            if (location.pathname !== '/favorites') {
                clearOffers();
            }
        };
    }, [
        tenderOfferState.tenderOfferList?.content,
        tenderOfferState.notes,
        activeFilter
    ]);

    const handleFavoriteSearch = (page?: number, size?: number) => {
        loadFavoriteOffers(page ?? 1, size ?? 25, filters);
    };

    return {
        tenderOfferState,
        handleChangePage,
        handleFavoriteSearch,
        renderList
    };
};
