import React, { createContext, useCallback, useEffect, useState } from 'react';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import { commonApiRequests } from 'utils/api-requests/common';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { offersApiRequests } from 'utils/api-requests/offers';
import { tenderOffersApiRequests } from 'utils/api-requests/tender';
import {
    getTenderValueFromState,
    initialState,
    setTenderStateValues
} from 'utils/state-managment/tender/tenderFilter';
import {
    tenderInitialState,
    TenderOffersResponse,
    TenderOfferStateType
} from 'utils/state-managment/tender/tenderOffer';
import { ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { UserReactions } from 'utils/types/OfferData';
import { TenderFilterReturnData, TenderFiltersStateType } from 'utils/types/Tender';
import { NoteResponse, TenderSearchingRequestSortTypeEnum } from 'utils/types/TenderModels';

interface LoadTenderOffersType {
    requestData?: TenderFilterReturnData,
    page?: number,
    rows?: number
}

export interface TenderContextType {
    tenderFiltersState: TenderFiltersStateType,
    tenderOfferState: TenderOfferStateType,
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void,
    handleSetFiltersValues: (id: number) => void,
    handleDeleteFilter: (id: number) => void,
    handleChangeDateList: (fieldName: string, value: number) => void,
    getSavedFilters: (id?: number) => void,
    clearFilters: () => void,
    loadTenderOffers: (tenderOffersOptions?: LoadTenderOffersType) => void;
    deleteNote: (id: number, noteId: number) => void,
    updateNote: (noteContent: string, advertisementId: number, noteId: number) => void,
    reactionUpdate: (id: number, city: string, reaction: UserReactions) => void,
    loadOfferDetailsAsync: (id: number) => void;
    loadNotesAsync: (id: string) => void;
    loadFavoriteOffers: (page?: number, size?: number, filters?: FavoritePageFiltersType) => void,
    clearOffers: () => void,
}

const values: TenderContextType = {
    tenderFiltersState: {} as TenderFiltersStateType,
    tenderOfferState: {} as TenderOfferStateType,
    handleChange: () => {},
    handleSetFiltersValues: () => {},
    handleDeleteFilter: () => {},
    handleChangeDateList: () => {},
    getSavedFilters: () => {},
    clearFilters: () => {},
    loadTenderOffers: () => {},
    deleteNote: () => {},
    updateNote: () => {},
    reactionUpdate: () => {},
    loadOfferDetailsAsync: () => {},
    loadNotesAsync: () => {},
    loadFavoriteOffers: () => {},
    clearOffers: () => {}
};

const TENDER_MODULE = 'TENDER';

export const TenderContext = createContext(values);

export const TenderProvider = ({ children }: { children: React.ReactNode }) => {
    const [tenderFiltersState, setTenderFiltersState] = useState(initialState.tenderFilterInitialState());
    const [tenderOfferState, setTenderOfferState] = useState(tenderInitialState);

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setTenderFiltersState((filters: TenderFiltersStateType) => ({
            ...filters,
            [fieldName]: value
        }));

        if (fieldName === 'sortType') {
            setTenderOfferState((prevState) => ({
                ...prevState,
                sortType: value as TenderSearchingRequestSortTypeEnum
            }));
        }
    }, [tenderFiltersState]);

    const getSavedFilters = useCallback(async (id?: number) => {
        const savedFilters = await tenderOffersApiRequests.getSavedFilters();
        setTenderFiltersState((filters: TenderFiltersStateType) => ({
            ...filters,
            savedFilter: savedFilters
        }));

        if (id) {
            await handleSetFiltersValues(id);
        }
    }, []);

    const clearFilters = useCallback((reset?: boolean) => {
        setTenderFiltersState((prev) => ({
            ...initialState.tenderFilterInitialState(),
            locations: prev.locations,
            city: reset ? [] : prev.city,
            savedLocations: reset ? {} : prev.savedLocations
        }));
        getSavedFilters();
    }, [getSavedFilters]);

    const handleDeleteFilter = useCallback(async (filterId: number) => {
        await tenderOffersApiRequests.removeSavedFilter(filterId);

        setTenderFiltersState(initialState.tenderFilterInitialState());
        getSavedFilters();
    }, [
        tenderFiltersState.filterId,
        tenderFiltersState.savedFilter,
        getSavedFilters
    ]);

    const handleSetFiltersValues = async (id: number) => {
        const filterData = await tenderOffersApiRequests.getFilterDetailsTender(id) as TenderFilterReturnData;

        if (filterData) {
            setTenderFiltersState((filters: TenderFiltersStateType) => ({
                ...setTenderStateValues({ ...filterData, locations: tenderFiltersState.locations }),
                savedFilter: filters.savedFilter,
                filterId: id
            }));
            getVoivodeships();
        } else {
            setTenderFiltersState((filters: TenderFiltersStateType) => ({
                ...filters,
                name: null,
                filterId: null
            }));
        }
    };

    const handleChangeDateList = (fieldName: string, value: number) => {
        setTenderFiltersState((filters: TenderFiltersStateType) => ({
            ...filters,
            offerAdded: {
                from: value === -1 ? null : new Date(new Date().setDate(new Date().getDate() - value)).toISOString().split('T')[0],
                to: new Date().toISOString().split('T')[0]
            },
            [fieldName]: value
        }));
    };

    const setLoadingPage = () => {
        setTenderOfferState((prev) => ({
            ...prev,
            isLoading: true
        }));
    };

    const loadTenderOffers = async (tenderOffersOptions?: LoadTenderOffersType) => {
        const { requestData, page, rows } = tenderOffersOptions || {};

        setLoadingPage();

        const response = await tenderOffersApiRequests.getTenderOffers(requestData ? requestData : getTenderValueFromState(tenderFiltersState), page ?? 0, rows ?? 25);

        if (response) {
            setTenderOfferState((prev) => ({
                ...prev,
                tenderOfferList: response,
                isLoading: false
            }));
        }
    };

    const loadFavoriteOffers = async (page?: number, size?: number, filters?: FavoritePageFiltersType) => {
        setLoadingPage();

        const response = await offersApiRequests.getUsersFavoriteOffers(TENDER_MODULE, page ?? 1, size ?? 25, filters) as unknown as TenderOffersResponse;
        setTenderOfferState((prev) => {
            const newState = {
                ...prev,
                tenderOfferList: response,
                isLoading: false
            };

            return newState;
        });
    };

    const deleteNote = async (id: number, noteId: number) => {
        setLoadingPage();

        const validChangeStatus = await commonApiRequests.deleteNote(TENDER_MODULE, noteId);

        if (validChangeStatus) {
            setTenderOfferState((prev) => {
                return {
                    ...prev,
                    notes: prev.notes!.filter((item) => item.noteId !== noteId),
                    tenderOfferList: {
                        ...prev.tenderOfferList,
                        content: prev.tenderOfferList.content.map((offer) => {
                            if (offer.tenderId === id) {
                                return {
                                    ...offer,
                                    numberOfNotes: (offer.numberOfNotes ?? 0) - 1
                                };
                            }

                            return offer;
                        })
                    },
                    isLoading: false
                };
            });
        }
    };

    const updateNote = async (noteContent: string, advertisementId: number, noteId: number) => {
        setLoadingPage();

        const validChangeStatus = await commonApiRequests.updateNote(TENDER_MODULE, noteContent, advertisementId, noteId) as NoteResponse;

        if (validChangeStatus) {
            if (noteId) {
                setTenderOfferState((prev) => {
                    return {
                        ...prev,
                        notes: prev.notes!.map((item) => {
                            if (item.noteId === noteId) {
                                return {
                                    ...item,
                                    date: validChangeStatus.date,
                                    content: validChangeStatus.content
                                };
                            }

                            return item;
                        }),
                        tenderOfferList: {
                            ...prev.tenderOfferList,
                            content: prev.tenderOfferList.content.map((x) => {
                                if (x.tenderId === advertisementId) {
                                    return {
                                        ...x,
                                        note: {
                                            ...validChangeStatus,
                                            noteId: noteId
                                        }
                                    };
                                }

                                return x;
                            })
                        },
                        isLoading: false
                    };
                });
            } else {
                setTenderOfferState((prev) => {
                    return {
                        ...prev,
                        notes: prev.notes ? [validChangeStatus].concat(prev.notes) : [validChangeStatus],
                        tenderOfferList: {
                            ...prev.tenderOfferList,
                            content: prev.tenderOfferList.content.map((x) => {
                                if (x.tenderId === advertisementId) {
                                    return {
                                        ...x,
                                        note: {
                                            ...validChangeStatus
                                        },
                                        numberOfNotes: (x.numberOfNotes ?? 0) + 1
                                    };
                                }

                                return x;
                            })
                        },
                        isLoading: false
                    };
                });
            }
        }
    };

    const reactionUpdate = async (id: number, city: string, reaction: UserReactions) => {
        const response = await commonApiRequests.setOfferReaction(id, city, reaction, TENDER_MODULE);

        setTenderOfferState((prev) => ({
            ...prev,
            tenderOfferList: {
                ...prev.tenderOfferList,
                content: prev.tenderOfferList.content.map((el) => {
                    if (el.tenderId === id) {
                        return {
                            ...el,
                            reaction: response
                        };
                    }

                    return el;
                })
            }
        }));
    };

    const loadOfferDetailsAsync = async (id: number) => {
        setLoadingPage();

        const response = await tenderOffersApiRequests.getTenderOfferDetails(id);

        setTenderOfferState((prevState) => ({
            ...prevState,
            tenderOfferList: {
                ...prevState.tenderOfferList,
                content: prevState.tenderOfferList.content.map((el) => {
                    if (el.tenderId === response.tenderId) {
                        return {
                            ...el,
                            detailedContent: response
                        };
                    }

                    return el;
                })
            },
            isLoading: false
        }));
    };

    const loadNotesAsync = async (id: string) => {
        const notes = await commonApiRequests.getNotes(TENDER_MODULE, id);

        setTenderOfferState((prev) => ({
            ...prev,
            notes
        }));
    };

    const getVoivodeships = useCallback(async () => {
        const locations = await filtersApiRequests.getVoivodeships();
        setTenderFiltersState((filters: TenderFiltersStateType) => ({
            ...filters,
            locations,
            officeLocations: locations
        }));
    }, []);

    const clearOffers = () => {
        setTenderOfferState(tenderInitialState);
    };

    useEffect(() => {
        if (tenderFiltersState.locations.length === 0) {
            getVoivodeships();
        }
    }, [tenderFiltersState.locations]);

    const providerValues = {
        tenderOfferState,
        tenderFiltersState,
        handleChange,
        handleSetFiltersValues,
        handleDeleteFilter,
        handleChangeDateList,
        getSavedFilters,
        clearFilters,
        loadTenderOffers,
        deleteNote,
        updateNote,
        reactionUpdate,
        loadOfferDetailsAsync,
        loadNotesAsync,
        loadFavoriteOffers,
        clearOffers
    };

    return (
        <TenderContext.Provider value={providerValues}>
            {children}
        </TenderContext.Provider>
    );
};

export default TenderContext;

