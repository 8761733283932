import React, { FC } from 'react';

import BooleanSelectInput from './common/Inputs/BooleanSelectInput';

interface FormRadioProps {
    name: string;
    value: boolean;
    onChange: (name: string, value: boolean) => void;
    disabled?: boolean;
    optionsText?: { trueOptionText: string; falseOptionText: string };
  }

export const FormRadio: FC<FormRadioProps> = ({ name, value, onChange, disabled = false, optionsText }) => <BooleanSelectInput optionsText={optionsText} disabled={disabled} value={value} onChange={(newValue) => onChange(name, newValue)} />;

