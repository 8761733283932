
import React, { useContext } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import AuctionContext from 'utils/context/AuctionContext';
import { auctionSortOptions } from 'utils/options';
import { getAuctionValueFromState } from 'utils/state-managment/auction/auctionFilter';
import { AuctionSearchingRequestSortTypeEnum } from 'utils/types/AuctionModels';

import Indicator from 'components/atom/Indicator';
import AuctionOffers from 'components/auction/AuctionOffers';
import FiltersAuction from 'components/auction/FiltersAuction';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import V2HeaderSection from 'components/V2HeaderSection';

const Container = styled.div`
    padding: 15px 20px 0;
`;

const PageContainer = styled.div`
    height: 100%;
`;

const AuctionPage = () => {
    const { auctionOfferState, handleChange, loadAuctionOffers, auctionFiltersState, clearOffers } = useContext(AuctionContext);

    const handleChangeSort = (sortType: string) => {
        handleChange('sortType', sortType);
        loadAuctionOffers({
            requestData: {
                ...getAuctionValueFromState(auctionFiltersState),
                sortType: sortType as AuctionSearchingRequestSortTypeEnum
            },
            page: 1,
            rows: 25
        });
    };

    // const handleCSVFile = useCallback(() => {
    //     auctionOffersApiRequests.getAuctionOffersCSVFile(getAuctionValueFromState(auctionFiltersState), auctionFiltersState.module);
    // }, [auctionFiltersState]);

    return (
        <PageContainer>
            <V2HeaderSection
                title={'Licytacje komornicze'}
                offersCount={auctionOfferState.auctionOfferList?.totalElements}
                sortType={auctionOfferState.auctionOfferList.content?.length ? auctionOfferState.sortType : undefined}
                sortOptions={auctionSortOptions}
                handleChangeSort={handleChangeSort}
                handleBackToFilters={auctionOfferState.auctionOfferList.content?.length || auctionOfferState.auctionOfferList.empty ? clearOffers : undefined}
            />
            <FiltersContentWrapper className={clsx('offers-list', { active: auctionOfferState.auctionOfferList?.content && auctionOfferState.auctionOfferList?.content.length > 0 || auctionOfferState.auctionOfferList?.empty })}>
                <Container id="filters">
                    <FiltersAuction/>
                </Container>
                <AuctionOffers/>
                {auctionOfferState.isLoading && <Indicator/>}
            </FiltersContentWrapper>
        </PageContainer>
    );
};

export default AuctionPage;
