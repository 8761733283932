import { axiosApiInstance2 } from 'Store';
import {
    BlockageAdvertisementDto,
    BlockageInfoDto,
    Module,
    PermanentBlockAdvertisementDto,
    UnblockAdvertisementDto
} from 'utils/types/BrokerModels';

import { activeNotification } from 'components/functions/activeNotification';

const addTempBlockage = async (data: BlockageAdvertisementDto): Promise<BlockageInfoDto | null> => {
    return axiosApiInstance2
        .post('/broker/advertisement/blockage/temporary', {
            ...data
        })
        .then(async () => {
            activeNotification(
                'Ogłoszenie zarezerwowane',
                'Udało się zarezerwować wybrane ogłoszenie',
                'success'
            );

            return await getBlockageInfo(data.advertisementId, data.module);
        })
        .catch(() => {
            activeNotification(
                'Nie udało się zarezerwować ogłoszenia',
                'Nie udało się zarezerwować wybranego ogłoszenia',
                'warning'
            );

            return null;
        });
};

const addBlockage = async (data: PermanentBlockAdvertisementDto): Promise<BlockageInfoDto | null> => {
    return axiosApiInstance2
        .post('/broker/advertisement/blockage/permanent', {
            ...data
        })
        .then(async () => {
            activeNotification(
                'Ogłoszenie zarezerwowane',
                'Udało się zarezerwować wybrane ogłoszenie na stałe',
                'success'
            );

            return await getBlockageInfo(data.advertisementId, data.module);
        })
        .catch(() => {
            activeNotification(
                'Nie udało się zarezerwować ogłoszenia',
                'Nie udało się zarezerwować wybranego ogłoszenia na stałe',
                'warning'
            );

            return null;
        });
};

const removeBlockage = async (data: UnblockAdvertisementDto): Promise<boolean | null> => {
    return axiosApiInstance2
        .post('/broker/advertisement/blockage/unblock', {
            ...data
        })
        .then(async () => {
            activeNotification(
                'Ogłoszenie odblokowane',
                'Udało się odblokować wybrane ogłoszenie',
                'success'
            );

            return true;
        })
        .catch(() => {
            activeNotification(
                'Nie udało się odblokować ogłoszenia',
                'Nie udało się odblokować wybranego ogłoszenia',
                'warning'
            );

            return null;
        });
};

const getBlockageInfo = async (advertisementId: number, module: Module): Promise<BlockageInfoDto> => {
    return axiosApiInstance2
        .get('/broker/advertisement/blockage/info', {
            params: {
                advertisementId,
                module
            }
        })
        .then((resp) => {
            return resp.data;
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać informacji o rezerwacji',
                'Nie udało się pobrać danych szczegółowych rezerwacji',
                'warning'
            );

            return {};
        });
};

export const brokerApiRequests = { addTempBlockage, getBlockageInfo, removeBlockage, addBlockage };
