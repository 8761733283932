import dayjs from 'dayjs';

export const formatDate = (date: string) => {
    // If date is already in DD.MM.YYYY format, return as is
    if (date.match(/^\d{2}\.\d{2}\.\d{4}/)) {
        return date;
    }

    return dayjs(date).isValid()
        ? dayjs(date).format('DD.MM.YYYY, HH:mm')
        : date;
};
